import React, { useContext, useEffect } from 'react';

import { NotificationsContext } from 'providers/contexts';
import { UserContext } from 'providers/UserProvider';
import NotificationItem from './NotificationItem';

const NotificationList: React.FC = () => {
  const { notifications } = useContext(NotificationsContext);

  const notificationView = () => (
    <div>
      <div className='control-block' style={{ marginLeft: 20 }}>
        {notifications &&
          notifications.map((item, index) => { 
            return <NotificationItem key={index} notification={item} />
            })}
      </div>
    </div>
  );

  return (
    <div className='product-upload'>
      <div className='product-upload__header'>
        <div className='product-upload__heading'>Notification Center</div>
      </div>
      {notificationView()}
    </div>
  );
};

export default NotificationList;

import React, { useState, useContext } from 'react';
import { navigate } from '@reach/router';
import { auth } from '../../../firebase';
import './Profile.scss';
import { UserContext, ModalsContext, NotificationsContext } from 'providers/contexts';
import PopUp from 'components/Common/PopUp/PopUp';

import { ProfileAssetCircle } from '../SidebarInfo/SidebarInfo';
import { mmAPI, setAuthToken } from 'services/Api';

export interface IProfile {
  profile_picture?: any;
}

const ProfileAvatar: React.FC<IProfile> = props => {
  const { profile_picture } = props;

  const { user, setUser } = useContext(UserContext);
  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const [open, setOpen] = useState(false);

  return (
    <>
      {profile_picture?.url ? (
        <img
          className='profile'
          src={profile_picture?.url || profile_picture?.urls?.download}
          alt={`${user?.account.first} ${user?.account.last}`}
          onClick={() => setOpen(!open)}
        />
      ) : (
        <div className='profile ' onClick={() => setOpen(!open)}>
          <div className='sidebar-info__member'>
            <ProfileAssetCircle
              firstName={user?.account?.first}
              lastName={user?.account?.last}
            />
          </div>
        </div>
      )}
      <PopUp open={open} setOpen={setOpen}>
        {user?.account && (
          <>
            <li
              className='pop-up__li unset'
              onClick={() => {
                navigate('/settings/profile');
              }}
            >
              {user?.account?.email}
            </li>
            {user.brand && (
              <li
                className='pop-up__li'
                onClick={() => {
                  navigate(`/brand/${user?.brand?.key}/team`);
                }}
              >
                {user?.brand?.name} Settings
              </li>
            )}
            <li
              className='pop-up__li'
              onClick={async () => {
                try {
                  const response = await mmAPI(
                    '/api/account/method/idTokenFromRefreshToken',
                    null,
                    { refresh_token: user.refreshToken },
                    'POST',
                    { refresh_token: user.refreshToken }
                  );
                  console.trace('idTokenFromRefreshToken response', response);
                  const {
                    refreshToken,
                    idToken: idTokenRefreshed,
                    account,
                    custom_token,
                  } = response;
                  setUser(prevUser => ({
                    ...prevUser,
                    account,
                    custom_token,
                    refreshToken,
                    idToken: idTokenRefreshed,
                  }));
                  // API endpoint should refresh session using the idToken from google response
                  setAuthToken(idTokenRefreshed);
                  // given the API is signed in the custom_token can be used to refresh the client session.
                  setOpen(false);
                } catch (error) {
                  console.error('idTokenFromRefreshToken error refreshing token');
                  console.error(error);
                }
              }}
            >
              Reset Session
            </li>
            <li
              className='pop-up__li'
              onClick={() => {
                setOpen(false);
                setDialogProps({
                  dialogBody: 'Are you sure you want to logout?',
                  btnActionHandler: async () => {
                    await auth.signOut().then(
                      value => {
                        setAuthToken(null);
                        navigate('/');
                      },
                      error => {
                        console.error(error);
                      }
                    );
                  },
                });
                setOpenDialog(true);
                setDisplayToast(false);
              }}
            >
              Log out
            </li>
          </>
        )}
        {!user?.account && (
          <>
            <li
              className='pop-up__li'
              onClick={() => {
                navigate('/');
              }}
            >
              Login
            </li>
          </>
        )}
      </PopUp>
    </>
  );
};

export default ProfileAvatar;

import React, { useEffect, useState, useContext, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import 'components/UploadAssetLibrary/UploadAssetLibrary.scss';

import Modal from 'components/Common/Modal/Modal';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import Search from 'components/Common/Search/Search';
import Tile from 'components/Tile/Tile';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { INIT_BUCKET_FOLDERS } from 'components/Dashboard/AssetLibrary/AssetLibraryContainer';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { getBucketModelFolder } from 'services/Api';
import { ASSET_TYPES, Dictionary } from 'constants/assets';
import CaretDownIcon from 'assets/icons/caret-bottom.svg';
import FolderIcon from 'assets/icons/Icon-folder.svg';
import ArrowIcon from 'assets/icons/angle-right-gray.svg';
import CloseIcon from 'assets/icons/close.svg';

export interface IAsset {
  assetName?: string;
  assetSrc?: string;
}

export interface IOptions {
  label: string;
}

export interface IFilterDropdowns {
  label: string;
  options: IOptions[];
}

export interface IUploadAssetLibrary {
  headingText: string;
  asset?: IAsset[];
  bucket: any;
  options?: IOptions[];
  filterDropdowns?: IFilterDropdowns[];
  open: boolean;
  setOpen?: (open: boolean) => void;
  onSelect?: (src: string) => void;
}

const asset_types: Dictionary = {
  Images: [
    ASSET_TYPES.image,
    ASSET_TYPES.svg,
    ASSET_TYPES.gif,
    ASSET_TYPES.ai,
    ASSET_TYPES.psd,
  ],
  Documents: [
    ASSET_TYPES.spreadsheet,
    ASSET_TYPES.pdf,
    ASSET_TYPES.document,
    ASSET_TYPES.presentation,
  ],
  Videos: [ASSET_TYPES.video],
  Techpacks: [],
  '3D': [ASSET_TYPES.obj, ASSET_TYPES.pattern, ASSET_TYPES.fbx],
  Other: [ASSET_TYPES.geometry, ASSET_TYPES.zip],
};

const UploadAssetLibrary: React.FC<IUploadAssetLibrary> = props => {
  const { headingText, bucket, open, setOpen, onSelect } = props;

  const {
    user: { idToken },
  } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [openDropdown, setOpenDropdown] = useState([]);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const [folderPath, setFolderPath] = useState([]);
  const [assetType, setAssetType] = useState();
  const [activeFolders, setActiveFolders] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);
  const [assetSelected, setAssetSelected] = useState();

  useEffect(() => {
    const fetchFolderHandler = async () => {
      if (!bucket?.key) {
        return;
      }
      setActiveFolders([]);
      setActiveFiles([]);
      setAssetSelected(undefined);

      const folder_path = '/' + folderPath.map(p => p + '/').join('');
      const response = await getBucketModelFolder(idToken, bucket.key, {
        prefix: folder_path,
      });
      const { data: blobs } = response.data;

      const folders = [];
      const files = [];
      for (const blob of blobs) {
        const subPathName = blob.name.substring(folder_path.length);
        if (subPathName === '') {
          continue;
        }
        const subPath = subPathName.split('/');
        if (subPath.length === 1) {
          const blobUrl = `/assets/bucket/${bucket.key}${blob.name}`;
          const firebaseUrl = await firebaseStorageUrl(blobUrl);
          if (firebaseUrl) {
            files.push({
              assetName: subPath[0],
              assetSrc: firebaseUrl,
              blob,
            });
          }
        } else if (subPath.length >= 2) {
          if (
            folders.findIndex(f => subPath[0].localeCompare(f.folderName) === 0) === -1
          ) {
            folders.push({
              folderName: subPath[0],
            });
          }
        }
      }
      setActiveFolders(folders);
      setActiveFiles(files);
    };
    fetchFolderHandler();
  }, [bucket?.key, folderPath]);

  const onFilterByFolder = folder => {
    setFolderPath([folder]);
  };

  const onFilterByType = asset_type => {
    setAssetType(asset_type);
  };

  const onRemoveAssetType = e => {
    e.preventDefault();
    setAssetType(undefined);
  };

  const filterDropdowns = [
    {
      label: 'By Folder',
      options: INIT_BUCKET_FOLDERS.map(f => ({
        label: f,
        onSelect: onFilterByFolder,
      })),
    },
    {
      label: 'By Type',
      options: Object.keys(asset_types).map(type => ({
        label: type,
        onSelect: onFilterByType,
      })),
    },
  ];

  const onSubmit = e => {
    e.preventDefault();
    if (assetSelected && activeFiles.length > 0) {
      const asset = activeFiles.find(({ blob }) => blob.name === assetSelected);
      if (asset) {
        onSelect?.(asset.assetSrc);
      }
    }
    setOpen(false);
  };

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < folderPath.length; i += 1) {
      const p = folderPath[i];
      link += `/${p}`;
      const page: IPages = {
        title: p,
      };
      if (i < folderPath.length - 1) {
        page.link = link;
        page.onClick = () => setFolderPath(folderPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    return pagesArray;
  }, [folderPath]);

  const filteredFolders = activeFolders?.filter(f =>
    f.folderName.toLowerCase().includes(search.toLowerCase())
  );
  const filteredFiles = activeFiles?.filter(f => {
    const assetName = f.assetName.toLowerCase();
    if (!assetName.includes(search.toLowerCase())) {
      return false;
    }
    if (!assetType || !asset_types[assetType]) {
      return true;
    }
    const assetExtension = assetName.split('.').pop();
    for (const type of asset_types[assetType]) {
      const { extensions } = type;
      for (const ext of extensions) {
        if (assetExtension.localeCompare(ext.toLowerCase()) === 0) {
          return true;
        }
      }
    }
    return false;
  });

  return (
    <div className='upload-asset-library'>
      <Modal
        modalHeading={headingText}
        modalBtnClose='Cancel'
        modalBtnActionLabel='Add'
        modalBtnActionHandler={onSubmit}
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
      >
        <div className='upload-asset-library__body'>
          <div className='upload-asset-library__search'>
            <Search changeHandler={setSearch} />
          </div>
          <div className='upload-asset-library__filters'>
            {filterDropdowns &&
              filterDropdowns.map(({ label, options }, idx) => (
                <div
                  className='upload-asset-library__dropdown'
                  key={idx}
                  onClick={
                    openDropdown.includes(idx)
                      ? () => setOpenDropdown(openDropdown.filter(e => e !== idx))
                      : () => setOpenDropdown([openDropdown.includes(idx), idx])
                  }
                >
                  {label}
                  <img src={CaretDownIcon} alt='Caret Down' />
                  <CSSTransition
                    classNames='lightbox'
                    timeout={200}
                    in={openDropdown.includes(idx)}
                    unmountOnExit
                  >
                    <div className='upload-asset-library__popup'>
                      {options.map(({ label, onSelect }, idx) => (
                        <div
                          className='upload-asset-library__option'
                          key={idx}
                          onClick={() => onSelect?.(label)}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  </CSSTransition>
                </div>
              ))}
          </div>
          <Breadcrumbs pages={pages} />
          {assetType && (
            <div className='upload-asset-library__type mb-3'>
              <button
                className='button-badge color-approved-bg d-flex align-items-center'
                onClick={onRemoveAssetType}
              >
                {assetType}
                <img src={CloseIcon} alt='remove type' />
              </button>
            </div>
          )}
          <div className='upload-asset-library__folders'>
            {filteredFolders &&
              filteredFolders.map(({ folderName }, idx) => (
                <div key={folderName} className='upload-asset-library__folder'>
                  <span
                    className='upload-asset-library__folder__heading-flex'
                    onClick={() => setFolderPath([...folderPath, folderName])}
                  >
                    <img
                      src={FolderIcon}
                      className='upload-asset-library__folder__folder-icon'
                      alt={folderName}
                    />
                    {folderName}
                  </span>
                  <div className='upload-asset-library__folder__arrow-icon'>
                    <img src={ArrowIcon} alt='Open' />
                  </div>
                </div>
              ))}
          </div>
          <div className='upload-asset-library__assets'>
            {filteredFiles ? (
              filteredFiles.map(({ assetName, assetSrc, blob }, idx) => {
                const checked = blob.name === assetSelected;
                return (
                  <div
                    key={idx}
                    className={
                      checked
                        ? 'upload-asset-library__asset upload-asset-library__asset-active'
                        : 'upload-asset-library__asset'
                    }
                    onClick={() => {
                      setAssetSelected(blob.name);
                    }}
                  >
                    <div className='upload-asset-library__selected'>
                      <Checkboxes
                        checked={checked}
                        onChange={e => {
                          setAssetSelected(blob.name);
                        }}
                      />
                    </div>
                    <Tile image={assetSrc} aspectRatio='1-1' />
                    <div className='upload-asset-library__asset-name'>{assetName}</div>
                  </div>
                );
              })
            ) : (
              <div className='upload-asset-library__none-selected'>Search the item</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadAssetLibrary;

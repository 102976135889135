import React, { useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './AssetLibraryFolder.scss';
import FolderIcon from 'assets/icons/Icon-folder.svg';
import ArrowIcon from 'assets/icons/Icon-angle-down-gray.svg';

export interface IAssetLibraryFolder {
  name?: string;
  subFolders?: IAssetLibraryFolder[];
  activeFolderPath?: string[];
  onClickFolder?: (path: Array<string>) => void;
}

const AssetLibrarySubFolder: React.FC<IAssetLibraryFolder> = props => {
  const { name, subFolders, activeFolderPath = [], onClickFolder } = props;

  const [open, setOpen] = useState(false);
  const nodeDialog = useRef();

  const isActiveFolder = useMemo(
    () => activeFolderPath.length > 0 && activeFolderPath[0].localeCompare(name) === 0,
    [name, activeFolderPath]
  );

  useEffect(() => {
    if (isActiveFolder) {
      setOpen(true);
    }
  }, [isActiveFolder]);

  const onClickOpen = () => {
    setOpen(!open);
    onClickFolder?.([name]);
  };

  const onClickSubFolder = path => {
    onClickFolder?.([name, ...path]);
  };

  return (
    <div className='asset-library-subfolder'>
      <div
        className={classNames('asset-library-subfolder__heading', {
          'asset-library-subfolder__heading-has-children': subFolders,
          active: isActiveFolder && activeFolderPath.length === 1,
        })}
        onClick={onClickOpen}
      >
        <img className='asset-library-folder__arrow' src={ArrowIcon} alt='Arrow Icon' />
        <img
          className='asset-library-folder__folder'
          src={FolderIcon}
          alt='Folder Icon'
        />{' '}
        {name}{' '}
      </div>
      <div>
        {subFolders && (
          <CSSTransition in={open} classNames='accordion' timeout={300} unmountOnExit>
            <div ref={nodeDialog}>
              {subFolders &&
                subFolders.map((subFolder, idx) => (
                  <AssetLibrarySubFolder
                    key={idx}
                    {...subFolder}
                    activeFolderPath={isActiveFolder ? activeFolderPath.slice(1) : []}
                    onClickFolder={onClickSubFolder}
                  />
                ))}
            </div>
          </CSSTransition>
        )}
      </div>
    </div>
  );
};

const AssetLibraryFolder: React.FC<IAssetLibraryFolder> = props => {
  const { name, subFolders, activeFolderPath = [], onClickFolder } = props;

  const [open, setOpen] = useState(false);
  const nodeDialog = useRef();

  const isActiveFolder = useMemo(
    () => activeFolderPath.length > 0 && activeFolderPath[0].localeCompare(name) === 0,
    [name, activeFolderPath]
  );

  useEffect(() => {
    if (isActiveFolder) {
      setOpen(true);
    }
  }, [isActiveFolder]);

  const onClickOpen = () => {
    setOpen(!open);
    onClickFolder?.([name]);
  };

  const onClickSubFolder = path => {
    onClickFolder?.([name, ...path]);
  };

  return (
    <div className='asset-library-folder'>
      <div className='asset-library-folder__heading' onClick={onClickOpen}>
        {name}
      </div>

      <CSSTransition in={open} classNames='accordion' timeout={300} unmountOnExit>
        <div ref={nodeDialog}>
          {subFolders &&
            subFolders.map((subFolder, idx) => (
              <AssetLibrarySubFolder
                key={idx}
                {...subFolder}
                activeFolderPath={isActiveFolder ? activeFolderPath.slice(1) : []}
                onClickFolder={onClickSubFolder}
              />
            ))}
          <div />
        </div>
      </CSSTransition>
    </div>
  );
};

export default AssetLibraryFolder;

import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import './LiveChat.scss';
import { Tween } from 'react-gsap';
import CloseIcon from 'assets/icons/close.svg';
import CloseGray from 'assets/icons/close-gray.png';
import PaperclipIcon from 'assets/icons/paperclip-grey.svg';
import TextAreaInput from 'components/Dashboard/FormComponents/TextAreaInput';
import { useForm } from 'react-hook-form';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import DragAndDrop from 'components/Upload/DragAndDrop';
import useMessages from 'hooks/useMessages';
import { UserContext } from 'providers/UserProvider';
import EllipsisHIcon from 'assets/icons/ellipsis-h-grey.svg';
import { deleteModel } from 'services/Api';
import BackIcon from '../Frame/BackIcon';
import { ChatContacts } from './ChatContacts';
import { ModalsContext } from 'providers/ModalsProvider';
import { ChatGroups } from './ChatGroups';
import ChatMessages from './ChatMessages';
import { NotificationsContext } from 'providers/NotificationsProvider';
import LogRocket from 'logrocket';
import PopUp from '../PopUp/PopUp';

export interface IContacts {
  key: number;
  profilePictureUrl?: string;
  firstName: string;
  lastName: string;
  lastMessage: string;
}

export interface IGroupChats {
  groupChatName: string;
  groupChatMembers: string;
}

export type LiveChat = {
  contacts: IContacts[];
  groupChats: IGroupChats[];
  onClose?: () => void;
};

const LiveChat: React.FC<LiveChat> = props => {
  const { state, dispatch } = useFrameUI();
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const [open, setOpen] = useState(false);
  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);
  const { setMessageModelAssoc, postMessage, mutateMessages } = useMessages(idToken, {
    model: null,
    open: false,
  });
  const { contacts, groupChats, onClose } = props;
  const node = useRef();
  const messageBox = useRef(null);

  useEffect(() => {
    messageBox.current.scrollTop = messageBox.current.scrollHeight;
  });
  const { register, handleSubmit, reset, errors } = useForm({});

  const deleteMessageActionHandler = useCallback(
    async teamKey => {
      setOpen(false);
      const response = await deleteModel(idToken, 'team', teamKey);

      if (response?.data) {
        setDisplayToast({
          type: 'success',
          message: `Thread Deleted Succesfully`,
        });
        mutateMessages();
        dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: false });
      } else {
        setDisplayToast({
          type: 'error',
          message: `Error while deleting message`,
        });
      }
    },
    [idToken]
  );

  const popUpOptions = [
    {
      selectItem: 'Delete',
      onClick: teamKey => {
        setDialogProps({
          dialogBody: 'Are you sure you would like to delete this Thread?',
          btnActionHandler: () => {
            deleteMessageActionHandler(teamKey);
          },
        });
        setOpenDialog(true);
      },
    },
  ];

  const onPostMessage = async values => {
    values.file = messageAttachment?.file;
    values.fileMetadata = messageAttachment?.fileMetadata;
    try {
      const response = await postMessage?.(values);
      if (response.data?.error) {
        const message =
          response.data.error.type === 'AuthInvalidSession'
            ? 'Unable to deliver the message. Please refresh your session.'
            : 'Unable to deliver the message.';
        setDisplayToast({
          type: 'error',
          persist: false,
          message,
        });
        LogRocket.captureMessage(`Messaging error: postMessage`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'LiveChat',
            step: 'onPostMessage',
          },
          extra: {
            // additional arbitrary data associated with the event
            values,
            response: response?.data,
          },
        });
      } else {
        setMessageAttachment(null);
        mutateMessages?.();
        reset();
      }
    } catch (error) {
      setDisplayToast({
        type: 'error',
        persist: false,
        message: 'Unable to deliver the message.',
      });
      LogRocket.captureMessage(`Messaging error: postMessage`, {
        tags: {
          // additional data to be grouped as "tags"
          journey: 'LiveChat',
          step: 'onPostMessage',
        },
        extra: {
          // additional arbitrary data associated with the event
          values,
          error,
        },
      });
    }
  };

  const [messageAttachment, setMessageAttachment] = useState(null);
  const onAttachmentChangeHandler = files => {
    const [file] = files;
    setMessageAttachment({
      file,
    });
  };

  return (
    <div className={state.chatbar?.open ? 'livechat--visible' : 'livechat-hidden'}>
      <Tween
        from={{ x: '50px', autoAlpha: 0 }}
        to={{ x: '0px', autoAlpha: 1 }}
        duration={0.5}
      >
        <div className='livechat' ref={node}>
          <div className='livechat__header'>
            <div className='livechat__header-top'>
              <div className='livechat__header-top-right'>
                {state.chatbar.props?.model?.key && (
                  <div className='style-sample-review-header__wrap'>
                    {!['Reference', 'Style'].includes(
                      state.chatbar.props?.model?.KIND
                    ) && (
                      <div
                        className='style-sample-review-header__left-aligned'
                        onClick={e => {
                          setMessageModelAssoc(null);
                        }}
                      >
                        <div className='livechat__back-icon'>
                          <svg
                            width='16'
                            height='14'
                            viewBox='0 0 16 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.72525 7.84536H15.1998C15.4121 7.84536 15.6156 7.76105 15.7656 7.61099C15.9157 7.46093 16 7.25741 16 7.04519C16 6.83297 15.9157 6.62945 15.7656 6.47939C15.6156 6.32933 15.4121 6.24502 15.1998 6.24502H2.72525L6.96613 2.00415C7.04934 1.93181 7.1167 1.84306 7.16399 1.74345C7.21128 1.64385 7.23748 1.53555 7.24093 1.42535C7.24439 1.31514 7.22503 1.20541 7.18408 1.10304C7.14312 1.00067 7.08145 0.907872 7.00294 0.830462C6.92442 0.753053 6.83076 0.692705 6.72782 0.653206C6.62488 0.613707 6.51488 0.595908 6.40474 0.600927C6.29459 0.605946 6.18668 0.633674 6.08775 0.682372C5.98883 0.731069 5.90104 0.799685 5.82989 0.883915L0.228733 6.48507C0.0821209 6.63465 0 6.83574 0 7.04519C0 7.25464 0.0821209 7.45573 0.228733 7.60531L5.82989 13.2065C5.98202 13.3387 6.17846 13.4087 6.37994 13.4024C6.58142 13.396 6.77309 13.3139 6.91664 13.1724C7.06018 13.0309 7.14502 12.8404 7.1542 12.639C7.16337 12.4376 7.09621 12.2402 6.96613 12.0862L2.72525 7.84536Z'
                              fill='#999A9A'
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <span className='uppercase'>
                  {state.chatbar.props?.model?.key
                    ? `${state.chatbar.props?.title || 'Direct Chat'}`
                    : `Chat`}
                </span>
              </div>
              <div className='livechat__flexRow'>
                {state.chatbar.props?.model?.key && !state.chatbar.loading && (
                  <>
                    <img
                      className={`livechat__mr20`}
                      src={EllipsisHIcon}
                      alt={'Options'}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <PopUp open={open}>
                      {popUpOptions.map(({ selectItem, onClick }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (onClick) {
                              onClick(state?.chatbar?.props?.model?.key);
                            }
                          }}
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      ))}
                    </PopUp>
                  </>
                )}
                <button onClick={() => onClose?.()}>
                  <img className='livechat__close' src={CloseIcon} alt='Close' />
                </button>
              </div>
            </div>
          </div>
          <div className='livechat__wrap'>
            <div className='livechat__body' ref={messageBox}>
              {state.chatbar.props?.model?.key && !state.chatbar.loading && (
                <div className='livechat__group'>
                  <ChatMessages
                    messages={state.chatbar?.messages}
                    mutateMessages={mutateMessages}
                  />
                </div>
              )}
              <div className='livechat__group'>
                {!state.chatbar.props?.model?.key && <ChatContacts contacts={contacts} />}
              </div>
              <div className='livechat__group'>
                {!state.chatbar.props?.model?.key && (
                  <ChatGroups groupChats={groupChats} />
                )}
              </div>
            </div>
            <div className='livechat__footer'>
              {state.chatbar.props?.model?.key && (
                <>
                  <form
                    className={`livechat__msg__compose`}
                    name='LiveChat-message'
                    onSubmit={handleSubmit(onPostMessage)}
                  >
                    <TextAreaInput
                      name={'message'}
                      required={true}
                      register={register}
                      placeholder={`Write a message in the ${state.chatbar.props?.model?.name} thread`}
                      update={true}
                      fullWidth={true}
                      onKeyDownHandler={handleSubmit(onPostMessage)}
                      validate={value => {
                        return (
                          [/^(?!.*<[^>]+>).*/g].every(pattern => pattern.test(value)) ||
                          'Cannot add html tags.'
                        );
                      }}
                    />
                    {errors?.message && (
                      <div className='error-text w-100'>{errors?.message?.message}</div>
                    )}
                    <div className={`livechat__send-btn flex-inline flex-justify-end`}>
                      <div className={`livechat__asset__controls`}>
                        {messageAttachment && (
                          <>
                            <div className='livechat__asset-remove'>
                              <img
                                src={CloseGray}
                                alt='Remove Attachment'
                                onClick={() => setMessageAttachment(null)}
                              />
                            </div>
                            <div className='livechat__asset-name'>
                              {messageAttachment?.file?.name}
                            </div>
                          </>
                        )}
                      </div>
                      <div className='livechat-attachment__drag-container'>
                        <DragAndDrop
                          onDropHandler={files => {
                            onAttachmentChangeHandler(files);
                          }}
                        >
                          <div className='livechat-attachment__drag-area'>
                            <label htmlFor={'messageAssetUpload'} className=''>
                              <img
                                className='livechat__attach'
                                src={PaperclipIcon}
                                alt='Add an Attachment'
                              />
                            </label>
                            <div className='image-upload'>
                              <input
                                id={'messageAssetUpload'}
                                type='file'
                                onChange={e => {
                                  const { files } = e.currentTarget;
                                  onAttachmentChangeHandler(files);
                                }}
                                name={'messageAssetUpload'}
                              />
                            </div>
                          </div>
                        </DragAndDrop>
                      </div>

                      <button className='button-regular color-white-bd-bg'>Send</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </Tween>
    </div>
  );
};

export default LiveChat;

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const getBytes = bytes => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i]
  );
};

export const Bytes = ({ bytes }) => <span>{getBytes(bytes)}</span>;

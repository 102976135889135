import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TimeAgo from 'react-time-ago';

import { Bytes } from './LiveChat';
import PopUp from '../PopUp/PopUp';
import { useFrameUI } from 'providers/FrameUIProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { UserContext } from 'providers/UserProvider';
import { deleteMessageModel } from 'services/Api';
import truncateText from 'utils/truncateText';
import DownloadIcon from 'assets/icons/download-gray.svg';
import EllipsisHIcon from 'assets/icons/ellipsis-h-grey.svg';
import { ASSET_TYPES } from 'constants/assets';

export type Message = {
  key: number;
  senderKey: number;
  profilePictureUrl?: string;
  firstName: string;
  lastName: string;
  lastMessage: string;
  timestamp?: number;
  assets?: any;
  assetMetadata?: any;
  assetUrl?: string;
  attachment_type?: string;
  attachment_name?: string;
};

export interface IChatMessages {
  messages?: Message[];
  mutateMessages?: () => void;
}

const ChatMessages: React.FC<IChatMessages> = props => {
  const { state } = useFrameUI();
  const { messages, mutateMessages } = props;
  const [newMessages, setNewMessages] = useState([]);

  const dateFormatOptions = useMemo(
    () =>
      ({
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      } as Intl.DateTimeFormatOptions),
    []
  );

  const { setDisplayToast } = useContext(NotificationsContext);
  const { user } = useContext(UserContext);
  const { idToken } = user;

  useEffect(() => {
    setNewMessages(messages.reverse())
  }, [messages])

  const deleteMessageActionHandler = useCallback(
    async messageKey => {
      setOpen(false);

      const response = await deleteMessageModel(idToken, messageKey);

      if (response?.data?.error) {
        setDisplayToast({
          type: 'error',
          message: `Error while deleting message`,
        });
      } else {
        setDisplayToast({
          type: 'success',
          message: `Message has been deleted successfully.`,
        });
        mutateMessages();
      }
    },
    [idToken]
  );

  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);
  const messageOptions = useMemo(
    () => [
      {
        selectItem: 'Delete',
        onClick: messageKey => {
          setDialogProps({
            dialogBody: 'Are you sure you would like to delete this image?',
            btnActionHandler: () => {
              deleteMessageActionHandler(messageKey);
            },
          });
          setOpenDialog(true);
        },
      },
    ],
    [deleteMessageActionHandler]
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <>
      <div className='livechat__heading'>
        {state.chatbar?.props?.model?.model === 'Account' ? 'Messages' : 'Comments'}
      </div>
      {newMessages &&
        newMessages.map(
          ({
            key,
            senderKey,
            timestamp,
            assets,
            attachment_type,
            attachment_name,
            assetMetadata,
            assetUrl,
            firstName,
            lastName,
            lastMessage,
            profilePictureUrl,
          }) => (
            <div className='livechat__item-msg' key={timestamp}>
              <div className='livechat__item__metadata-row flex-inline'>
                <div className='livechat__item__metadata flex-inline'>
                  {!profilePictureUrl ? (
                    <div className='livechat__avatar'>
                      {firstName?.slice(0, 1)}
                      {lastName?.slice(0, 1)}
                    </div>
                  ) : (
                    <img
                      src={profilePictureUrl}
                      alt='Profile'
                      className='livechat__avatar'
                    />
                  )}

                  <div className='livechat__name'>
                    {truncateText(`${firstName || ''} ${lastName || ''}`, 20)}
                  </div>
                  <TimeAgo date={timestamp * 1000} />
                </div>
                {senderKey === user?.account?.key && (
                  <div className='livechat__options'>
                    <img
                      src={EllipsisHIcon}
                      alt='Options'
                      onClick={() => {
                        setDialogProps({
                          dialogBody:
                            'Are you sure you would like to delete this message?',
                          btnActionHandler: () => {
                            deleteMessageActionHandler(key);
                          },
                        });
                        setOpenDialog(true);
                      }}
                    />
                    <PopUp open={open && selectedItem === key}>
                      {messageOptions.map(({ selectItem, onClick }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (onClick) {
                              onClick(key);
                            }
                          }}
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      ))}
                    </PopUp>
                  </div>
                )}
              </div>
              <div className='livechat__content'>
                <div className='livechat__msg livechat__msg-single-chat'>
                  {lastMessage}
                </div>
                {attachment_type && assets?.[attachment_type]?.progress > 0 && (
                  <div
                    className='livechat__asset'
                    onClick={() => {
                      state.chatbar?.props?.onClickMessageAsset?.(
                        assets[attachment_type]
                      );
                    }}
                  >
                    <div
                      className='livechat__asset__metadata'
                      onClick={() => {
                        setSelectedItem(key);
                      }}
                    >
                      <div className='livechat__asset__metadata-thumbnail'>
                        {attachment_type == ASSET_TYPES.image.type ? (
                          <img src={assetUrl} alt='thumbnail' />
                        ) : (
                          <span>{attachment_type}</span>
                        )}
                      </div>
                      <div className='livechat__asset__metadata-name'>
                        {state.chatbar?.props?.model?.model === 'Account'
                          ? `${attachment_name}`
                          : 'Annotation'}
                      </div>
                      <div className='livechat__asset__metadata-size'>
                        <Bytes bytes={assetMetadata?.size} />
                      </div>
                    </div>
                    {assets?.[attachment_type]?.progress === 1 && assetUrl && (
                      <a
                        className='livechat__asset__download'
                        target='_blank'
                        rel='noreferrer'
                        href={assetUrl}
                        download={attachment_name || assetMetadata?.name}
                        title={attachment_name || assetMetadata?.name}
                      >
                        <img src={DownloadIcon} alt='Download' />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        )}
    </>
  );
};

export default ChatMessages;

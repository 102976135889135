import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Link, navigate } from '@reach/router';
import useSWR from 'swr';
import classNames from 'classnames';
import LogRocket from 'logrocket';

import './StyleSummary.scss';
import './StyleSampleReview.scss';
import 'components/Dashboard/AFSAssetReview/AFSAssetReview.scss';

import StyleSampleReviewHeader from './StyleSampleReviewHeader';
import { ApiAsset } from './StyleSummary';
import AssetAnnotationModal from './AssetAnnotationModal';
import { UserContext } from 'providers/UserProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { UploadContext } from 'providers/UploadProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import SlickRailMode from './SlickRailMode';
import StyleSampleListView from './StyleSampleListView';
import { ConfirmDialog } from 'components';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import {
  deleteModel,
  mmAPI,
  postActivityMethod,
  putModel,
  referenceMethodClone,
  postModel,
} from 'services/Api';
import useMessages from 'hooks/useMessages';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getImageDimensions } from 'utils/getImageDimensions';
import { WORKFLOW_STATUS } from './StyleSummaryContainer';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import RailIcon from 'assets/icons/Icon-rail.svg';
import PlusIcon from 'assets/icons/plus-icon-dark.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import Asset from 'components/Asset';
import PlaceholderImg from 'assets/images/placeholder-image.png';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import { CreateModelModal } from '../../Common/Modal/CreateModelModal';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { getPythonVersion } from '../../../services/Api';

export type APIMedia = {
  assets: {
    image: ApiAsset;
  };
  created: number;
  modified: number;
  brand: number;
  key: number;
  name: string;
  pose: string;
  position: number;
  products: number[];
  shot: string;
  size: string;
  sku: string;
  source: 'brand' | 'vsm';
  style: number;
  style_number: string;
  tags: string[];
  type: 'image' | 'fabric' | 'trim' | 'hardware' | 'pattern';
  src?: string;
};

export type StyleSampleReview = {
  style: any;
  media: APIMedia;
  brand_model_id: string;
  style_model_id: string;
  reference_model_id: string;
  references: any;
  sampleSlideshow: any[];
  sampleSlideshowUploading: any[];
  updatePositionsHandler?: any;
  onDropHandler?: (files: File[], path?: number[]) => void;
};

const VIEWMODE = {
  RAIL: 'RAIL',
  LIST: 'LIST',
};

const StyleSampleReview: React.FC<StyleSampleReview> = props => {
  const {
    style,
    media,
    brand_model_id,
    style_model_id,
    reference_model_id,
    references,
    sampleSlideshow,
    sampleSlideshowUploading,
    onDropHandler,
    updatePositionsHandler,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const [openModal, setOpenModal] = useState(false);
  const { state, dispatch } = useFrameUI();

  const virtualSampleNavItems = ['Virtual Sample', 'Archived'];
  const [tab, setTab] = useState(virtualSampleNavItems[0]);

  const initialSlideIndex = sampleSlideshow.filter(s => s.type !== 'folder').forEach((slide, index) => {
    if (+slide.reference_model_id === +reference_model_id) {
      return index;
    }
  });

  const [slideIndex, setSlideIndex] = useState<any | null>(initialSlideIndex);
  const [referenceMediaIndex, setReferenceMediaIndex] = useState<any | null>(0);

  useEffect(() => {
    const setIndexEffect = () => {
      sampleSlideshow.filter(s => s.type !== 'folder').forEach((slide, index) => {
        if (+slide.reference_model_id === +reference_model_id) {
          setSlideIndex(index);
        }
      });
    };
    setIndexEffect();
  }, [sampleSlideshow, reference_model_id]);

  const [lastReferences, setLastReferences] = useState([])

  const { mutate: mutateReferences } = useSWR(
    [`/api/reference/query/style/`, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    setLastReferences(references);
    const setPositionsEffect = async () => {
      let str = ''
      references.sort((a, b) => a.position - b.position).forEach((ref, index) => {
        str = str + `${ref.key}:${index + 1}` + `${index < references.length - 1 ? ';' : ''}`
      });
      if (str !== '') await updatePositionsHandler({ position: str });
    }
    if (references.length < lastReferences.length) setPositionsEffect();
  }, [references])

  const [viewMode, setViewMode] = useState(VIEWMODE.RAIL);
  const [open, setOpen] = useState(false);
  const [, setModalOpen] = useState(false);
  const [, setActiveModalComponent] = useState(null);
  const batchUploadPlus = useRef();

  const deleteVSMActionHandler = async data => {
    const response = await deleteModel(idToken, 'reference', data.key);
    if (response?.data?.data) {
      setDisplayToast({
        persist: false,
        type: 'success',
        message: `Deleted Virtual Sample ${data?.name}`,
      });
    }
    mutateReferences()
  };
  
  const tagAsFinalFile = async data => {
    if (data.status !== WORKFLOW_STATUS.FINAL.value) {
      const response = await referenceMethodClone(idToken, {
        params: {
          reference: data.key,
        },
      });
      if (response?.data?.data) {
        const clonedReference = response?.data?.data;
        console.log('clonedReference', clonedReference);
        setDisplayToast({
          persist: false,
          type: 'success',
          message: `Tagged Virtual Sample ${clonedReference?.name} as Final`,
        });
      }
      mutateReferences();
    }
  };
  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-downloaded'
      });
      LogRocket.track('reference-downloaded', {
        reference: props.key,
        user: JSON.stringify(user, null, 2),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'StyleSampleReview: error on activity POST',
          journey: 'reference-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user, null, 2),
        },
      });
    }
  };

  const renameModelActionHandler = async values => {
    const {
      name,
      data: { key },
    } = values;
    const { data: referenceUpdate } = await putModel(idToken, 'reference', key, { name });
    if (referenceUpdate.data?.error) {
      setDisplayToast({ type: 'error', message: referenceUpdate.data?.error?.message });
    } else {
      setDisplayToast({ type: 'success', message: `Reference name updated` });
      setOpenModal(false);
      mutateReferences();
    }
  };

  const [editTileOptions, setEditTileOptions] = useState([]);
  const editTileOption = [
    {
      selectItem: 'Download',
      disabled: false,
      download: true,
      onClick: onClickActivityHandler,
    },
    {
      selectItem: 'Delete',
      ModalComponent: (
        <ConfirmDialog
          dialogBody='Deleting virtual sample can’t be undone. Are you sure you want to continue delete the sample?'
          btnActionHandler={deleteVSMActionHandler}
        />
      ),
      disabled: false,
    },
    {
      selectItem: 'Move',
      disabled: true,
    },
    {
      selectItem: 'Rename',
      disabled: false,
      ModalComponent: (
        <CreateModelModal
          formId='Rename'
          modalHeading='Rename'
          modelName=''
          btnCloseLabel='CANCEL'
          btnActionLabel='RENAME'
          open={openModal}
          setOpen={setOpenModal}
          modelInputLabel='NEW NAME'
          btnActionHandler={renameModelActionHandler}
          closeOnSubmit={true}
        />
      ),
    },
  ];
  useEffect(() => {
    const version = async () => {
      const res = await getPythonVersion();
      // Only add *Lock As Final File* with py3
      if (res.data?.data?.version === 3) {
        editTileOption.push(featureFlaggedOption);
      }
      setEditTileOptions(editTileOption);
    };
    version();
  }, []);

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: 'Virtual Atelier',
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: () => window.history.back(),
      });
    };
    filterBarEffect();

    return () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: null,
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: null,
      });
    };
  }, [dispatch]);

  type NavigateToSample = { direction?: 'next' | 'previous'; reference?: number };
  const navigateToSample = (options: NavigateToSample) => {
    const { reference } = options;
    if (reference) {
      navigate(`/brand/${brand_model_id}/style/${style_model_id}/samples/${reference}`);
      return;
    }
  };

  const onClickSampleAsset = reference_key => {
    navigateToSample({ reference: reference_key });
  };

  const onClickFolder = reference_key => {
    setCurrentPath(prev => [...prev, reference_key]);
  };

  const { firebaseStorageUrl } = useContext(UploadContext);
  const getFirebaseStorageUrl = async (asset, resize = null) => {
    const { path } = asset;
    let pathResized;
    if (resize) {
      const pathResizeIndex = path.lastIndexOf('.png');
      pathResized = [...path];
      pathResized.splice(pathResizeIndex, 0, resize);
    }
    if (asset) {
      try {
        const firebaseUrl = await firebaseStorageUrl(
          resize ? pathResized.join('') : path
        );
        return Promise.resolve(firebaseUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const srcToImage = src => {
    if (!src) return null;
    return new Promise(function (resolve, reject) {
      const image = new Image();
      image.crossOrigin = '*';
      image.onload = () => {
        resolve(image);
      };
      image.src = src;
    });
  };

  const srcToFileObjectUrl = (name, src) => {
    if (!src) return null;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = event => {
        const blob = xhr.response;
        const file = blobToFile(blob, name);
        const fileObjectUrl = URL.createObjectURL(file);
        resolve(fileObjectUrl);
      };
      xhr.open('GET', src);
      xhr.send();
    });
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
  };

  const { data: referenceModel, mutate: mutateReferenceModel } = useSWR(
    [`/api/reference/model/${reference_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const { data: styleModel } = useSWR(
    [`/api/style/model/${style_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const [imageUrlZoomed, setImageUrlZoomed] = useState(null);
  const [sampleImage, setSampleImage] = useState(null);
  const [sampleSlideshowLoaded, setSampleSlideshowLoaded] = useState(
    sampleSlideshow?.reduce((prev, current) => {
      prev[current] = false;
      return prev;
    }, {})
  );
  const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();

  useEffect(() => {
    sampleSlideshow.map(async s => {
      if (!sampleSlideshowLoaded[s.key] || sampleSlideshowLoaded[s.key] === false) {
        const resolvedSample = await srcToImage(s.assetUrl);
        setSampleSlideshowLoaded(prev => ({
          [s.key]: true,
          ...prev,
        }));
      }
    });
  }, [sampleSlideshow, sampleSlideshowLoaded]);
  useEffect(() => {
    const referenceModelEffect = async referenceModelVal => {
      if (!referenceModelVal || sampleSlideshow.length === 0) {
        return;
      }

      const imageUrlFromAsset = sampleSlideshow.find(
        item => parseInt(item.reference_model_id) === parseInt(reference_model_id)
      )?.assetUrl;

      const { height, width, aspectRatio } = await getImageDimensions(imageUrlFromAsset);

      const fileObjectUrlZoomed = await srcToFileObjectUrl(
        referenceModelVal.name,
        imageUrlFromAsset
      );
      setImageUrlZoomed({
        reference_model_id: reference_model_id,
        src: fileObjectUrlZoomed,
        width,
        height,
      });

      const resizedImageUrl = await getFirebaseStorageUrl(
        referenceModelVal.assets['image'],
        '_300x400'
      );
      const fileObjectUrl = await srcToFileObjectUrl(
        referenceModelVal.name,
        resizedImageUrl
      );
      const resolvedSampleImage = {
        ...referenceModel.assets['image'],
        src: fileObjectUrl,
        aspectRatio,
      };
      const resolvedSampleImageZoomed = {
        ...referenceModel.assets['image'],
        src: fileObjectUrlZoomed,
        aspectRatio,
      };
      setSampleImage(resolvedSampleImageZoomed);
    };
    referenceModelEffect(referenceModel);
  }, [referenceModel, sampleSlideshow, viewportHeight, viewportWidth]);

  const [referenceMedia, setReferenceMedia] = useState(null);
  useEffect(() => {
    const mediaEffect = async mediaVal => {
      const getMediaUrls = async m => {
        const { assets } = m;
        const assetUrl =
          assets?.image?.progress > 0
            ? await firebaseStorageUrl(assets?.image?.path)
            : null;
        const { aspectRatio } = await getImageDimensions(assetUrl);
        return {
          ...m,
          assetUrl,
          reference_model_id,
          aspectRatio,
        };
      };

      const getMedia = mediaModels => {
        return Promise.all(
          mediaModels.filter(m => m.assets?.image?.progress > 0).map(m => getMediaUrls(m))
        );
      };

      const mediaFiltered = mediaVal.filter(m => m.source === 'brand');
      const mediaResolved = await getMedia(mediaFiltered);
      setReferenceMedia(mediaResolved.filter(m => m.assetUrl));
    };
    mediaEffect(media);
  }, [media]);

  const { messages, setMessageModelAssoc, onMessagesOpen } = useMessages(idToken, {
    model: null,
    reset: true,
  });

  useEffect(() => {
    if (state.chatbar?.props?.model?.key === style?.key) {
      // console.log('onMessagesOpen', state.chatbar?.props?.model?.key);
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: false });
      // onMessagesOpen();
    } else {
      console.log(
        'xxxxxxxxxxxxxxxxx',
        'StyleSampleReivew style?.key',
        style?.key,
        'state.chatbar?.props?.model?.key === style?.key',
        state.chatbar?.props?.model?.key === style?.key,
        state.chatbar?.props?.model?.key
      );
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
      setMessageModelAssoc({
        ...style,
      });
    }
  }, [style, state.chatbar?.props?.model?.key]);

  const { setAppModal, setOpenAppModal, openAppModal } = useContext(ModalsContext);
  const openAnnotationModal = () => {
    if (imageUrlZoomed?.reference_model_id !== reference_model_id) {
      return;
    }
    const modal = (
      <AssetAnnotationModal
        heading='Annotate'
        open={openAppModal}
        setOpen={setOpenAppModal}
        imgSrc={imageUrlZoomed?.src}
        reference={referenceModel}
      />
    );
    setAppModal(modal);
    setOpenAppModal(true);
  };

  const [referenceImagesToggle, setReferenceImagesToggle] = useState(true);

  const { setDisplayToast } = useContext(NotificationsContext);

  const featureFlaggedOption = {
    selectItem: 'Lock as final file',
    onClick: tagAsFinalFile,
    disabled: false,
  };

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const [currentPath, setCurrentPath] = useState([]);

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < currentPath.length; i += 1) {
      const key = currentPath[i];
      const reference = sampleSlideshow.find(r => +r.key === +key);
      link += `/${reference.name}`;
      const page: IPages = {
        title: reference.name,
      };
      if (i < currentPath.length - 1) {
        page.link = link;
        page.onClick = () => setCurrentPath(currentPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (currentPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setCurrentPath(currentPath.slice(0, currentPath.length - 1)),
      });
    }
    return pagesArray;
  }, [currentPath, sampleSlideshow]);

  const currentPathname = currentPath.map(
    key => sampleSlideshow.find(r => +r.key === +key).name
  );

  const onCreateFolder = useCallback(async () => {
    await postModel(idToken, 'reference', {
      type: 'folder',
      brand: brand_model_id,
      model: 'Style',
      model_id: style.key,
      name: 'untitled folder',
      path: currentPath.length > 0 ? currentPath.join(',') : null,
      pathname: '/' + currentPathname.join('/'),
      notes: 'folder',
      tags: 'vsm,sample',
    });
    mutateReferences();
  }, [brand_model_id, style.key, currentPath, currentPathname]);

  const popUpOptions = [
    {
      selectItem: 'Add new',
      onClick: () => {
        batchUploadPlus.current && batchUploadPlus.current.click();
      },
    },
    {
      selectItem: 'Create folder',
      onClick: () => {
        onCreateFolder();
      },
    },
  ] as IPopUpOptions[];

  const filteredSampleSlideshow = sampleSlideshow.filter(r => {
    const rPath = r.path ?? [];
    const len = Math.max(rPath.length, currentPath.length);
    for (let i = 0; i < len; i++) {
      if (rPath[i] != currentPath[i]) {
        return false;
      }
    }
    return true;
  });

  return (
    <>
      <StyleSampleReviewHeader
        style={style}
        reference={referenceModel}
        asset={referenceModel.assets.image}
        imageUrlZoomed={imageUrlZoomed}
        heading={{ label: `${style?.name}` }}
        openAnnotationModal={openAnnotationModal}
        onMessagesOpen={onMessagesOpen}
        referenceImagesToggle={referenceImagesToggle}
        setReferenceImagesToggle={setReferenceImagesToggle}
        slideIndex={slideIndex}
        slides={filteredSampleSlideshow.filter(s => s.type !== 'folder')}
        {...props}
      />
      <div className='style-sample-review'>
        <div className='container-large'>
          <div className='style-sample-review__compare'>
            <div
              className={classNames('style-sample-review__left-col', {
                hide: !referenceImagesToggle,
              })}
            >
              <div className='style-sample-review__heading px-3 mb-4'>
                <span>Reference Image</span>
                <div className='product-listing-dashboard__arrows'>
                  <img
                    className='product-listing-dashboard__main-prev'
                    src={PrevIcon}
                    alt='Previous'
                    onClick={() =>
                      setReferenceMediaIndex(
                        referenceMediaIndex === 0
                          ? referenceMedia.length - 1
                          : referenceMediaIndex - 1
                      )
                    }
                  />
                  <img
                    className='product-listing-dashboard__main-next'
                    src={PrevIcon}
                    alt='Next'
                    onClick={() =>
                      setReferenceMediaIndex(
                        referenceMediaIndex === referenceMedia.length - 1
                          ? 0
                          : referenceMediaIndex + 1
                      )
                    }
                  />
                </div>
              </div>
              <div style={{ marginTop: '40px' }}>
                <SlickRailMode slideIndex={referenceMediaIndex} media={referenceMedia?.length > 0 ? referenceMedia : [{ assetUrl: PlaceholderImg }]} />
              </div>
            </div>
            <div className='style-sample-review__right-col'>
              <div className='style-sample-review__heading px-3 mb-4'>
                <DashboardNav
                  dashboardNavItems={virtualSampleNavItems.map(item => ({
                    title: item,
                    activeBtn: item === tab,
                    disableBtn: item === 'Archived',
                  }))}
                  darkMode={false}
                  changeTab={setTab}
                />
                <div className='style-sample-review__buttons-wrapper'>
                  <div className='product-listing-dashboard__arrows'>
                    <img
                      className='product-listing-dashboard__main-prev'
                      src={PrevIcon}
                      alt='Previous'
                      onClick={() =>
                        setSlideIndex(
                          slideIndex === 0 ? filteredSampleSlideshow.filter(s => s.type !== 'folder').length - 1 : slideIndex - 1
                        )
                      }
                    />
                    <img
                      className='product-listing-dashboard__main-next'
                      src={PrevIcon}
                      alt='Next'
                      onClick={() =>
                        setSlideIndex(
                          slideIndex === filteredSampleSlideshow.filter(s => s.type !== 'folder').length - 1 ? 0 : slideIndex + 1
                        )
                      }
                    />
                  </div>
                  {viewMode === VIEWMODE.RAIL ? (
                    <img
                      className='style-sample-review__view-icon'
                      src={RailIcon}
                      alt='Rail'
                      onClick={() => {
                        setViewMode(VIEWMODE.LIST);
                        setSlideIndex(0);
                      }}
                    />
                  ) : (
                    <img
                      className='style-sample-review__view-icon'
                      src={ListIconBlack}
                      alt='List'
                      onClick={() => setViewMode(VIEWMODE.RAIL)}
                    />
                  )}
                  <img
                    src={PlusIcon}
                    className='style-sample-review__add'
                    onClick={() => setOpen(!open)}
                  />
                </div>
                <div
                  className={classNames(
                    'style-sample-review__pop-up',
                    open && 'style-sample-review__pop-up-visible'
                  )}
                >
                  <PopUp open={open} setOpen={setOpen}>
                    {popUpOptions &&
                      popUpOptions.map(({ selectItem, to, onClick, ModalComponent }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (ModalComponent) {
                              handlePopUpModal(ModalComponent);
                            } else if (onClick) {
                              onClick();
                            }
                          }}
                          key={selectItem}
                        >
                          {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                        </li>
                      ))}
                  </PopUp>
                  <input
                    ref={batchUploadPlus}
                    id='batchUploadPlus'
                    type='file'
                    multiple
                    className='d-none'
                    onChange={e => {
                      const { files } = e.currentTarget;
                      onDropHandler(files, currentPath);
                    }}
                    name='batchUpload'
                  />
                </div>
              </div>
              <Breadcrumbs pages={pages} />

              {sampleSlideshowUploading?.map(upload => (
                <AssetProgress key={upload.key} {...upload} />
                ))}

              {!sampleSlideshowUploading?.length &&
                filteredSampleSlideshow?.length > 0 &&
                filteredSampleSlideshow[0]?.progress !== -1 &&
                !Object.values(sampleSlideshowLoaded)?.some(s => s.loaded === false) &&
                (viewMode === VIEWMODE.RAIL ? (
                  <SlickRailMode
                    media={filteredSampleSlideshow.filter(s => s.type !== 'folder')}
                    slideIndex={slideIndex}
                    onClickSampleAsset={onClickSampleAsset}
                  />
                  ) : (
                    <StyleSampleListView
                      media={filteredSampleSlideshow}
                      editTileOptions={editTileOptions}
                      onClickSampleAsset={onClickSampleAsset}
                      updatePositionsHandler={updatePositionsHandler}
                      onClickRow={onClickFolder}
                    />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AssetProgress = props => {
  const { asset, imageAsFileSeed, save, onUploadHandler } = props;
  const [, metadata] = asset;
  const { imageUploadProgress } = useContext(UploadContext);
  const progress = imageUploadProgress[metadata?.path];

  return (
    <Asset
      asset={asset}
      saveImages={save}
      imageAsFileSeed={imageAsFileSeed}
      onUploadHandler={onUploadHandler}
    >
      <span>{!!progress && ` Uploading: ${progress}%`}</span>
    </Asset>
  );
};

export default StyleSampleReview;

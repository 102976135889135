import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form/dist/types';

export interface TextAreaInputProps {
  label?: string;
  name: string;
  register?: ({ required, pattern, validate }) => LegacyRef<HTMLInputElement>;
  required?: boolean | string;
  placeholder?: string;
  errors?: FieldErrors;
  value?: string;
  disabled?: boolean;
  pattern?: string;
  validate?: (arg0: string) => void;
  onChange?: (arg0: string) => void;
  onKeyDownHandler?: (arg?: any) => void;
  update?: boolean;
  fullWidth?: boolean;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  label,
  name,
  register,
  required,
  placeholder,
  errors = {},
  value = '',
  disabled = false,
  pattern,
  onChange,
  onKeyDownHandler,
  update = false,
  fullWidth = false,
  validate,
}) => {
  const checkErrors = () => {
    return Object.prototype.hasOwnProperty.call(errors, name);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.metaKey) {
      e.preventDefault();
      onKeyDownHandler?.();
    }
  };

  return (
      <div className={`col-md-${fullWidth ? 12 : 6}`}>
        <div className='control-block'>
          <label
            className={classNames('control--label', {
              'control--required': required && label,
            })}
          >
            {label}
          </label>
          {update ? (
            <div>
              <textarea
                className='control control--textarea'
                placeholder={placeholder}
                defaultValue={value}
                name={name}
                ref={
                  register
                    ? register({
                        required,
                        pattern,
                        validate,
                      })
                    : null
                }
                onKeyDown={handleKeyDown}
                disabled={disabled}
              />
              {/* {checkErrors() && (
                <span className='error-text'>This field is required</span>
              )} */}
            </div>
          ) : (
            <span className='dashboard-forms__updated'>{value}</span>
          )}
        </div>
      </div>
  );
};

export default TextAreaInput;

import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form/dist/types';

export interface SwitchInputProps {
  label: string;
  name: string;
  register?: ({ required, pattern }) => LegacyRef<HTMLInputElement>;
  required?: boolean;
  placeholder?: string;
  errors?: FieldErrors;
  value?: boolean;
  disabled?: boolean;
  pattern?: string;
  onChange?: (arg0: string) => void;
  update?: boolean;
  fullWidth?: boolean;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  name,
  register,
  required,
  placeholder,
  errors = {},
  value = false,
  disabled = false,
  pattern,
  onChange,
  update = false,
  fullWidth = false,
}) => {
  const checkErrors = () => {
    return Object.prototype.hasOwnProperty.call(errors, name);
  };

  const id = `switch__${name}`;

  return (
    <div className={`col-md-${fullWidth ? 12 : 6}`}>
      <div className='control-block'>
        {update ? (
          <div className='switch'>
            <input
              className='control control--switch'
              type='checkbox'
              defaultChecked={value}
              placeholder={placeholder}
              name={name}
              id={id}
              ref={
                register
                  ? register({
                      required,
                      pattern,
                    })
                  : null
              }
            />
            <label htmlFor={id}>{label}</label>
            {checkErrors() && <span className='error-text'>This field is required</span>}
          </div>
        ) : (
          <>
            <label
              className={classNames('control--label', { 'control--required': required })}
            >
              {label}
            </label>
            <span className='dashboard-forms__updated'>{value ? 'Yes' : 'No'}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default SwitchInput;

import React from 'react';
import ImageInput from 'components/Dashboard/FormComponents/ImageInput';
import Asset from 'components/Asset';
import SidebarItem from './SidebarItem';
import SidebarItemColor from './SidebarItemColor';
import ArrowIcon from 'assets/icons/Icon-angle-right.svg';
import 'components/Dashboard/Sidebar/Sidebar.scss';

export interface BrandSidebarProps {
  brand: { key: string; assets: { logo; hero } };
  saveChanges: boolean;
  state: any;
  onUploadHandler: () => Promise<void>;
  onColorChange?: (id, color) => void;
  toggleColorPicker?: (id, open) => void;
  setEditTemplate?: () => void;
  handleUploadButtonClicked?: (e) => void;
}

const BrandSidebar: React.FC<BrandSidebarProps> = ({
  brand,
  saveChanges,
  state,
  onUploadHandler,
  onColorChange,
  toggleColorPicker,
  setEditTemplate,
  handleUploadButtonClicked,
}) => {
  const { logo, hero } = brand?.assets;

  return (
    <div className='sidebar'>
      <div className='sidebar__heading mm--h4'>Branding</div>
      <div className='sidebar__content'>
        <SidebarItem header='Universal Style'>
          <SidebarItem
            baseName='sidebar__subitem'
            header='Logo'
            tooltip='We suggest that you use an image of at least 200pixel in height to ensure your logo is ready for both retina and non-retina displays.'
          >
            <Asset
              asset={['logo', logo]}
              saveImages={saveChanges}
              onUploadHandler={onUploadHandler}
              onDropHandler={fileList =>
                handleUploadButtonClicked({ logoFile: fileList[0] })
              }
            >
              <ImageInput
                logo={true}
                name='logo'
                update={state.customize}
                handleUploadButtonClicked={e =>
                  handleUploadButtonClicked({ logoFile: e.target.files[0] })
                }
              />
            </Asset>
          </SidebarItem>
          <SidebarItem baseName='sidebar__subitem' header='Panel'>
            <SidebarItemColor
              {...state.panelBackground}
              setOpen={toggleColorPicker}
              onColorChange={onColorChange}
              header='Background Color'
            />
            <SidebarItemColor
              {...state.panelFont}
              setOpen={toggleColorPicker}
              onColorChange={onColorChange}
              header='Font Color'
            />
          </SidebarItem>
        </SidebarItem>
        <SidebarItem header='Landing Page'>
          <div className='sidebar__item-name'>
            <div className='sidebar__wrap'>
              Template
              <div className='sidebar__arrow'>
                <img src={ArrowIcon} alt='Arrow Right Icon' onClick={setEditTemplate} />
              </div>
            </div>
          </div>
        </SidebarItem>
        <SidebarItem header='Login Page'>
          <SidebarItem
            baseName='sidebar__subitem'
            header='Background image'
            tooltip='We suggest that you use an image of at least 1920 pixels in width to ensure your logo is ready for both retina and non-retina displays.'
          >
            <Asset
              asset={['hero', hero]}
              saveImages={saveChanges}
              onUploadHandler={onUploadHandler}
              onDropHandler={fileList =>
                handleUploadButtonClicked({ bgImageFile: fileList[0] })
              }
            >
              <ImageInput
                logo={true}
                name='hero'
                update={state.customize}
                handleUploadButtonClicked={e =>
                  handleUploadButtonClicked({ bgImageFile: e.target.files[0] })
                }
              />
            </Asset>
            <SidebarItemColor
              {...state.loginBackground}
              setOpen={toggleColorPicker}
              onColorChange={onColorChange}
              header='Background Color'
            />
            <SidebarItemColor
              {...state.loginFont}
              setOpen={toggleColorPicker}
              onColorChange={onColorChange}
              header='Font Color'
            />
          </SidebarItem>
        </SidebarItem>
      </div>
    </div>
  );
};

export default BrandSidebar;

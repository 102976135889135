import React, { useEffect, useState, useContext } from 'react';
import { useParams } from '@reach/router';
import { Controller, useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './CollectionAccessModal.scss';
import Modal from 'components/Common/Modal/Modal';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { ProfileAssetCircle } from 'components/Dashboard/SidebarInfo/SidebarInfo';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { postInviteMethodEmail, postInviteModel } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { ConditionalWrap } from 'components/ConditionalWrap';
import useTeam, { JobType } from 'hooks/useTeam';
import classNames from 'classnames';

export type ModelMember = {
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: string;
  profileAsset?: string;
  inviteUrl?: string;
  key: string;
  created: number;
};

export type ModelAccessModal = {
  heading: string;
  allMembers: ModelMember[];
  allInvites: ModelMember[];
  open: boolean;
  model?: any;
  jobType?: JobType;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModelAccessModal: React.FC<ModelAccessModal> = props => {
  const { model, heading, allMembers, allInvites, open, setOpen, jobType } = props;

  const { brand_model_id } = useParams();

  const {
    user: { idToken },
  } = useContext(UserContext);

  const { team, setTeamModelAssoc, mutateAll, teamModelAssoc } = useTeam(idToken, {
    brand_model_id,
  });

  const [allUsers, setAllUsers] = useState(null);
  useEffect(() => {
    const membersInviteesAggregate = allMembers
      ?.concat(allInvites)
      .sort((a, b) => b.created - a.created);
    setAllUsers(membersInviteesAggregate);
  }, [allMembers, allInvites]);

  const { handleSubmit, register, setValue, control, errors } = useForm({});
  const [openPopUp, setOpenPopUp] = useState([]);

  const { setDisplayToast } = useContext(NotificationsContext);
  const [, setModalJobType] = useState(null);

  const memberInviteRequest = async (values, model) => {
    const { email, first_name, team, modelName } = values;
    if (!team || !teamModelAssoc.jobType) {
      setDisplayToast({
        type: 'error',
        message: `Error granting access to ${modelName || ''}`,
      });
    } else {
      try {
        const { data: invite } = await postInviteModel(idToken, {
          email: email.trim(),
          team,
          first_name,
          role: 'user',
          job_type: teamModelAssoc.jobType,
        });
        if (invite.data?.error || invite.error) {
          setDisplayToast({
            type: 'error',
            message: invite.data?.error?.message || invite.error?.message,
          });
        } else {
          const { data: inviteData } = await postInviteMethodEmail(idToken, {
            key: invite.data.key,
            path: `${process.env.REACT_APP_PUBLIC_URL}/invite`,
          });
          const { link } = inviteData;
          setOpen(false);
          setDisplayToast({
            type: 'success',
            message: `${email} has been invited to ${modelName}`,
          });

          mutateAll();

          return {
            [email]: {
              invite: invite?.data,
              inviteEmail: inviteData,
              link,
            },
          };
        }
      } catch (error) {
        if (error?.message) {
          setDisplayToast({
            type: 'error',
            message: error.message,
          });
        }
      }
    }
  };

  const onSubmit = values => {
    console.log('memberInviteRequest', values, model);
    memberInviteRequest?.(values, model);
  };

  const NameEmailSelectProps = props => {
    return <CreatableSelect {...props} isClearable components={{ SingleValue }} />;
  };

  const SingleValue = ({ children, ...props }) => {
    const [emailValue, setEmailValue] = useState('');
    useEffect(() => {
      const nameEmailChildrenEffect = nameEmailVal => {
        if (!nameEmailVal) return;
        const nameEmailSeparatorIndex = nameEmailVal.lastIndexOf('<') + 1;
        if (nameEmailSeparatorIndex > 0) {
          const name = nameEmailVal.substring(0, nameEmailSeparatorIndex - 1);
          setValue('first_name', name, { shouldValidate: true });
        }
        const email = nameEmailVal?.substring(
          nameEmailSeparatorIndex,
          nameEmailSeparatorIndex > 0 ? nameEmailVal.length - 1 : nameEmailVal.length
        );
        setEmailValue(email);
      };
      nameEmailChildrenEffect(children);
    }, [children]);
    return <components.SingleValue {...props}>{emailValue}</components.SingleValue>;
  };

  const accessModalItemOptions: IPopUpOptions[] = [
    {
      selectItem: 'Revoke',
    },
  ];

  useEffect(() => {
    setTeamModelAssoc(prev => ({
      ...prev,
      model,
    }));
  }, [model]);

  useEffect(() => {
    setTeamModelAssoc(prev => ({
      ...prev,
      jobType,
    }));
  }, [jobType]);

  return (
    <div className='collection-access-modal'>
      <Modal
        modalHeading={heading}
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
        modalSubheading={``}
        closeOnClickAway={false}
        modalBtnActionHandler={() => setOpen(false)}
      >
        <form id={`#modelAccessModal-${model?.key}`} onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' name='team' value={team?.key} ref={register({})} />
          <input type='hidden' name='modelName' value={model?.name} ref={register({})} />
          <input type='hidden' name='modelKey' value={model?.key} ref={register({})} />
          <div className='collection-access-modal__label'>
            <label className='control--label control'>Add Member</label>
          </div>
          <label className='control--label control--required'>First Name</label>
          <input
            ref={register({
              required: 'This field is required',
              validate: value => {
                return (
                  [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
                  'Invalid First Name. Alphanumeric characters expected.'
                );
              },
            })}
            name='first_name'
            className='control control--text'
            placeholder='First Name'
            type='text'
          />
          {errors?.first_name && (
            <div className='error-text'>{errors?.first_name?.message}</div>
          )}
          <label className='control--label control--required'>Email</label>

          <input
            ref={register({
              required: 'This field is required',
              validate: value => {
                return (
                  [/^[\w-.]+(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4}$/].every(pattern =>
                    pattern.test(value)
                  ) || 'Invalid Email.'
                );
              },
            })}
            name='email'
            className='control control--text'
            placeholder='Email'
            type='text'
          />
          {errors?.first_name && (
            <div className='error-text'>{errors?.email?.message}</div>
          )}
          <div className='collection-access-modal__add-btn'>
            <button
              type='submit'
              disabled={!team?.key}
              className={classNames('button-primary button-wider', {
                disabled: !team?.key,
              })}
            >
              Add
            </button>
          </div>

          <div className='collection-access-modal__members'>
            {allUsers &&
              allUsers.map((user, idx) => {
                const {
                  accessLevel,
                  inviteUrl,
                  email,
                  firstName,
                  lastName,
                  profileAsset,
                  key,
                } = user;
                const userAccessModalItemOptions = inviteUrl
                  ? [{ selectItem: `${accessLevel}` }, ...accessModalItemOptions]
                  : [...accessModalItemOptions];

                return (
                  <div className='collection-access-modal__member' key={key}>
                    <div className='collection-access-modal__member-name'>
                      {profileAsset?.progress > 0 ? (
                        <ProfileAssetCircle
                          {...user}
                          imgClassName='collection-access-modal__member-photo'
                        />
                      ) : (
                        <span className='collection-access-modal__member-photo'>
                          {firstName?.slice(0, 1)}
                          {lastName?.slice(0, 1)}
                        </span>
                      )}
                      {firstName} {lastName}
                    </div>
                    <ConditionalWrap
                      condition={inviteUrl}
                      wrap={children => (
                        <CopyToClipboard
                          text={inviteUrl}
                          key={inviteUrl?.split('token')?.[1]}
                        >
                          {children}
                        </CopyToClipboard>
                      )}
                    >
                      <div className='collection-access-modal__member-email'>{email}</div>
                    </ConditionalWrap>
                    <div className='collection-access-modal__member-access'>
                      <button className='button-select'>
                        {inviteUrl ? 'Invited' : 'Member' || 'Legacy'}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ModelAccessModal;

import * as React from 'react';
import './INotificationPopUp.scss';
import { INotification } from 'models/Dashboard/INotification';
import TimeAgo from 'react-time-ago';

export interface INotificationPopUp {
  notifications: INotification[];
  clearNotifications?: () => void;
  clearNotification?: (notification: INotification) => void;
}

export interface INotificationItem {
  notification: INotification;
  clearNotification?: (notification: INotification) => void;
}

const NotificationItem: React.FC<INotificationItem> = props => {
  const { notification, clearNotification } = props;
  const { profileImg, firstName, lastName, action, when } = notification;

  return (
    <div className='notifications__item' onClick={() => clearNotification(notification)}>
      {profileImg ? (
        <img className='notifications__img' src={profileImg} alt='Profile' />
      ) : (
        <span className='notifications__thumbnail'>
          {firstName.slice(0, 1)}
          {lastName.slice(0, 1)}
        </span>
      )}
      <div className='notifications__content'>
        <p className='notifications__action'>{action}</p>
        <p className='notifications__time'>
          <TimeAgo date={when} />
        </p>
      </div>
    </div>
  );
};
const NotificationPopUp: React.FC<INotificationPopUp> = props => {
  const { notifications, clearNotifications, clearNotification } = props;

  return (
    <div className='notifications'>
      <div className='notifications__header'>
        <h2 className='notifications__headline'>notification</h2>
        <button className='notifications__read' onClick={clearNotifications}>
          mark as read
        </button>
      </div>
      <div className='notifications__content'>
        {notifications &&
          notifications.map(item => (
            <NotificationItem
              key={item.key}
              notification={item}
              clearNotification={() => clearNotification(item)}
            />
          ))}
      </div>
    </div>
  );
};

export default NotificationPopUp;

import React, { useContext } from 'react';
import useSWR from 'swr';

import AtelierMaterialsUpload from './AtelierMaterialsUpload';
import { UserContext } from 'providers/UserProvider';
import { mmAPI } from 'services/Api';

const AtelierMaterials: React.FC<{
  brand_model_id: string;
  style_model_id: string;
  view_type: string;
  view_step: string;
  onSetStepComplete: (arg) => void;
  materialsLibrary?: any[];
  materials?: any[];
}> = props => {
  const { brand_model_id, style_model_id } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { data: materials, mutate: mutateMaterials } = useSWR(
    ['api/material/query/style', idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  const { data: materialsLibrary, mutate: mutateMaterialsLibrary } = useSWR(
    ['api/material/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  return (
    <AtelierMaterialsUpload
      materials={materials}
      materialsLibrary={materialsLibrary}
      mutateMaterials={() => {
        mutateMaterials();
        mutateMaterialsLibrary();
      }}
      {...props}
    />
  );
};

export default AtelierMaterials;

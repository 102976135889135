import { UploadContext } from 'providers/UploadProvider';
import React from 'react';
import './Materials.scss';

export type MaterialSummaryMetadata = {
  label: string;
  text: string;
};

export type MaterialModelSummary = {
  name: string;
  assets?: any;
  frontImg: string;
  backImg: string;
  metadata: MaterialSummaryMetadata[];
};

export type IMaterials = {
  materials: MaterialModelSummary[];
  onClickMaterialEditLink?: (material) => void;
  type: string;
};

const AssetProgress = props => {
  const { image, src, asset, onClickMaterialEditLink } = props;
  const { imageUploadProgress, imageUrl } = React.useContext(UploadContext);
  const progress = imageUploadProgress?.[asset?.path];
  const backgroundImage = image || src || imageUrl?.[asset?.path];
  return (
    <>
      <div
        className={
          backgroundImage ? 'materials__card' : 'materials__card add-new-material__no-img'
        }
        style={
          backgroundImage && {
            backgroundImage: `url(${backgroundImage} )`,
          }
        }
      >
        {!backgroundImage && (
          <button
            className='button-dashboard color-white-bd-bg'
            onClick={() => {
              onClickMaterialEditLink?.();
            }}
          >
            Upload{!!progress && `ing ${progress}%`}
          </button>
        )}
      </div>
    </>
  );
};

const Materials: React.FC<IMaterials> = ({
  materials,
  type,
  onClickMaterialEditLink,
}) => (
  <div className='materials'>
    {materials.length === 0 && (
      <div className='product-upload'>
        <div className='product-upload__empty-area'>
          <div className='product-upload__txt'>
            <p className='product-upload__instructions'>{`There is no ${type}`}</p>
            <p className='txt-gray-light'>
              {`Add ${type} from the upload ${type} section`}
            </p>
          </div>
        </div>
      </div>
    )}
    {materials &&
      materials.map((m, idx) => {
        const { name, assets, frontImg, backImg } = m;
        return (
          <div className='materials__item' key={idx}>
            <div className='materials__note'>{name}</div>
            <div className='materials__item-wrap'>
              <div className='materials__front-back'>
                <div className='materials__item-material'>
                  <span>Front</span>
                  <AssetProgress
                    asset={assets?.front}
                    image={frontImg}
                    onClickMaterialEditLink={() => onClickMaterialEditLink(m)}
                  />
                </div>
                <div className='materials__item-material'>
                  <span>Back</span>
                  <AssetProgress
                    asset={assets?.back}
                    image={backImg}
                    onClickMaterialEditLink={() => onClickMaterialEditLink(m)}
                  />
                </div>
              </div>
              <div className='materials__info'>
                {materials[idx].metadata &&
                  materials[idx].metadata.map(({ label, text }, idx) => (
                    <div className='materials__info-line' key={idx}>
                      {label} <span>{text}</span>
                    </div>
                  ))}
              </div>
              <div className='materials__edit'>
                <button
                  className='button-transparent-black mx-5'
                  onClick={() => onClickMaterialEditLink(m)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        );
      })}
  </div>
);

export default Materials;

import React from 'react';
import DownloadIcon from 'assets/icons/Icon-download.svg';
import DragAndDrop from 'components/Upload/DragAndDrop';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import { AtelierGalleryImage } from '../Uploads/AtelierModelUploads/AtelierModelUploads';

export interface IAtelierFileUploader {
  dragTxt?: string;
  fileType?: string;
  emptyStateFileTypes?: string;
  buttonTxt?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChangeHandler?: (files: File[]) => void;
  images?: AtelierGalleryImage[];
}

const AtelierFileUploader: React.FC<IAtelierFileUploader> = props => {
  const {
    dragTxt,
    emptyStateFileTypes,
    buttonTxt,
    multiple,
    disabled,
    onChangeHandler,
    images,
  } = props;

  const noUploadedImagesText = 'No product asset has been uploaded';

  const noUploadedImagesLightText = 'Use upload button or drag and drop media into area';

  const onChange = event => {
    const localFiles: File[] = Array.from(event.currentTarget.files);
    onChangeHandler(localFiles);
  };

  const onDropFile = fileList => {
    onChangeHandler(fileList);
  };
  console.log(emptyStateFileTypes);
  return (
    <DragAndDrop onDropHandler={onDropFile}>
      <div className='product-upload'>
        <div className='product-upload__drag-area'>
          <div className='product-upload__txt'>
            <p className='product-upload__instructions'>
              {images?.length === 0 ? noUploadedImagesText : dragTxt}
            </p>
            <p className='txt-gray-light'>
              {' '}
              {images?.length === 0 ? noUploadedImagesLightText : emptyStateFileTypes}
            </p>
          </div>
          <div className='image-upload'>
            <label htmlFor={'upload'} className='button-small color-white-bd-bg mt-4'>
              {buttonTxt}
            </label>
            <input
              id='upload'
              type='file'
              onChange={onChange}
              multiple={multiple}
              name='upload'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </DragAndDrop>
  );
};

AtelierFileUploader.defaultProps = {
  dragTxt: 'Drag and drop or select files',
  fileType: 'xxxx',
  buttonTxt: 'Upload',
  multiple: false,
};

export default AtelierFileUploader;

import { NotificationsContext } from 'providers/NotificationsProvider';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { PasswordRules } from 'constants/index';

type ResetPasswordModalProps = {
  btnActionHandler?: (values) => void;
  formId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  const { formId, btnActionHandler, open, setOpen } = props;
  const { handleSubmit, register } = useForm();
  const { setDisplayToast } = useContext(NotificationsContext);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validatePassword = event => {
    const password = event.target.value;
    setPassword(password);
    if (PasswordRules.regex.test(password)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
      setMessage('Enter a valid password!');
    }
  };
  const validateConfirmPassword = event => {
    const passwordInput = event.target.value;
    setConfirmPassword(password);
    if (password === passwordInput) {
      setIsValidConfirmPassword(true);
    } else {
      setIsValidConfirmPassword(false);
      setMessage('Confirm password does not match!');
    }
  };

  const onSubmit = values => {
    if (values.new_password === values.confirm_password) {
      btnActionHandler?.(values);
    } else {
      setDisplayToast({
        type: 'error',
        message: 'New password and confirm password not matching!',
      });
    }
  };

  const footer = () => (
    <>
      <button className='button-transparent-black button-smaller'>{'cancel'}</button>
      <button className='button-primary'>{'done'}</button>
    </>
  );

  return (
    <Modal
      modalHeading='Change Password'
      modalBtnClose='cancel'
      modalBtnActionLabel='change'
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId={formId}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div className='control-block'>
          <label className='control--label control--required'>Current Password</label>
          <input
            ref={register}
            name='old_password'
            required
            className='control control--text'
            type='password'
            placeholder='Current password'
          />
        </div>
        <div
          className={
            !isValidPassword
              ? 'control-block control-block--error'
              : password === ''
                ? 'control-block'
                : 'control-block--success'
          }
        >
          <label className='control--label control--required'>New Password</label>
          <input
            ref={register}
            name='new_password'
            onChange={validatePassword}
            required
            className='control control--text'
            type='password'
          />
          {!isValidPassword && (
            <>
              <span className='error-text' role='alert'>
                {message}
              </span>
              <p className='change-password__info'>
                {PasswordRules.reminderText}
              </p>
            </>
          )}
        </div>
        <div
          className={
            !isValidConfirmPassword
              ? 'control-block control-block--error'
              : confirmPassword === ''
                ? 'control-block'
                : 'control-block--success'
          }
        >
          <label className='control--label control--required'>Confirm New Password</label>
          <input
            ref={register}
            onChange={validateConfirmPassword}
            name='confirm_password'
            required
            className='control control--text'
            type='password'
          />
          {!isValidConfirmPassword && (
            <>
              <span className='error-text' role='alert'>
                {message}
              </span>
              <p className='change-password__info'>{'Password doesn’t match'}</p>
            </>
          )}
        </div>
      </form>
    </Modal>
  );
};

import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useLocation, navigate, useParams } from '@reach/router';
import useSWR from 'swr';
import _ from 'lodash';

import './AssetLibraryPage/AssetLibraryPage.scss';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import { CreateModelModal } from 'components/Common/Modal';
import Loader from 'components/Loader/Loader';
import AssetLibraryNav from './AssetLibraryNav/AssetLibraryNav';
import FilePreview from './FilePreview/FilePreview';
import FolderPreview from './FolderPreview/FolderPreview';
import AssetListView from './AssetListView/AssetListView';
import AssetLibraryFilesUpload from './AssetLibraryUpload/AssetLibraryFilesUpload';
import AssetLibraryFolderUpload from './AssetLibraryUpload/AssetLibraryFolderUpload';
import { UserContext, ViewModeContext } from 'providers/contexts';
import { UploadContext } from 'providers/UploadProvider';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { VIEWMODE } from 'providers/ViewModeProvider';
import {
  mmAPI,
  postBucketModel,
  getBucketModelFolder,
  createBucketModelFolder,
  deleteBucketModelFolder,
  deleteBucketModelFile,
  renameBucketModelFile,
} from 'services/Api';
import { ASSET_TYPES } from 'constants/assets';
import PlaceholderImg from 'assets/images/placeholder-image.png';
import IconFolder from 'assets/icons/Icon-folder.svg';

export const INIT_BUCKET_FOLDERS = [
  'Blocks',
  'Carry Forward',
  'Tech Packs',
  'Materials',
  'Graphics',
  'Prints',
  'Branding',
];

const FOLDER = 'folder';
const FILE = 'file';

const parseFolderPath = (folders, path) => {
  if (path.length === 0) {
    return;
  }
  const parentFolder = path.shift();
  let folder = folders.find(f => parentFolder.localeCompare(f.name) === 0);
  if (!folder) {
    folder = {
      name: parentFolder,
      subFolders: [],
    };
    folders.push(folder);
  } else if (!folder.subFolders) {
    folder.subFolders = [];
  }
  parseFolderPath(folder.subFolders, path);
};

export const parsePathFromArray = (path_array, encode = false) =>
  path_array.map(p => '/' + (encode ? encodeURIComponent(p) : p)).join('');

const AssetLibraryContainer = props => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { brand_model_id } = props;
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const base_url = `/brand/${brand_model_id}/assets_library`;
  const folder_url = location.pathname.substring(base_url.length);
  const folder_path_array = folder_url
    .split('/')
    .filter(p => p)
    .map(p => decodeURIComponent(p));
  const folder_path = parsePathFromArray(folder_path_array);

  const [bucketFolders, setBucketFolders] = useState(
    INIT_BUCKET_FOLDERS.map(f => ({ name: f }))
  );
  const [activeFolders, setActiveFolders] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const { setDialogProps, setAppModal, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );
  const { dispatch } = useFrameUI();

  const { viewMode, setViewMode, setViewsList, setShowLabel } = useContext(
    ViewModeContext
  );

  useEffect(() => {
    setViewsList([VIEWMODE.TABLE, VIEWMODE.LARGE]);
    setShowLabel(false); // to show labels, set `true`
    setViewMode(VIEWMODE.LARGE.key);
  }, []);

  const { data: bucket, error, mutate } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log('onerrorretry', error);
        if (error.status === 500) return;
      },
    }
  );

  const currentFolderUrl = useMemo(() => {
    let path = 'assets/bucket';
    if (!bucket?.key) {
      return path;
    }
    path += '/' + bucket?.key + folder_path;
    return path;
  }, [bucket?.key, folder_path]);

  React.useEffect(() => {
    const errorEffect = errorVal => {
      if (!errorVal) return;
      if (errorVal.response?.data?.error?.type === 'BucketNotFound') {
        setDialogProps({
          dialogBody:
            'Brand bucket required. Click continue to create bucket and continue to the Asset Library',
          dialogBtnAction: `Create ${user?.brand.name} Bucket`,
          dialogBtnClose: 'Go Back',
          btnActionHandler: async e => {
            const response = await postBucketModel(idToken, user?.brand?.key, {
              name: `${user?.brand.name} Bucket`,
            });
            if (!response?.data?.error) {
              const {
                data: { data: bucket },
              } = response;
              mutate(bucket);
              setDisplayToast({
                type: 'success',
                persist: true,
                message: `${bucket.name} has been created. Asset Library is now available.`,
              });
            }
          },
        });
      }
    };
    errorEffect(error);
  }, [error, user?.brand]);

  const fetchBucketHandler = useCallback(async () => {
    if (!bucket || !bucket?.key) {
      return;
    }

    const response = await getBucketModelFolder(idToken, bucket.key, { prefix: '/' });
    const { data: blobs } = response.data;
    const folders = INIT_BUCKET_FOLDERS.map(f => ({ name: f }));

    for (const blob of blobs) {
      const path = blob.name.split('/');
      path.shift();
      path.pop();
      parseFolderPath(folders, path);
    }

    setBucketFolders(folders);
  }, [idToken, bucket?.key]);

  useEffect(() => {
    fetchBucketHandler();
  }, [idToken, bucket?.key]);

  useEffect(() => {
    console.log('error', error);
  }, [error]);

  const fetchFolderHandler = useCallback(async () => {
    if (!bucket?.key) {
      return;
    }

    setLoading(true);
    const response = await getBucketModelFolder(idToken, bucket.key, {
      prefix: folder_path,
    });
    const { data: blobs } = response.data;

    const folders = [];
    const files = [];
    for (const blob of blobs) {
      const subPathName = blob.name.substring(folder_path.length + 1);
      if (subPathName === '') {
        continue;
      }
      const subPath = subPathName.split('/');
      if (blob.metadata === null && blob.size === 0) {
        // folder
        if (folders.findIndex(f => subPath[0].localeCompare(f.name) === 0) === -1) {
          const [name] = subPath;
          folders.push({
            name,
            itemData: {
              key: `folder-${name}`,
              name,
              type: FOLDER,
              icon: IconFolder,
              created_at: blob.time_created,
            },
          });
        }
      } else {
        // file
        if (subPath.length !== 1) {
          // skip nested files
          continue;
        }
        if (blob.metadata?.resizedImage === 'true') {
          // filter out resized images
          continue;
        }
        const blobUrl = `/assets/bucket/${bucket.key}${blob.name}`;
        const content_type = blob.content_type.split('/').pop();
        let firebaseUrl = PlaceholderImg;
        if (ASSET_TYPES.image.extensions.includes(content_type)) {
          try {
            firebaseUrl = await firebaseStorageUrl(blobUrl);
          } catch (err) {
            console.log(err);
          }
        }
        const [name] = subPath;
        files.push({
          imageUrl: firebaseUrl,
          name,
          itemData: {
            key: `file-${name}`,
            name,
            type: FILE,
            imageUrl: firebaseUrl,
            created_at: blob.time_created,
          },
        });
      }
    }

    setActiveFolders(folders);
    setActiveFiles(files);
    setLoading(false);
  }, [idToken, bucket?.key, folder_path]);

  useEffect(() => {
    fetchFolderHandler();
  }, [idToken, bucket?.key, folder_path]);

  const createFolderActionHandler = useCallback(
    async values => {
      const response = await createBucketModelFolder(idToken, bucket.key, {
        prefix: folder_path,
        name: values.name,
      });
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket?.key, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const popUpAssetLibrary = useMemo(
    () => [
      {
        selectItem: 'Create folder',
        ModalComponent: (
          <CreateModelModal
            formId='createFolder'
            modelName='Folder'
            modelInputLabel='Name'
            modalHeading='Create'
            btnActionLabel='Create'
            btnActionHandler={createFolderActionHandler}
            closeOnSubmit
          />
        ),
      },
      {
        selectItem: 'Upload files',
        ModalComponent: (
          <AssetLibraryFilesUpload
            path={currentFolderUrl}
            onUploadHandler={fetchFolderHandler}
            onClose={fetchFolderHandler}
          />
        ),
      },
      {
        selectItem: 'Upload folder',
        ModalComponent: (
          <AssetLibraryFolderUpload
            path={currentFolderUrl}
            onUploadHandler={fetchFolderHandler}
            onClose={fetchBucketHandler}
          />
        ),
      },
    ],
    [currentFolderUrl, createFolderActionHandler, fetchFolderHandler, fetchBucketHandler]
  );

  const onDeleteFolder = useCallback(
    async ({ name }) => {
      const response = await deleteBucketModelFolder(idToken, bucket?.key, {
        path: `${folder_path}/${name}/`,
      });
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket?.key, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onRenameFile = useCallback(
    ({ name }) => {
      setAppModal(
        <CreateModelModal
          formId='Rename'
          modalHeading='Rename'
          modelName=''
          btnCloseLabel='CANCEL'
          btnActionLabel='RENAME'
          open={openAppModal}
          setOpen={setOpenAppModal}
          modelInputLabel='NEW NAME'
          btnActionHandler={async values => {
            const response = await renameBucketModelFile(idToken, bucket?.key, {
              path: `${folder_path}/${name}`,
              new_path: `${folder_path}/${values?.name}`,
            });
            fetchFolderHandler();
          }}
          closeOnSubmit={true}
        />
      );
      setOpenAppModal(true);
    },
    [idToken, bucket?.key, folder_path, openAppModal, setOpenAppModal, fetchFolderHandler]
  );

  const onDeleteFile = useCallback(
    async ({ name }) => {
      const response = await deleteBucketModelFile(idToken, bucket?.key, {
        path: `${folder_path}/${name}`,
      });
      fetchFolderHandler();
    },
    [idToken, bucket?.key, folder_path]
  );

  const onRenameItem = useCallback(
    item => {
      const { type } = item;
      if (type === FOLDER) {
        return;
      }
      if (type === FILE) {
        onRenameFile(item);
      }
    },
    [onRenameFile]
  );

  const onDeleteItem = useCallback(
    item => {
      const { type } = item;
      if (type === FOLDER) {
        onDeleteFolder(item);
      }
      if (type === FILE) {
        onDeleteFile(item);
      }
    },
    [onDeleteFolder, onDeleteFile]
  );

  const popUpOptions = useMemo(
    () => [
      {
        selectItem: 'Rename',
        onClick: onRenameItem,
      },
      {
        selectItem: 'Delete',
        onClick: onDeleteItem,
      },
    ],
    [onRenameItem, onDeleteItem]
  );

  useEffect(() => {
    const filterBarEffect = () => {
      // dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: popUpAssetLibrary,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_VIEWMODE_ICON_VISIBLE,
        payload: true,
      });

      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
  }, [popUpAssetLibrary]);

  const onClickFolderNav = path => {
    navigate(base_url + parsePathFromArray(path, true));
  };

  const onClickFolderPreview = name => {
    navigate(base_url + folder_path + '/' + encodeURIComponent(name));
  };

  const onClickFolderItem = item => onClickFolderPreview(item?.name);

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = base_url;
    for (let i = 0; i < folder_path_array.length; i += 1) {
      const p = folder_path_array[i];
      link += `/${p}`;
      const page = {
        title: p,
      };
      if (i < folder_path_array.length - 1) {
        page.link = link;
      }
      pagesArray.push(page);
    }
    return pagesArray;
  }, [folder_path_array]);

  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      {error?.response?.data?.error?.type === 'BucketNotFound' && (
        <ErrorOverlay error={error} />
      )}
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <div className='asset-library-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='asset-library-page__heading'>
                  <div onClick={() => navigate(base_url)}>Asset Library</div>
                </div>
                <AssetLibraryNav
                  navFolders={bucketFolders}
                  activeFolderPath={folder_path_array}
                  onClickFolder={onClickFolderNav}
                />
              </div>
              <div className='col-md-9'>
                <Breadcrumbs pages={pages} />
                {activeFolders.length + activeFiles.length > 0 ? (
                  viewMode === VIEWMODE.LARGE.key ? (
                    <>
                      <div className='asset-library-page__flex'>
                        {activeFolders.map(({ name, itemData }, idx) => (
                          <div className='asset-library-page__flex-item' key={idx}>
                            <FolderPreview
                              name={name}
                              itemData={itemData}
                              popUpOptions={popUpOptions}
                              onClickFolderPreview={onClickFolderPreview}
                            />
                          </div>
                        ))}
                      </div>
                      <div className='asset-library-page__flex'>
                        {activeFiles.map(({ imageUrl, name, itemData }, idx) => (
                          <div className='asset-library-page__flex-item' key={idx}>
                            <FilePreview
                              imageUrl={imageUrl}
                              name={name}
                              itemData={itemData}
                              popUpOptions={popUpOptions}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <AssetListView
                      assets={[...activeFolders, ...activeFiles]}
                      popUpOptions={popUpOptions}
                      onClickRow={onClickFolderItem}
                    />
                  )
                ) : (
                  <div className='asset-library-page__empty'>
                    <div className='asset-library-page__empty-h'>There is no fabric</div>
                    <div className='asset-library-page__empty-p'>
                      Add fabric from the upload fabric section
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ErrorOverlay = () => {
  const { dialogProps } = useContext(ModalsContext);
  const { brand_model_id } = useParams();

  const backHandler = () => {
    navigate(`/brand/${brand_model_id}`);
  };

  return (
    <div className='dialog'>
      <div className='dialog__content'>
        <div className='dialog__inner'>{dialogProps?.dialogBody}</div>
        <div className='dialog__footer'>
          <button className='button-transparent-black' onClick={backHandler}>
            Go Back
          </button>
          <button
            className='button-regular color-warning-bg'
            onClick={dialogProps.btnActionHandler}
          >
            {dialogProps?.dialogBtnAction}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetLibraryContainer;

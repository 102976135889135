import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import classNames from 'classnames';

import './MaterialForm.scss';
import TextBox from 'components/Common/TextBox/TextBox';
import DragAndDrop from 'components/Upload/DragAndDrop';
import PopUp from 'components/Common/PopUp/PopUp';
import { MaterialModel } from 'components/VirtualAtelier/Uploads/MaterialModel';
import { mmAPI, postModel, putModel } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import useMaterialAssets from 'hooks/useMaterialAssets';
import UploadAssetLibrary from './UploadAssetLibrary';

export type SampleMaterialCard = {
  title: string;
  name: string;
  image?: string;
  file?: File;
  fields: IField[];
  asset?: any;
};

export interface IField {
  label: string;
  name: string;
  value?: string;
}

export type MaterialForm = {
  materials?: MaterialModel[];
  materialsLibrary?: MaterialModel[];
  materialType?: string;
  materialSummaries?: any[];
  material?: any;
  brand_model_id?: string;
  style_model_id?: string;
  onCancel?: (e: Event) => void;
  onSave?: (changeView?) => void;
  onUploadHandler?: () => void;
};

const MaterialForm: React.FC<MaterialForm> = props => {
  const {
    materialType,
    material = null,
    brand_model_id,
    style_model_id,
    onCancel,
    onSave,
    materials,
    materialsLibrary,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const { handleFireBaseUpload } = useContext(UploadContext);

  const frontColor = material?.colors.find(c => c.name === 'front');
  const backColor = material?.colors.find(c => c.name === 'back');

  const [sampleMaterialCards, setSampleMaterialCards] = useState<SampleMaterialCard[]>([
    {
      title: 'front',
      name: 'front',
      image: material?.frontImg,
      fields: [
        { name: 'front_hex', label: 'Hex code', value: frontColor?.hexcode },
        { name: 'front_pantone', label: 'Pantone', value: frontColor?.pantone },
      ],
    },
    {
      title: 'back',
      name: 'back',
      image: material?.backImg,
      fields: [
        { name: 'back_hex', label: 'Hex code', value: backColor?.hexcode },
        { name: 'back_pantone', label: 'Pantone', value: backColor?.pantone },
      ],
    },
  ]);

  const [fields, setFields] = useState<IField[]>([
    {
      label: 'Name',
      name: 'name',
      value: material?.name,
    },
    {
      label: 'Use',
      name: 'use',
      value: material?.use,
    },
    {
      label: 'Colorway',
      name: 'colorway',
      value: material?.colorway,
    },
    {
      label: 'Content',
      name: 'notes',
      value: material?.notes,
    },
  ]);

  const { data: bucket, error } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
    }
  );

  const onDropHandler = (name, files) => {
    const [droppedFile] = files;
    const imgSrc = URL.createObjectURL(droppedFile);
    setSampleMaterialCards(prev =>
      prev.map(p => ({
        ...p,
        image: p.name === name ? imgSrc : p.image,
        file: p.name === name ? droppedFile : p.file,
      }))
    );
  };
  const onLibrarySelection = (name, src) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = event => {
      const blob = xhr.response;
      const file = blobToFile(blob, name);
      setSampleMaterialCards(prev =>
        prev.map(p => ({
          ...p,
          image: p.name === name ? src : p.image,
          file: p.name === name ? file : p.file,
        }))
      );
    };
    xhr.open('GET', src);
    xhr.send();
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
  };

  const onMaterialComplete = async values => {
    const formValues = {
      ...values,
      brand: brand_model_id,
      styles: style_model_id,
      type: materialType,
    };
    try {
      const materialResponse = material
        ? await putModel(idToken, 'material', material.key, formValues)
        : await postModel(idToken, 'material', formValues);
      const materialData = materialResponse?.data?.data;

      if (materialData) {
        sampleMaterialCards.forEach(c => {
          if (c.file) {
            handleFireBaseUpload(
              materialData?.assets[c.name].path,
              c.file,
              materialData?.assets[c.name],
              c.name,
              () => onSave?.(false)
            );
          }
        });
        onSave?.();
      } else {
        const materialError = materialResponse?.data?.error;
        errorHandler(materialError);
      }
    } catch (e) {
      errorHandler(e);
    }
  };
  const errorHandler = error => {
    setDisplayToast({
      persist: false,
      type: 'error',
      message:
        'Error with Material API. Please try again or contact support to report details about the error.',
    });
  };
  const onMaterialCancel = e => {
    onCancel?.(e);
  };

  const { handleSubmit, register, errors, control } = useForm();

  const onSubmit = values => {
    values.colors = [];
    if (values.front_hex || values.front_pantone) {
      values.colors.push({
        name: 'front',
        notes: 'front',
        code: values.front_hex,
        hexcode: values.front_hex,
        pantone: values.front_pantone,
      });
    }
    if (values.back_hex || values.back_pantone) {
      values.colors.push({
        name: 'back',
        notes: 'back',
        code: values.back_hex,
        hexcode: values.back_hex,
        pantone: values.back_pantone,
      });
    }
    values.colors = JSON.stringify(values.colors);
    onMaterialComplete(values);
  };

  return (
    <div className='add-new-material'>
      <div className='add-new-material__body'>
        <div className='add-new-material__inner'>
          <form id={`material-new-${material?.key}`} onSubmit={handleSubmit(onSubmit)}>
            <div className='add-new-material__row'>
              <div className='add-new-material__cards'>
                {sampleMaterialCards &&
                  sampleMaterialCards.map(({ title, image, name, asset }, idx) => (
                    <div className='add-new-material__card' key={name}>
                      <div className='add-new-material__card-wrap'>
                        <div className='upload-material__card-header'>{title}</div>
                        <DragAndDrop onDropHandler={files => onDropHandler(name, files)}>
                          <AssetProgress
                            name={name + 'Img'}
                            image={image}
                            asset={asset}
                            bucket={bucket}
                            onChange={e => onDropHandler(name, e.target.files)}
                            onLibrarySelection={src => onLibrarySelection(name, src)}
                            materials={materials}
                            materialsLibrary={materialsLibrary}
                          />
                        </DragAndDrop>
                      </div>
                      {sampleMaterialCards[idx].fields &&
                        sampleMaterialCards[idx].fields.map(({ label, name, value }) => (
                          <TextBox
                            name={name}
                            label={label}
                            value={value}
                            key={name}
                            register={register}
                          />
                        ))}
                    </div>
                  ))}
              </div>
              <div className='add-new-material__fields'>
                {fields &&
                  fields.map(({ label, name, value }, idx) => (
                    <TextBox
                      name={name}
                      label={label}
                      key={name}
                      value={value}
                      register={register}
                    />
                  ))}
              </div>
            </div>

            <div className='add-new-material__actions'>
              <button
                className='button-transparent-black mr-2'
                type='reset'
                onClick={onMaterialCancel}
              >
                Cancel
              </button>
              <button className='button-primary' type='submit'>
                Complete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export type MaterialFormAssetProgress = {
  materials?: MaterialModel[];
  materialsLibrary?: MaterialModel[];
};

const AssetProgress = props => {
  const {
    name,
    image,
    asset,
    bucket,
    onChange,
    materials,
    materialsLibrary,
    onLibrarySelection,
  } = props;
  const { imageUploadProgress } = useContext(UploadContext);
  const progress = imageUploadProgress[asset?.path];

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const { materialLibrarySummaries } = useMaterialAssets({
    materials,
    materialsLibrary,
  });

  const [open, setOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setAppModalProps(prev => ({
      ...prev,
      materialSummaries: [...materialLibrarySummaries],
    }));
  }, [materialLibrarySummaries, setAppModalProps]);

  return (
    <div
      className={classNames('upload-atelier-assets', {
        'add-new-material__img': image,
        'add-new-material__no-img': !image,
      })}
      style={{
        backgroundImage: 'url(' + image + ')',
      }}
    >
      {(!image || asset?.progress < 1) && (
        <>
          <label
            onClick={() => setOpen(!open)}
            className='button-dashboard color-white-bd-bg'
          >
            Upload{!!progress && `ing ${progress}%`}
          </label>
          <PopUp open={open} setOpen={setOpen}>
            <li className='pop-up__li upload-atelier-assets__upload-buttons'>
              <label
                htmlFor={'upload_option_search'}
                onClick={() => {
                  setOpen(false);
                  setUploadModal(true);
                }}
              >
                Search Library
              </label>
            </li>
            <li className='pop-up__li upload-atelier-assets__upload-buttons'>
              <label>
                Upload Assets
                <input
                  type='file'
                  id={name}
                  name={name}
                  onChange={onChange}
                  ref={fileInputRef}
                />
              </label>
            </li>
          </PopUp>
        </>
      )}
      {bucket && (
        <UploadAssetLibrary
          headingText='Asset Library'
          bucket={bucket}
          open={uploadModal}
          setOpen={setUploadModal}
          onSelect={onLibrarySelection}
        />
      )}
    </div>
  );
};

export default MaterialForm;

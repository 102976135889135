import React from 'react';
import './UploadMedia.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import classNames from 'classnames';

export interface IUploadMedia {
  txtInstructions: string;
  fileType: string;
  popUpOptions: IPopUpOptions[];
  fontColor?: string;
  handleUploadButtonClicked?: (e) => void;
  edit?: boolean;
}

const UploadMedia: React.FC<IUploadMedia> = props => {
  const {
    edit,
    fileType,
    txtInstructions,
    popUpOptions,
    handleUploadButtonClicked,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classNames('upload-media', { 'upload-media--overlay': edit })}>
      {edit && (
        <div className='upload-media__block'>
          <div className='upload-media__txt'>
            {txtInstructions}
          </div>
          <div className='upload-media__file'>
            {fileType}
          </div>
          <button
            className='button-small color-white-bd-bg'
            onClick={() => setOpen(!open)}
          >
            select files
          </button>

          <PopUp open={open} setOpen={setOpen}>
            {popUpOptions &&
              popUpOptions.map(({ selectItem }, idx) => (
                <li key={`li_${idx}`} className='pop-up__li upload-media__upload-buttons'>
                  <label htmlFor={'upload_option_' + idx}>{selectItem}</label>
                  <input
                    id={'upload_option_' + idx}
                    name={selectItem}
                    type='file'
                    onChange={e => {
                      handleUploadButtonClicked(e);
                      setOpen(false);
                    }}
                  />
                </li>
              ))}
          </PopUp>
        </div>
      )}
    </div>
  );
};

export default UploadMedia;

import * as React from 'react';
import { getPythonVersion } from 'services/Api';
import LogRocket from 'logrocket';

type useFeatureFlagsModelParams = Record<string, unknown>;

type useFeatureFlagsReturn = {
  pythonVersion: number;
};

const useFeatureFlags = (): useFeatureFlagsReturn => {
  const [pythonVersion, setPythonVersion] = React.useState(null);

  React.useEffect(() => {
    const requestPythonVersion = async () => {
      try {
        const res = await getPythonVersion();
        setPythonVersion(res.data?.data?.version);
      } catch (error) {
        LogRocket.captureException(error);
      }
    };
    requestPythonVersion();
  }, []);

  return {
    pythonVersion,
  };
};

export default useFeatureFlags;

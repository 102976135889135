import React, { LegacyRef } from 'react';

interface Props {
  label: string;
  name: string;
  register?: ({ required, pattern }) => LegacyRef<HTMLInputElement>;
  required?: boolean;
  placeholder?: string;
  errors: [];
  value?: string;
  disabled?: boolean;
  pattern?: string;
  onChange?: (arg0: string) => void;
}

const TextInput: React.FC<Props> = ({
  label,
  name,
  register,
  required,
  placeholder,
  errors,
  value,
  disabled = false,
  pattern,
  onChange,
}) => {
  return (
    <div className='md:flex md:items-center mb-6'>
      <div className='md:w-1/12'>
        <label
          className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
          htmlFor='inline-full-name'
        >
          {label}
        </label>
      </div>
      <div className='md:w-2/3'>
        <input
          className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-black-500'
          name={name}
          ref={
            register
              ? register({
                required,
                pattern,
              })
              : null
          }
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={
            onChange
              ? e => {
                onChange(e.target.value);
              }
              : null
          }
        />
        {errors && <span className='error-text'>This field is required</span>}
      </div>
    </div>
  );
};

export default TextInput;

import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { NotificationsContext, UserContext } from 'providers/contexts';
import CloseIcon from 'assets/icons/close.svg';
import { ResetPasswordModal } from 'components/Common/Modal/ResetPasswordModal';
import { EnableMfaAuthModal } from 'components/Common/Modal/EnableMfaAuthModal';
import { checkPasswordIfPwned } from 'services/Api';
import { errorMessage, hashString, verifyPwned } from 'utils/pwned';

const MFA_STEP = {
  SIGNIN: 'sign_in',
  INFO: 'info_page',
  AUTHENTICATE: 'authenticate',
  PHONE_NUMBER: 'phone_number',
  VERIFICATION_CODE: 'verification_code',
  LIST_FACTORS: 'list_factors',
};

const MFA_MANAGEMENT = {
  ENROLL: 'enroll',
  UNENROLL: 'unenroll',
  AUTHENTICATE: 'authenticate',
};

const EnrollMfa: React.FC = () => {
  const recaptchaRef = React.useRef(null);
  const [isRecaptchaPassed, setIsRecaptchaPassed] = useState(false);
  const [enrolledFactors, setEnrolledFactors] = useState([]);
  const [mfaList, setMfaList] = useState([]);
  const [mfaStep, setMfaStep] = useState(MFA_STEP.INFO);
  const [mfaAction, setMfaAction] = useState(MFA_MANAGEMENT.ENROLL);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [password, setPassword] = useState('');
  const [selectedHint, setSelectedHint] = useState(null);
  const [verificationPhone, setPhoneVerified] = useState('');
  const [resolverSession, setResolverSession] = useState(null);
  const [mfaVerificationId, setMfaVerificationId] = useState(null);
  const { setDisplayToast } = useContext(NotificationsContext);
  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const { account } = user;
  const [userEmail, setUserEmail] = useState(account.email);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [isEnrollMfaModalOpen, setEnrollMfaModalOpen] = useState(false);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    console.log('mfaAction', mfaAction);
    console.log('mfaStep', mfaStep);
  }, [mfaStep, mfaAction]);

  const checkIfPwned = async value => {
    const hashedPassword = hashString(value);
    const checkPwn = await checkPasswordIfPwned({
      hash: hashedPassword.substring(0, 5),
    });
    const found = verifyPwned(checkPwn.data.data, hashedPassword);
    return found;
  };

  const resetPasswordActionHandler = values => {
    const auth = firebase.auth();
    auth
      .signInWithEmailAndPassword(auth.currentUser.email, values.old_password)
      .then(async userCredential => {
        const isPassPwnd = await checkIfPwned(values.new_password);
        if (!isPassPwnd) {
          const user = firebase.auth().currentUser;
          user
            .updatePassword(values.new_password)
            .then(() => {
              setDisplayToast({
                type: 'success',
                message: 'Password changed successfully',
              });
              setOpen(false);
            })
            .catch(error => {
              console.log(error)
              setDisplayToast({ type: 'error', message: error.message });
            });
        } else {
          setDisplayToast({
            type: 'error',
            message: errorMessage
          });
        }
      })
      .catch(error => {
        setDisplayToast({ type: 'error', message: error.message });
      });
  };

  const enrollMfaActionHandler = values => {
    if (values.handler === MFA_STEP.AUTHENTICATE) {
      console.log(values.password);
      authenticate(values.password);
    }
  };

  useEffect(() => {
    try {
      const mfa = firebase.auth().currentUser.multiFactor;
      setEnrolledFactors(mfa.enrolledFactors);
    } catch (error) {
      console.trace('mfa firebase.auth().currentUser', firebase.auth().currentUser);
      setIsUserSignedIn(false);
      setMfaStep(MFA_STEP.SIGNIN);
    }
  }, [setDisplayToast]);

  const cancelMfa = () => {
    const mfa = firebase.auth().currentUser.multiFactor;
    setEnrolledFactors(mfa.enrolledFactors);
    setMfaStep(MFA_STEP.INFO);
    setMfaAction(MFA_MANAGEMENT.ENROLL);
  };

  useEffect(() => {
    const recaptchaRenderEffect = () => {
      try {
        (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          recaptchaRef.current,
          {
            size: 'normal',
            callback: response => {
              setIsRecaptchaPassed(true);
            },
            'expired-callback': () => {
              setIsRecaptchaPassed(false);
            },
          }
        );
        (window as any).recaptchaVerifier.render().then(widgetId => {
          (window as any).recaptchaWidgetId = widgetId;
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (
      mfaStep === MFA_STEP.AUTHENTICATE ||
      mfaStep === MFA_STEP.PHONE_NUMBER ||
      mfaStep === MFA_STEP.LIST_FACTORS
    ) {
      recaptchaRenderEffect();
    }

    return () => {
      if (
        mfaStep === MFA_STEP.AUTHENTICATE ||
        mfaStep === MFA_STEP.PHONE_NUMBER ||
        mfaStep === MFA_STEP.LIST_FACTORS
      ) {
        (window as any).recaptchaVerifier.clear();
      }
    };
  }, [mfaStep]);

  const signInHandler = (email, password) => {
    const auth = firebase.auth();
    auth
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => {
        window.location.reload();
      })
      .catch(error => {
        setDisplayToast({ type: 'error', message: error.message });
      });
  };

  const authenticate = async password => {
    if (!isRecaptchaPassed) {
      await (window as any).recaptchaVerifier.verify();
    }
    console.log((window as any).recaptchaVerifier, '(window as any).recaptchaVerifier');

    const auth = firebase.auth();
    console.log('authenticate auth.currentUser', auth.currentUser);
    console.log('authenticate mfaAction', mfaAction);
    if (mfaAction === MFA_MANAGEMENT.ENROLL) {
      auth.currentUser
        .reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, password)
        )
        .then(userCredential => {
          console.log('MFA_STEP.PHONE_NUMBE', MFA_STEP.PHONE_NUMBER);
          setMfaStep(MFA_STEP.PHONE_NUMBER);
        })
        .catch(async error => {
          if (error.code === 'auth/multi-factor-auth-required') {
            try {
              setResolverSession(error.resolver);
              const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
              const phoneInfoOptions = {
                multiFactorHint: error.resolver.hints[0],
                session: error.resolver.session,
              };

              const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                (window as any).recaptchaVerifier
              );
              setMfaVerificationId(verificationId);
              mfaAction === MFA_MANAGEMENT.UNENROLL
                ? setMfaStep(MFA_STEP.VERIFICATION_CODE)
                : setMfaStep(MFA_STEP.PHONE_NUMBER);
            } catch (error) {
              console.log('Mfa error', error);
            }
          } else {
            setDisplayToast({ type: 'error', message: error.message });
          }
        });
    } else {
      console.log('authenticate mfaAction', mfaAction);
      try {
        const auth = firebase.auth();
        auth.currentUser
          .reauthenticateWithCredential(
            firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, password)
          )
          .then(userCredential => {
            console.log('authenticate authenticate userCredential', userCredential);
            setMfaStep(MFA_STEP.PHONE_NUMBER);
            return Promise.resolve(userCredential);
          })
          .catch(async error => {
            if (error.code === 'auth/multi-factor-auth-required') {
              try {
                setResolverSession(error.resolver);
                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                const phoneInfoOptions = {
                  multiFactorHint: error.resolver.hints[0],
                  session: error.resolver.session,
                };

                const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                  phoneInfoOptions,
                  (window as any).recaptchaVerifier
                );
                setMfaVerificationId(verificationId);

                if (mfaAction === MFA_MANAGEMENT.UNENROLL) {
                  setMfaStep(MFA_STEP.VERIFICATION_CODE);
                } else if (mfaAction === MFA_MANAGEMENT.AUTHENTICATE) {
                  setMfaList(error.resolver.hints);
                  setMfaStep(MFA_STEP.LIST_FACTORS);
                } else {
                  setMfaStep(MFA_STEP.PHONE_NUMBER);
                }
              } catch (error) {
                console.log('err rr', error);
              }
            } else {
              setDisplayToast({ type: 'error', message: error.message });
            }
          });
      } catch (error) {
        console.log('unenroll error------', error);
        setDisplayToast({ type: 'error', message: error.message });
      }
    }
  };
  const sendMfaCode = async phoneNumber => {
    console.log('sendMfaCode');
    if (!isRecaptchaPassed) {
      await (window as any).recaptchaVerifier.verify();
    }
    setPhoneVerified(phoneNumber);
    try {
      const auth = firebase.auth();
      const user = auth.currentUser;
      const session = await user.multiFactor.getSession();
      const phoneOpts = {
        phoneNumber,
        session,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        (window as any).recaptchaVerifier
      );
      setMfaVerificationId(verificationId);
      setMfaStep(MFA_STEP.VERIFICATION_CODE);
    } catch (error) {
      if (error.code === 'auth/unverified-email') {
        const auth = firebase.auth();
        const user = auth.currentUser;
        user.sendEmailVerification();
        setDisplayToast({
          type: 'error',
          message: error.message + ' Please check your account for email verification',
        });
      } else if (error.code === 'auth/requires-recent-login') {
        console.log('error.code', error, error.code);
        setMfaAction(MFA_MANAGEMENT.AUTHENTICATE);
        setMfaStep(MFA_STEP.AUTHENTICATE);
      } else {
        setDisplayToast({
          type: 'error',
          message: error.message,
        });
      }
      return Promise.reject(error);
    }
  };
  const verifyMfaCode = async verificationCode => {
    console.trace('verifyMfaCode');
    if (!isRecaptchaPassed) {
      await (window as any).recaptchaVerifier.verify();
    }

    console.trace('verifyMfaCode', mfaAction);
    if (mfaAction === MFA_MANAGEMENT.ENROLL) {
      try {
        const cred = firebase.auth.PhoneAuthProvider.credential(
          mfaVerificationId,
          verificationCode
        );
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );
        const auth = firebase.auth();
        const user = auth.currentUser;
        await user.multiFactor.enroll(multiFactorAssertion, 'phone number');
        setDisplayToast({ type: 'success', message: 'Enrollment Successful' });
        window.location.reload();
      } catch (error) {
        setDisplayToast({ type: 'error', message: error.message });
      }
    } else {
      try {
        const cred = firebase.auth.PhoneAuthProvider.credential(
          mfaVerificationId,
          verificationCode
        );
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );
        await resolverSession.resolveSignIn(multiFactorAssertion);
        if (mfaAction === MFA_MANAGEMENT.UNENROLL) {
          await firebase.auth().currentUser.multiFactor.unenroll(selectedHint);
          setDisplayToast({
            type: 'success',
            message: ' Successfully Unenrolled Factor',
          });
        }
        window.location.reload();
      } catch (error) {
        setDisplayToast({ type: 'error', message: error.message });
      }
    }
  };

  const addMfa = () => {
    console.trace('addMfa');
    setEnrolledFactors([]);
    setMfaStep(MFA_STEP.AUTHENTICATE);
    setMfaAction(MFA_MANAGEMENT.ENROLL);
  };

  const unEnrollMfa = factor => {
    setSelectedHint(factor);
    setPhoneVerified(factor.phoneNumber);
    setEnrolledFactors([]);
    setMfaStep(MFA_STEP.AUTHENTICATE);
    setMfaAction(MFA_MANAGEMENT.UNENROLL);
  };
  const enableMfa = () => {
    console.log('isUserSignedIn', isUserSignedIn);
    if (isUserSignedIn) {
      setMfaStep(MFA_STEP.AUTHENTICATE);
    } else {
      setMfaStep(MFA_STEP.SIGNIN);
    }
  };
  const choseFactor = async factor => {
    setSelectedHint(factor);
    setPhoneVerified(factor.phoneNumber);
    try {
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const phoneInfoOptions = {
        multiFactorHint: factor,
        session: resolverSession.session,
      };
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        (window as any).recaptchaVerifier
      );
      setMfaVerificationId(verificationId);
      setMfaStep(MFA_STEP.VERIFICATION_CODE);
    } catch (error) {
      setDisplayToast({ type: 'error', message: error.message });
    }
  };

  const renderMfaEnrollment = () => (
    <>
      {mfaStep === MFA_STEP.SIGNIN && (
        <>
          <div className='control-block'>
            <label className='control--label'> Enter Password</label>
            <input
              autoComplete='off'
              name='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              className='control control--full-width control--text'
              type='password'
            />
          </div>
          <button
            className='button-primary col-md-4'
            type='button'
            onClick={e => {
              e.preventDefault();
              signInHandler(userEmail, password);
            }}
          >
            Continue
          </button>
        </>
      )}
      {mfaStep === MFA_STEP.INFO && (
        <>
          <label className='control--label'>PASSWORD</label>
          <p
            style={{
              fontSize: '56px',
              fontWeight: 'bold',
              color: '#999A9A',
              letterSpacing: '10px',
              marginBottom: '30px',
            }}
          >
            ..........
          </p>
          <button
            className='button-primary col-md-4'
            type='button'
            onClick={() => setResetPasswordModalOpen(true)}
          >
            Change Password
          </button>
          <div className='control-block'>
            <label className='control--label'>Two-Factor Authentication</label>
            <p
              style={{
                fontSize: '16px',
                color: '#999A9A',
                fontFamily: 'Nunito',
              }}
            >
              Protect your Modern Mirror Account with an extra layer of security. Once
              configured, you will be required to enter both your password and an
              authentication code from your mobile phone in order to sign in.
            </p>
          </div>
          <button
            className='button-primary col-md-4'
            type='button'
            onClick={e => {
              setEnrollMfaModalOpen(true);
            }}
          >
            Enable Two Factor Auth
          </button>
        </>
      )}
      {mfaStep === MFA_STEP.AUTHENTICATE && (
        <>
          <h5 className='dashboard-forms__heading mm--h5 optima'>
            Enable Two-Factor Auth
          </h5>
          <div className='control-block'>
            <label className='control--label'>Password</label>
            <input
              autoComplete='off'
              name='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              className='control control--full-width control--text'
              type='password'
            />
          </div>
          <div className='flow-root'>
            <button
              className='button-primary col-md-4 float-left'
              type='button'
              onClick={e => {
                e.preventDefault();
                authenticate(password);
              }}
            >
              Continue
            </button>
            <button
              className='button-primary col-md-4 float-right'
              type='button'
              onClick={e => {
                cancelMfa();
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {mfaStep === MFA_STEP.PHONE_NUMBER && (
        <>
          <div className='control-block'>
            <label className='control--label'>Phone Number</label>
            <input
              name='phone-number'
              value={mfaPhoneNumber}
              onChange={e => setMfaPhoneNumber(e.target.value)}
              className='control control--full-width control--text'
              type='text'
            />
          </div>
          <div className='flow-root'>
            <button
              className='button-primary col-md-4'
              type='button'
              onClick={e => {
                e.preventDefault();
                sendMfaCode(mfaPhoneNumber);
              }}
            >
              Add Phone Number
            </button>
            <button
              className='button-primary col-md-4 float-right'
              type='button'
              onClick={e => {
                cancelMfa();
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {mfaStep === MFA_STEP.LIST_FACTORS && (
        <>
          <div className='block'>
            <label className='control--label'>Chose Two-step authentication Factor</label>
            <div className='my-2 ml-1'>
              {mfaList.map(factor => (
                <div key={factor.uid}>
                  <label className='inline-flex items-center'>
                    <input
                      onChange={e => {
                        choseFactor(factor);
                      }}
                      type='radio'
                      name='factorGroup'
                      value={factor.uid}
                    />
                    <span className='ml-2'>{factor.phoneNumber}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {mfaStep === MFA_STEP.VERIFICATION_CODE && (
        <>
          <div className='control-block'>
            <label className='control--label'>
              Enter code sent to {verificationPhone}
            </label>
            <input
              name='verify-code'
              value={mfaCode}
              onChange={e => setMfaCode(e.target.value)}
              className='control control--full-width control--text'
              type='text'
            />
          </div>
          <div className='flow-root'>
            <button
              className='button-primary col-md-4'
              type='button'
              onClick={e => {
                e.preventDefault();
                verifyMfaCode(mfaCode);
              }}
            >
              Verify code
            </button>
            <button
              className='button-primary col-md-4 float-right'
              type='button'
              onClick={e => {
                cancelMfa();
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );

  return (
    <div>
      <div className='dashboard-forms__header'>
        <div className='dashboard-forms__headline'>
          <h2 className='dashboard-forms__heading mm--h3 optima'>
            Password and Authentication
          </h2>
        </div>
      </div>
      {enrolledFactors.length === 0 ? (
        <div className='control-block mb-4'>{renderMfaEnrollment()}</div>
      ) : (
        <>
          <button
            className='button-primary col-md-4'
            type='button'
            onClick={() => setResetPasswordModalOpen(true)}
          >
            Change Password
          </button>
          <div className='control-block mb-4'>
            <label className='control--label control--required'>
              Available Authentication Factors
            </label>
            <div className='order-table'>
              <table className='table table--fs-regular'>
                <thead>
                  <th>Factor</th>
                  <th>Delete</th>
                </thead>
                <tbody>
                  {enrolledFactors.map(factor => (
                    <tr key={factor.uid}>
                      <td key={1}>{factor.phoneNumber}</td>

                      <td>
                        <img
                          src={CloseIcon}
                          alt='Close'
                          onClick={() => unEnrollMfa(factor)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              className='button-primary col-md-4'
              type='button'
              onClick={e => {
                setEnrollMfaModalOpen(true);
              }}
            >
              Enroll New Factor
            </button>
          </div>
        </>
      )}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          formId='resetPassword'
          open={isResetPasswordModalOpen}
          setOpen={setResetPasswordModalOpen}
          btnActionHandler={resetPasswordActionHandler}
        />
      )}
      {isEnrollMfaModalOpen && (
        <EnableMfaAuthModal
          stepTitle={mfaStep}
          mfaSteps={MFA_STEP}
          formId='enrollMfa'
          open={isEnrollMfaModalOpen}
          setOpen={setEnrollMfaModalOpen}
          btnActionHandler={enrollMfaActionHandler}
        />
      )}
      {(mfaStep === MFA_STEP.AUTHENTICATE ||
        mfaStep === MFA_STEP.PHONE_NUMBER ||
        mfaStep === MFA_STEP.LIST_FACTORS) && (
          <div id='recaptcha-container' ref={recaptchaRef} />
        )}
    </div>
  );
};

export default EnrollMfa;


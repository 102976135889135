import React, { useEffect, useState, useContext } from 'react';
import { navigate, useLocation } from '@reach/router';
import useSWR from 'swr';

import 'components/Dashboard/UploadMaterial/UploadMaterial.scss';
import { MaterialModel } from './MaterialModel';
import MaterialForm from 'components/Dashboard/MaterialForm/MaterialForm';
import Materials from 'components/Dashboard/Materials/Materials';
import AtelierLibraryModal from 'components/Dashboard/Collections/CollectionAccessModal/AtelierLibraryModal';
import { UserContext } from 'providers/UserProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { mmAPI, putStyleModel } from 'services/Api';
import useMaterialAssets from 'hooks/useMaterialAssets';
import ArrowLeftIcon from 'assets/icons/Icon-angle-left.svg';
import CheckIcon from 'assets/icons/completed-check.svg';
import CheckedIcon from 'assets/icons/completed-checked.svg';
import ViewIcon from 'assets/icons/view.svg';
import Switch from 'components/Common/Switch/Switch';

export type MaterialCard = {
  title: string;
  image?: string;
  cta: string;
};

type MaterialSwatchInfo = {
  label: string;
  text: string;
};

export type IUploadMaterial = {
  materials: MaterialModel[];
  materialsLibrary: MaterialModel[];
  brand_model_id: string;
  style_model_id: string;
  view_type: string;
  view_step: string;
  onSetStepComplete: (arg) => void;
  mutateMaterials?: () => void;
};

const STEPS = { UPLOAD: 'upload', SUMMARY: 'summary', ADD: 'add', EDIT: 'edit' };

const AtelierMaterialsUpload: React.FC<IUploadMaterial> = props => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const {
    materials,
    materialsLibrary,
    brand_model_id,
    style_model_id,
    view_type,
    view_step,
    onSetStepComplete,
    mutateMaterials,
  } = props;
  const [notApplicableToogle, setNotApplicableToggle] = useState(false);

  const { data: style } = useSWR(
    [`/api/style/model/${style_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const { materialSummaries, materialLibrarySummaries } = useMaterialAssets({
    materials,
    materialsLibrary,
  });

  const [toggleCheck, setToggleCheck] = useState([]);
  useEffect(() => {
    setToggleCheck(style?.tags);
  }, [style]);

  const goBackBtn = e => {
    setView(v => ({
      ...v,
      step: STEPS.UPLOAD,
    }));
  };

  const onUploadHandler = () => {
    mutateMaterials?.();
  };
  const onSave = (changeView = true) => {
    mutateMaterials?.();
    onSetStepComplete({
      [`status_materials`]: true,
    });
    if (changeView) {
      setView(v => ({
        ...v,
        step: STEPS.SUMMARY,
      }));
    }
  };

  const [view, setView] = useState<{
    step: string;
    type: string;
    title: string;
    material?: any;
  }>({
    step: view_step || STEPS.UPLOAD,
    type: view_type,
    title: null,
    material: null,
  });

  useEffect(() => {
    const viewEffect = (view, brand_model_id, style_model_id) => {
      if (view.type) {
        navigate(
          `/brand/${brand_model_id}/style/${style_model_id}/materials/${view.type}/${view.step}`,
          { replace: false }
        );
      }
    };
    viewEffect(view, brand_model_id, style_model_id);
  }, [view, brand_model_id, style_model_id]);

  const { setDisplayToast } = useContext(NotificationsContext);
  const materialAdditionHandler = async (toggleVals, onError) => {
    const tags = toggleVals.join(',');
    const { data: materialStyle } = await putStyleModel(idToken, style_model_id, {
      tags,
    });
    if (materialStyle.error) {
      setDisplayToast({
        persist: false,
        type: 'error',
        message: materialStyle.errror.message,
      });
      onError();
    }
  };

  const [materialCards, setMaterialCards] = useState<MaterialCard[]>([
    {
      title: 'Fabric',
      cta: 'fabric',
    },
    {
      title: 'trim',
      cta: 'trim',
    },
    {
      title: 'hardware',
      cta: 'hardware',
    },
    {
      title: 'branding',
      cta: 'branding',
    },
  ]);

  useEffect(() => {
    const materialSummariesEffect = materialSummariesVal => {
      if (!materialSummariesVal) return;
      setMaterialCards(prev => {
        const next = prev.map(m => {
          const [recentImage] = materialSummariesVal
            .filter(
              s =>
                s.type === m.cta &&
                (s.frontThumbImg || s.backThumbImg || s.frontImg || s.backImg)
            )
            .sort((a, b) => b.modified - a.modified);
          const image =
            recentImage?.frontThumbImg ||
            recentImage?.backThumbImg ||
            recentImage?.frontImg ||
            recentImage?.backImg;
          return {
            ...m,
            image,
          };
        });
        return next;
      });
    };
    materialSummariesEffect(materialSummaries);
  }, [materialSummaries]);

  const location = useLocation();
  useEffect(() => {
    const locationEffect = locationPathname => {
      if (
        locationPathname.indexOf(
          `brand/${brand_model_id}/style/${style_model_id}/materials`
        ) === 0
      ) {
        setView({
          title: 'Upload',
          type: null,
          step: STEPS.UPLOAD,
        });
      }
    };
    locationEffect(location.pathname);
  }, [location.pathname, brand_model_id, style_model_id]);

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  useEffect(() => {
    setAppModalProps(props => ({
      ...props,
      materialSummaries: materialLibrarySummaries,
    }));
  }, [materialLibrarySummaries]);

  return (
    <div className='upload-material'>
      <div className='upload-material__heading'>
        {view.step === STEPS.UPLOAD && <>Material</>}
        {view.step === STEPS.ADD && <>New {view.type}</>}
        {view.step === STEPS.EDIT && <>Edit {view.type}</>}
        {view.step === STEPS.SUMMARY && <>{view.title}</>}
      </div>
      {view.step !== STEPS.UPLOAD && (
        <div className='upload-material__back'>
          <div
            className='upload-material__back-button'
            onClick={e => {
              if (view.step === STEPS.ADD) {
                mutateMaterials?.();
              }
              goBackBtn(e);
            }}
          >
            <img src={ArrowLeftIcon} alt='Back' className='mr-2' />
            Back
          </div>
        </div>
      )}
      <div className='upload-material__body'>
        {(view.step === STEPS.ADD || view.step === STEPS.EDIT) && (
          <MaterialForm
            materialType={view.type}
            material={view.step === STEPS.EDIT ? view.material : null}
            materialSummaries={materialLibrarySummaries}
            {...props}
            onCancel={goBackBtn}
            onSave={onSave}
            onUploadHandler={onUploadHandler}
          />
        )}

        {view.step === STEPS.SUMMARY && (
          <Materials
            type={view.type}
            onClickMaterialEditLink={material =>
              setView(v => ({
                ...v,
                material,
                type: material.type,
                step: STEPS.EDIT,
              }))
            }
            materials={materialSummaries
              .filter(m => m.type === view.type)
              .sort((a, b) => b.modified - a.modified)}
          />
        )}
        {view.step === STEPS.UPLOAD && (
          <div className='upload-material__cards'>
            {materialCards &&
              materialCards.map(({ title, image, cta }) => {
                return (
                  <div className='upload-material__card' key={cta}>
                    <div className='upload-material__card-header'>
                      {title}
                      <div className='upload-material__view-container'>
                        <span>{`View ${title}`}</span>
                        <img
                          onClick={() =>
                            setView(v => ({
                              ...v,
                              title,
                              type: cta,
                              step: STEPS.SUMMARY,
                            }))
                          }
                          src={ViewIcon}
                          alt='View'
                          className='upload-material__view'
                        />
                      </div>
                    </div>
                    <div
                      className={
                        image ? 'upload-material__img' : 'upload-material__no-img'
                      }
                      style={{
                        backgroundSize: 'cover',
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                    <div className={'afs-asset-review__workflow'}>
                      <button
                        disabled={notApplicableToogle}
                        className='upload-material__cta button-dashboard color-white-bd-bg'
                        onClick={() => {
                          const modal = (
                            <AtelierLibraryModal
                              heading='Media Library'
                              open={openAppModal}
                              setOpen={setOpenAppModal}
                            />
                          );
                          setAppModal(modal);
                          setView(v => ({
                            title,
                            type: cta,
                            step: STEPS.ADD,
                          }));
                        }}
                      >
                        Add {cta}
                      </button>
                    </div>

                    <div className='upload-material__txt ml-2'>
                      <img
                        src={toggleCheck.includes(cta) ? CheckedIcon : CheckIcon}
                        onClick={() => {
                          const toggleUpdate = toggleCheck.includes(cta)
                            ? toggleCheck.filter(e => e !== cta)
                            : [...toggleCheck, cta];
                          setToggleCheck(toggleUpdate);
                          materialAdditionHandler(toggleUpdate, () => {
                            setToggleCheck([...toggleCheck]);
                          });
                        }}
                        className='upload-material__check mr-2'
                        alt='Check'
                      />
                      No additional {title}? Click checkmark
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div style={{ marginLeft: '55px', marginBottom: '24px' }}>
        <Switch label={'not applicable'} onSwitchChange={setNotApplicableToggle} />
      </div>
    </div>
  );
};

export default AtelierMaterialsUpload;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import map from 'lodash.map';
import { mmAPI, postInviteModel, postInviteMethodEmail } from 'services/Api';
import { navigate } from '@reach/router';
import { UserContext } from 'providers/contexts';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { TeamModalConfig } from './TeamModalConfig';
import { AddTeamMemberModal } from 'components';
import useTeam from 'hooks/useTeam';
import { ModalsContext } from 'providers/ModalsProvider';
import ModelAccessModal from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModal';
import { ContactsContainer } from './ContactsContainer';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';

const Contacts = ({ brand_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = useContext(NotificationsContext);

  const [openAddTeamMemberModal, setOpenAddTeamMemberModal] = useState(false);

  const { data: lines } = useSWR(
    ['/api/line/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const { data: collections } = useSWR(
    ['/api/collection/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const [lineOptions, setLineOptions] = useState([]);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [modalData] = useState(TeamModalConfig(lineOptions, collectionOptions));

  useEffect(() => {
    const linesEffect = () => {
      if (!lines) return;
      const linesMapped = lines.map(line => {
        const { key, name } = line;
        return {
          name,
          key,
        };
      });
      setLineOptions(linesMapped);
    };
    linesEffect();
  }, [lines]);

  useEffect(() => {
    const collectionsEffect = () => {
      if (!collections) return;
      const collectionsMapped = collections.map(collection => {
        const { key, name } = collection;
        return {
          name,
          key,
        };
      });
      setCollectionOptions(collectionsMapped);
    };
    collectionsEffect();
  }, [collections]);

  const {
    setAppModal,
    setAppModalProps,
    setOpenAppModal,
    openAppModal,
  } = React.useContext(ModalsContext);

  const {
    teamAccountsState,
    teamInvitesState,
    teamModelAssoc,
    setTeamModelAssoc,
  } = useTeam(idToken, {
    brand_model_id,
    matchMultiTeams: true,
  });

  const modal = (
    <ModelAccessModal
      heading={`${user?.brand?.name} Access`}
      open={openAppModal}
      setOpen={setOpenAppModal}
      numOfMembers={teamAccountsState?.length}
      allMembers={teamAccountsState}
      allInvites={teamInvitesState}
      model={user?.brand}
    />
  );

  const triggerModelAccessModal = React.useCallback(
    (model, jobType) => {
      // console.log('setTeamModelAssoc', model);
      setTeamModelAssoc({ model, jobType });
      setAppModalProps(prev => ({
        ...prev,
        model,
      }));
      setAppModal(modal);
      setOpenAppModal(true);
    },
    [teamAccountsState, teamInvitesState]
  );

  React.useEffect(() => {
    setAppModalProps(prev => ({
      ...prev,
      heading: `${teamModelAssoc?.model?.name} Access`,
      numOfMembers: teamAccountsState?.length,
      allMembers: teamAccountsState,
      allInvites: teamInvitesState,
      model: teamModelAssoc?.model,
    }));
  }, [teamAccountsState, teamInvitesState, user?.brand, teamModelAssoc?.model]);

  const popUpOptions = [
    {
      selectItem: 'Add Brand Admin',
      onClick: () => triggerModelAccessModal(user?.brand, 'admin'),
    },
    {
      selectItem: 'Add Line Team Member',
      onClick: () => navigate(`/brand/${brand_model_id}/lines`),
    },
    {
      selectItem: 'Add Collection Team Member',
      onClick: () => navigate(`/brand/${brand_model_id}/collections`),
    },
  ];
  const { dispatch } = useFrameUI();

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: [...popUpOptions],
      });
      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
  }, []);

  const determineGrantType = useCallback(job_type => {
    let grantType = 'read';
    if (job_type === 'admin') grantType = 'owner';
    else if (['designer', 'marketing'].includes(job_type)) grantType = 'editor';
    return grantType;
  }, []);

  const addTeamMemberHandler = useCallback(
    async values => {
      console.log('addTeamMemberHandler values', values);
      const { grants, collections, lines, department, job_type, ...params } = values;
      // role, (site role) admin, buyer, brand, samplemaker etc
      // job_type, admin, sales rep, designer,  etc..
      // grants [{"model": "Collection", "model_id":4644611993632768, "type":"editor"}]
      const brandGrant = [
        {
          model: 'Brand',
          model_id: parseInt(brand_model_id),
          type: determineGrantType(job_type),
        },
      ];
      const linesGrants = map(lines, l => ({
        model: 'Line',
        model_id: l.key,
        type: determineGrantType(job_type),
      }));
      const collectionsGrants = map(collections, c => ({
        model: 'Collection',
        model_id: c.key,
        type: determineGrantType(job_type),
      }));
      const grantsAggregate = JSON.parse(grants).concat(
        brandGrant,
        linesGrants,
        collectionsGrants
      );
      // console.log(grantsAggregate);
      try {
        const { data: invite } = await postInviteModel(idToken, {
          team: user.brand.team,
          role: job_type === 'admin' ? 'admin' : 'user',
          grants: JSON.stringify(grantsAggregate),
          department: department && department[0].value,
          job_type,
          ...params,
        });
        // console.log('addTeamMemberHandler invite', invite);
        if (invite.error) {
          setDisplayToast({ type: 'error', message: invite?.error.message });
        } else if (invite.data.key) {
          setDisplayToast({ type: 'success', message: 'Invite has been created' });
          const { data: inviteData } = await postInviteMethodEmail(idToken, {
            key: invite.data.key,
            path: `${process.env.REACT_APP_PUBLIC_URL}/invite`,
          });
          const { email } = inviteData;
          mutate([`/api/invite/query/brand/`, idToken, brand_model_id]);
          mutate([`/api/account/query/brand/`, idToken, brand_model_id]);

          setDisplayToast({
            type: 'success',
            message: `Invite has been delivered to ${email}.`,
          });
          setOpenAddTeamMemberModal(false);
        } else {
          setDisplayToast({
            type: 'error',
            message: 'Error sending invite. Please try again later.',
          });
        }
      } catch (e) {
        setDisplayToast({ type: 'error', message: e });
      }
    },
    [brand_model_id, idToken, user.brand.team]
  );

  const pages = [
    { title: 'Settings', link: `/settings` },
    { title: 'Manage Team', link: `/team` },
  ];
  return (
    <>
      <div className='TeamView'>
        <Breadcrumbs pages={pages} />
        <div className='TeamTables'>
          <ContactsContainer
            viewMode='MANAGE'
            key={brand_model_id}
            brand_model_id={brand_model_id}
          />

          <AddTeamMemberModal
            modalData={modalData}
            addTeamMemberHandler={addTeamMemberHandler}
            open={openAddTeamMemberModal}
            setOpen={setOpenAddTeamMemberModal}
            brand_model_id={brand_model_id}
          />
        </div>
      </div>
    </>
  );
};
export default Contacts;

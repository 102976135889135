import React, { LegacyRef } from 'react';

export interface ICheckboxes {
  label?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  register?: ({ required }) => LegacyRef<HTMLInputElement>;
  required?: boolean;
  onChange?: (event: any) => void;
}

const Checkboxes: React.FC<ICheckboxes> = props => {
  const { label, name, value, checked = false, register, required, onChange } = props;

  return (
    <div className='Checkboxes'>
      <div className='control-group'>
        <label className='control control--checkbox mt-2'>
          {label}
          <input
            type='checkbox'
            name={name}
            value={value}
            ref={
              register
                ? register({
                    required,
                  })
                : null
            }
            {...(!!onChange && { checked })}
            onChange={onChange}
          />
          <span className='control__indicator'></span>
        </label>
      </div>
    </div>
  );
};

export default Checkboxes;

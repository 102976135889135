import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import './ProductUpload.scss';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import UploadModal from 'components/Dashboard/UploadModal/UploadModal';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { apiMediaMethodBatch } from 'services/Api';
import { NotificationsContext, UserContext } from 'providers/contexts';
import { allowedFileTypes } from 'constants/index';

import InfoIcon from 'assets/icons/Icon-info-circle-full.svg';
import DragAndDrop from 'components/Upload/DragAndDrop';
import LogRocket from 'logrocket';

export interface IProductUpload {
  tooltipTxt: string;
  dragTxt: string;
  fileType: string;
  popUpOptions: IPopUpOptions[];
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeHandler?: (event: React.SyntheticEvent) => void;
  onDoneHandler?: (event: React.SyntheticEvent) => void;
  uploadedMedia?: any;
  pages: IPages[];
  brandModelId?: string;
}

const ProductMediaUpload: React.FC<IProductUpload> = props => {
  const { pages, tooltipTxt, dragTxt, fileType, brandModelId } = props;

  const { setDisplayToast } = useContext(NotificationsContext);
  const { user } = useContext(UserContext);
  const { idToken, brand } = user;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [uploadedMedia, setUploadedMedia] = React.useState([]);

  const onDoneHandler = event => {
    navigate(`/brand/${brandModelId}/products/media/manage`);
  };

  const onDropImagesHandler = files => {
    onMediaFilesHandler(Array.from(files));
  };

  const onMediaSelectedHandler = event => {
    onMediaFilesHandler(Array.from(event.currentTarget.files));
  };
  const onMediaFilesHandler = async (localFiles: File[]) => {
    const validatedLocalFiles = localFiles.filter(file => allowedFileTypes.includes(file.type))
    if (validatedLocalFiles.length > 0) {
      setModalOpen(true);
      const mediaNamesList = validatedLocalFiles.map((file: File) => file.name);
      let mediaBatch = null;
      let batchUploadResponse = null;
      try {
        batchUploadResponse = await apiMediaMethodBatch(idToken, {
          preflight: false,
          names: mediaNamesList.join(','),
          brand: brandModelId,
        });
        mediaBatch = batchUploadResponse.data?.data;
        mediaBatch.forEach(media => {
          const file = validatedLocalFiles.find(l => l.name === media.name);
          media.local = file;
        });
        setUploadedMedia(mediaBatch);
        setModalOpen(true);
      } catch (error) {
        console.log('apiMediaMethodBatch', error);
        if (error?.type === 'UnexpectedError') {
          setDisplayToast({
            type: 'error',
            message:
              'Unexpected API error. If this error persists, please reach out to our Client Services Department by email at: clientsupport@themodernmirror.com',
          });
        } else {
          setDisplayToast({
            type: 'error',
            message:
              'Please ensure all files have supported file extensions PNG, JPG, or GIF. If this error persists, you can reach our to our Client Services Department by email at: clientsupport@themodernmirror.com',
          });
        }
        LogRocket.captureMessage(`Product Media Upload`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'Product Media Upload',
            step: 'onMediaFilesHandler',
          },
          extra: {
            // additional arbitrary data associated with the event
            error,
            mediaBatch,
            batchUploadResponse: batchUploadResponse?.data,
          },
        });
      }
    } else {
      setDisplayToast({
        type: 'error',
        message:
          'Files selected did not meet supported file types. Please select files from the allowed types.',
      });
    }
  };

  const onInputClick = ( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <div className='product-upload'>
      <div className='product-upload__header'>
        <div className='product-upload__heading'>Product Media Upload</div>
        <div className='product-upload__subheading'>
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className='product-upload__controls'>
        <div className='product-upload__select'>
          <span className='product-upload__info'>
            <img src={InfoIcon} alt='Info Icon' />
            File Upload requirement
            {tooltipTxt && (
              <div className='tooltip'>
                <span className='tooltip__txt'>{tooltipTxt}</span>
              </div>
            )}
          </span>
        </div>
      </div>
      <div className='product-upload__drag-area'>
        <div className='product-upload__txt'>
          <p className='product-upload__instructions'>{dragTxt}</p>
          <p className='txt-gray-light'>File types: {fileType}</p>
        </div>
        <DragAndDrop onDropHandler={onDropImagesHandler}>
          <div className='image-upload'>
            <label htmlFor='batchUpload' className='button-small color-white-bd-bg mt-4'>
              Select Files
            </label>
            <input
              id='batchUpload'
              type='file'
              onChange={onMediaSelectedHandler}
              multiple
              name='batchUpload'
              onClick={onInputClick}
            />
          </div>
        </DragAndDrop>
      </div>
      <UploadModal
        itemsAdded={true}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        uploadedMedia={uploadedMedia}
        setUploadedMedia={setUploadedMedia}
        onDoneHandler={onDoneHandler}
      />
    </div>
  );
};

export default ProductMediaUpload;

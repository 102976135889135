import React, { useEffect, useState, useContext, useCallback } from 'react';
import useSWR, { mutate } from 'swr';

import { mmAPI, postTeamModel } from 'services/Api';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModelMember } from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModal';
import capitalize from 'utils/capitalize';

type useTeamModelParams = {
  brand_model_id: string;
  line_model_id?: string;
  collection_model_id?: string;
  style_model_id?: string;
  model?: any;
  matchMultiTeams?: boolean;
  jobType?: JobType;
};
type useSidebarReturn = {
  team: any;
  teams: any[];
  teamInvites: any[];
  teamInvitesState: ModelMember[];
  teamAccountsState: ModelMember[];
  brandAccounts: any[];
  brandTeamsInvites: any[];
  teamModelAssoc: any;
  setTeamModelAssoc: (arg?) => void;
  mutateAll: () => void;
};

type TeamModel = {
  key: number;
  KIND: string;
  name: string;
};

export type JobType = 'designer' | 'vsm' | 'delegate' | 'admin' | 'guest';

type TeamModelAssoc = {
  model: TeamModel;
  jobType?: JobType;
};

const useTeam = (
  idToken: string,
  { brand_model_id, model, jobType, matchMultiTeams }: useTeamModelParams
): useSidebarReturn => {
  const { setDisplayToast } = useContext(NotificationsContext);
  const [teamModelAssoc, setTeamModelAssoc] = useState<TeamModelAssoc>({
    model,
    jobType,
  });
  const [team, setTeam] = useState(null);
  const [teamInvitesState, setTeamInvitesState] = useState(null);
  const [teamAccountsState, setTeamAccountsState] = useState(null);

  const { data: teams } = useSWR(
    brand_model_id ? [`/api/team/query/brand`, idToken, brand_model_id] : null,
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    { suspense: false }
  );

  const { data: teamInvites, mutate: mutateTeamInvites } = useSWR(
    team ? [`/api/invite/query/team`, idToken, team.key] : null,
    (url, idToken, team) => {
      return mmAPI(url, idToken, { team });
    },
    { suspense: false }
  );

  const { data: teamAccounts } = useSWR(
    team ? [`/api/account/query/team`, idToken, team.key] : null,
    (url, idToken, team) => {
      return mmAPI(url, idToken, { team });
    },
    { suspense: false }
  );

  const { data: brandAccounts, mutate: mutateBrandAccounts } = useSWR(
    ['/api/account/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const { data: modelInvites, mutate: mutateModelInvites } = useSWR(
    teamModelAssoc?.model
      ? [
          '/api/invite/query/model',
          idToken,
          teamModelAssoc.model.KIND,
          teamModelAssoc.model.key,
        ]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const { data: brandTeamsInvites, mutate: mutateBrandTeamsInvites } = useSWR(
    ['/api/invite/query/brand_teams', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const permissionTypeFromJobType = jobType => {
    if (jobType === 'admin') return 'owner';
    if (['designer', 'vsm'].includes(jobType)) return 'editor';
    return 'read';
  };

  const findTeamByModelKeyAndJobType = useCallback(
    async (key, jobType) => {
      console.trace('findTeamByModelKeyAndJobType', findTeamByModelKeyAndJobType);
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(g => g.model_id === parseInt(key));
        return matchingGrants?.length && t.account_job_type === jobType;
      });
      console.log('teamMatch', teamMatch);
      if (teamMatch) {
        return teamMatch;
      }
      if (teamModelAssoc.model && jobType) {
        const { KIND: model, key: model_id, name: model_name } = teamModelAssoc.model;
        const permissions = permissionTypeFromJobType(jobType);
        const grants = JSON.stringify([{ model, model_id, type: permissions }]);
        console.log('permission', permissions);
        console.log('grants', jobType, grants);
        const name = `${
          model === 'Brand' ? `${model}` : `${model_name} ${model}`
        } ${capitalize(jobType)} Team`;
        const nameLabel = `${
          model === 'Brand' ? `${model}` : `${model_name} ${model}`
        } Team`;

        setDisplayToast({
          type: 'info',
          message: `Creating team: ${nameLabel}`,
        });
        const {
          data: { data: teamCreated },
        } = await postTeamModel(idToken, {
          name,
          grants,
          account_job_type: jobType,
          model: 'Brand',
          model_id: brand_model_id,
          description: name,
        });
        console.trace('findTeamByModelKeyAndJobType teamCreated', teamCreated);
        return teamCreated;
      }
    },
    [teamModelAssoc, idToken, teams, brand_model_id]
  );

  const findTeamByModelKey = useCallback(
    async key => {
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(g => g.model_id === parseInt(key));
        return matchingGrants?.length;
      });
      if (teamMatch) {
        return teamMatch;
      }
      if (!teamMatch && teamModelAssoc.model) {
        const { KIND: model, key: model_id, name: model_name } = teamModelAssoc.model;
        const grants = JSON.stringify([{ model, model_id, type: 'editor' }]);
        const name = `${model} Team`;
        const {
          data: { data: teamCreated },
        } = await postTeamModel(idToken, {
          name,
          grants,
          model: 'Brand',
          model_id: brand_model_id,
          description: name,
        });
        return teamCreated;
      }
    },
    [teamModelAssoc, idToken, teams, brand_model_id]
  );

  useEffect(() => {
    const sidebarModelEffect = async (teamModelAssocVal, idToken) => {
      if (!teamModelAssocVal) return;
      const { model, jobType } = teamModelAssocVal;
      if (!matchMultiTeams) {
        let teamMatch = null;
        if (model && !jobType) {
          teamMatch = await findTeamByModelKey(model.key);
        } else if (model && jobType) {
          teamMatch = await findTeamByModelKeyAndJobType(model.key, jobType);
        }
        if (teamMatch) {
          mutate([`/api/account/query/team`, idToken, teamMatch.key]);
          mutate([`/api/invite/query/team`, idToken, teamMatch.key]);
          setTeam(teamMatch);
        }
      }
    };
    sidebarModelEffect(teamModelAssoc, idToken);
  }, [teamModelAssoc, matchMultiTeams, teams, idToken]);

  useEffect(() => {
    const teamModelEffect = (
      matchMultiTeamsVal,
      teamsVal,
      teamModelAssocVal,
      teamInvitesVal,
      teamAccountsVal
    ) => {
      const teamInvitesStateTransform = ({
        key,
        created,
        first_name: firstName,
        last_name: lastName,
        token,
        email,
        job_type,
      }) => ({
        key,
        created,
        firstName,
        lastName,
        email,
        inviteUrl: `${process.env.REACT_APP_PUBLIC_URL}/invite/?token=${token}`,
        accessLevel: job_type,
      });
      const teamAccountsStateTransform = ({
        key,
        created,
        first: firstName,
        last: lastName,
        assets,
        email,
        job_type,
      }) => ({
        key,
        created,
        firstName,
        lastName,
        email,
        profileAsset: assets?.profile_picture,
        accessLevel: job_type,
      });
      if (matchMultiTeamsVal && teamsVal && teamModelAssocVal?.model) {
        /**
         * teamModelAssoc state
         *  - filters out teams with grants matching the model association
         *  - filters out accounts and invites from all brand accounts and invites
         */
        const teamModelMatches = teamsVal?.filter(t => {
          const matchingGrants = t.grants.filter(
            g => g.model_id === teamModelAssocVal.model?.key
          );
          return matchingGrants?.length;
        });
        const teamModelMatchKeys = teamModelMatches.map(t => t.key);
        const teamModelAccounts = brandAccounts
          .filter(a => a.teams.some(atk => teamModelMatchKeys.includes(atk)))
          .sort((a, b) => b.created - a.created)
          .map(teamAccountsStateTransform);
        setTeamAccountsState(teamModelAccounts);
        const teamModelInvites = modelInvites
          .filter(
            i =>
              teamModelMatchKeys.includes(i.team) &&
              ['pending', 'invited'].includes(i.status)
          )
          .sort((a, b) => b.created - a.created)
          .map(teamInvitesStateTransform);
        setTeamInvitesState(teamModelInvites);
      } else {
        if (teamInvitesVal) {
          const teamInvitesData = teamInvitesVal
            .filter(i => i.status === 'pending')
            .sort((a, b) => b.created - a.created)
            .map(teamInvitesStateTransform);
          setTeamInvitesState(p => teamInvitesData);
        }
        if (teamAccountsVal) {
          const teamAccountsData = teamAccountsVal
            .sort((a, b) => b.created - a.created)
            .map(teamAccountsStateTransform);
          setTeamAccountsState(p => teamAccountsData);
        }
      }
    };
    teamModelEffect(matchMultiTeams, teams, teamModelAssoc, teamInvites, teamAccounts);
  }, [
    matchMultiTeams,
    teams,
    teamModelAssoc,
    team?.key,
    teamInvites,
    teamAccounts,
    modelInvites,
    brandAccounts,
  ]);

  const mutateAll = () => {
    mutateModelInvites?.();
    mutateBrandAccounts?.();
    mutateTeamInvites?.();
    mutateBrandTeamsInvites?.();
  };

  return {
    team,
    teams,
    teamInvites,
    teamInvitesState,
    teamAccountsState,
    brandAccounts,
    brandTeamsInvites,
    teamModelAssoc,
    setTeamModelAssoc,
    mutateAll,
  };
};

export default useTeam;

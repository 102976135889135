import React from 'react';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import Tooltip from 'components/Common/Tooltip/Tooltip';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import AtelierFileUploader from '../AtelierFileUploader';
import AtelierTraditionalPattern from './AtelierTraditionalPattern';
import './AtelierPattern.scss';
import useSWR from 'swr';
import {
  mmAPI,
  postModel,
  putModel,
  deleteModel,
  postActivityMethod,
} from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import AtelierModelUploadsListView from '../AtelierModelUploads/AtelierModelUploadsListView';
import AtelierAdditionsPreview from '../AtelierModelUploads/AtelierModelUploadsPreview';
import { UploadContext } from 'providers/UploadProvider';
import { ISteps } from 'models/Dashboard/ISteps';
import { useForm } from 'react-hook-form';
import { ASSET_TYPES, getAssetTypeFromFileType } from 'constants/assets';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { AssetMetadataProps } from 'components/Asset';
import Switch from 'components/Common/Switch/Switch';
import HistoryIcon from 'assets/icons/history.svg';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import SlideIcon from 'assets/icons/Icon-slide.svg';
import PlusIcon from 'assets/icons/plus-btn.svg';
import LogRocket from 'logrocket';

// const patternNavItems = ['3D CAD Pattern', 'Traditional Pattern']; // Uncomment to enable traditional pattern.
const patternNavItems = ['3D CAD Pattern']; 


type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Patterns' | 'Graphics' | 'References' | 'Final';
};

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';

const VIEWMODE = {
  SLIDE: 'SLIDE',
  LIST: 'LIST',
};

export type AtelierPattern = {
  key: string;
  name: string;
  type: string;
  original: string;
  thumbnail?: string;
  localFile?: File;
  localUrl?: string;
  save?: boolean;
  // content?: string;
  // progress?: number;
  inProgress?: boolean;
  asset?: [string, AssetMetadataProps];
  created?: number;
  // url?: string;
  onDeleteHandler?: (item: AtelierPattern) => void;
  onRenameHandler?: (item: AtelierPattern) => void;
  onUploadHandler?: () => void;
  onClickHandler?: (item: AtelierPattern) => void;
};

export interface IAtelierPatterns {
  brand_model_id: string;
  style_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
  setSteps?: (arg) => void;
  onSetStepComplete?: (arg) => void;
  onClickContinue?: () => void;
  setOriginal?: (arg) => void;
  currentStep: ISteps;
}

const AtelierPattern: React.FC<IAtelierPatterns> = props => {
  const {
    style_model_id,
    brand_model_id,
    atelierUploadConfig,
    onClickContinue,
    setOriginal,
    setSteps,
    currentStep,
    onSetStepComplete,
  } = props;
  const { user } = React.useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = React.useContext(NotificationsContext);

  const { endpoint, heading } = atelierUploadConfig;

  const [view, setView] = React.useState(STEP_UPLOAD);

  const [viewMode, setViewMode] = React.useState(VIEWMODE.LIST);

  const [notApplicableToogle, setNotApplicableToggle] = React.useState(false);

  const { data: stylePattern } = useSWR(
    [endpoint, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  const [styleUploadModel, setStyleUploadModel] = React.useState(null);
  React.useEffect(() => {
    const styleModelEffect = async (stylePatternVal, brandModelId, styleModelId) => {
      if (stylePatternVal?.length >= 1) {
        setStyleUploadModel(stylePatternVal[0]);
      } else {
        const name = `Pattern - ${user?.style?.name} - ${user?.brand?.name}`;
        const response = await postModel(idToken, 'pattern', {
          brand: brandModelId,
          style: styleModelId,
          name,
          notes: name,
          asset_type: 'asset-uploaded'
        });
        const { data: patternModel } = response.data;
        setStyleUploadModel(patternModel);
      }
    };
    styleModelEffect(stylePattern, brand_model_id, style_model_id);
  }, [stylePattern, user?.brand, user?.style, brand_model_id, style_model_id, idToken]);

  const { data: uploadModelReferences } = useSWR(
    styleUploadModel
      ? [`/api/reference/query/model`, idToken, 'Pattern', styleUploadModel.key]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const [onSave, setOnSave] = React.useState(null);
  React.useEffect(() => {
    const styleUploadModelEffect = styleUploadModelVal => {
      if (!styleUploadModelVal) return;
      setOnSave(() => async values => {
        console.log('values', values, styleUploadModelVal);
        const digitization = values.traditionalPattern === 'in-house-digitization';
        const tags = values.traditionalPattern;
        const { data: patternUpdate } = await putModel(
          idToken,
          'pattern',
          styleUploadModel?.key,
          {
            tags,
            digitization,
          }
        );
        console.log(patternUpdate);
      });
    };
    styleUploadModelEffect(styleUploadModel);
  }, [styleUploadModel]);

  React.useEffect(() => {
    const styleUploadModelEffect = () => {
      setSteps(prevSteps => {
        prevSteps.forEach(
          s =>
            s.url.includes('pattern') &&
            (s.buttonPrimaryHandler = event => {
              console.log('sidenav button handler', event);
              onSave && handleSubmit(onSave)();
            })
        );
        return [...prevSteps];
      });
    };
    styleUploadModelEffect();
  }, [styleUploadModel]);

  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-pattern-downloaded',
      });
      LogRocket.track('reference-pattern-downloaded', {
        reference: props.key,
        user: JSON.stringify(user),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'AtelierPattern: error on activity POST',
          journey: 'reference-pattern-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user),
        },
      });
    }
  };

  const { firebaseStorageUrl } = React.useContext(UploadContext);
  const getFirebaseStorageUrl = async asset => {
    return asset && asset.path ? await firebaseStorageUrl(asset.path) : null;
  };

  const referenceModelGalleryTransform = React.useCallback(async referenceModelVal => {
    try {
      const imgSrc = await getFirebaseStorageUrl(
        referenceModelVal.assets[referenceModelVal.type]
      );
      return Promise.resolve({
        original: imgSrc,
        thumbnail: imgSrc,
        key: referenceModelVal.key,
        name: referenceModelVal.name,
        type: referenceModelVal.type,
        progress: referenceModelVal.assets?.[referenceModelVal.type]?.progress,
        created: referenceModelVal.created,
        onDeleteHandler,
        // onRenameHandler,
        onClickHandler: onClickActivityHandler,
      } as AtelierPattern);
    } catch (e) {
      return null;
    }
  }, []);

  const [referenceModels, setReferenceModels] = React.useState([]);
  const [patternsFromFiles, setPatternsFromFiles] = React.useState([]);
  const patterns = React.useMemo(() => [...patternsFromFiles, ...referenceModels], [
    patternsFromFiles,
    referenceModels,
  ]);

  React.useEffect(() => {
    const uploadModelReferencesEffect = async uploadModelReferencesVal => {
      if (!uploadModelReferencesVal) return;
      const imagesFromReferences = (
        await Promise.all(
          uploadModelReferencesVal.map(t => referenceModelGalleryTransform(t))
        )
      ).filter(r => !!r) as AtelierPattern[];
      setReferenceModels([...imagesFromReferences]);
      setPatternsFromFiles(prev =>
        prev.filter(c => !imagesFromReferences.find(r => r.key == c.key))
      );
      setView(STEP_PREVIEW);
    };
    uploadModelReferencesEffect(uploadModelReferences);
  }, [uploadModelReferences]);

  const fileGalleryTransform = React.useCallback(
    async file => {
      const { type } = getAssetTypeFromFileType(file) || {};
      if (!type) {
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `${file.name} is not a supported file type.`,
        });
        return null;
      }
      const typeSplit = type.split('/');
      const nameSplit = file.name.split('.');

      const response = await postModel(idToken, 'reference', {
        type,
        brand: brand_model_id,
        model: 'Pattern',
        model_id: styleUploadModel.key,
        name: file.name,
        notes: file.type,
        asset_type: 'pattern',
        style: style_model_id
      });
      const patternReferenceData = response?.data?.data;
      console.log('patternReferenceData', patternReferenceData);
      const imgSrc = URL.createObjectURL(file);
      return Promise.resolve({
        key: patternReferenceData.key,
        created: Date.now(),
        name: file.name,
        original: imgSrc,
        thumbnail: imgSrc,
        localFile: file,
        localUrl: imgSrc,
        type,
        save: true,
        asset: [type, patternReferenceData?.assets?.[type]],
        imageAsFileSeed: file,
        onDeleteHandler,
        onUploadHandler: () => {
          console.log('onUploadHandler', file.name);
          onSetStepComplete({
            [`status_patterns`]: true,
          });
        },
        onClickHandler: onClickActivityHandler,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken, brand_model_id, atelierUploadConfig.model, styleUploadModel?.key]
  );

  const onAddNewFiles = async newFiles => {
    const filesArray = Array.from(newFiles);
    const patternsFromFilesResolved = (
      await Promise.all(filesArray.map((file: File) => fileGalleryTransform(file)))
    ).filter(f => !!f);
    if (patternsFromFilesResolved.length > 0) {
      setPatternsFromFiles(prev => [...patternsFromFilesResolved, ...prev]);
      setView(STEP_PREVIEW);
    }
  };

  const [tab, setTab] = React.useState(patternNavItems[0]);

  const uploadPanelConfig = {
    fileType: Object.values(ASSET_TYPES)
      .reduce((extensions, types) => {
        return extensions.concat(types.extensions);
      }, [])
      .join(', '),
    emptyStateFileTypes: 'File types:  DXF AAMA/ASTM and RUL .jpg .pdf .png etc',
    disabled: notApplicableToogle,
    onChangeHandler: onAddNewFiles,
  };

  const onSelectPattern = p => {
    currentStep?.buttonPrimaryHandler(() => {
      console.log('selected pattern', p);
    });
  };

  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);
  const onDeleteHandler = React.useCallback(
    item => {
      setDialogProps({
        dialogBody: `Deleting pattern uploads can’t be undone. Are you sure you want to delete the pattern upload?`,
        btnActionHandler: async () => {
          const { data: referenceDeleted } = await deleteModel(
            idToken,
            'reference',
            item.key
          );
          if (referenceDeleted.error) {
            setDisplayToast({ type: 'error', message: referenceDeleted.error.message });
          } else {
            setReferenceModels(prev => prev.filter(c => c.key !== item.key));
            setPatternsFromFiles(prev => prev.filter(c => c.key !== item.key));
            setDisplayToast({
              type: 'success',
              persist: false,
              message: `Pattern reference has been deleted successfully.`,
            });
          }
        },
      });
      setOpenDialog(true);
    },
    [idToken]
  );

  const { handleSubmit, register } = useForm();

  const tooltipText = `Please upload pattern file DXF AAMA/ASTM and RUL file. 
    Important: when uploading .jpg .pdf .png 
    include SCALE of the image to its original patterns. 
    The grading information needs to be included on the images. Can upload several 
    images of difference sizes, or put all sizes in one image per product. 
    Supported file types: ${uploadPanelConfig.fileType}`;

  const editTileOptions = [
    {
      selectItem: 'Download',
      download: true,
    },
    {
      selectItem: 'Delete',
      onClick: onDeleteHandler,
    },
    {
      selectItem: 'Rename',
      // onClick: renameModelActionHandler,
    },
    {
      selectItem: 'Archive',
      // onClick: onArchiveHandler,
    },
  ];

  return (
    <div className='pattern'>
      <div className='pattern__heading'>
        <div className='pattern__heading-wrapper'>
          {heading}
          <div className='pattern__heading-icons'>
            <img
              className='pattern__history'
              src={HistoryIcon}
              alt='history'
              onClick={() => console.log('History clicked')}
            />
            <Tooltip tooltipText={tooltipText} />
            {viewMode === VIEWMODE.SLIDE ? (
              <img
                className='pattern__viewmode'
                style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={SlideIcon}
                alt='Rail'
                onClick={() => setViewMode(VIEWMODE.LIST)}
              />
            ) : (
              <img
                className='pattern__viewmode'
                style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={ListIconBlack}
                alt='List'
                onClick={() => setViewMode(VIEWMODE.SLIDE)}
              />
            )}
            <img
              src={PlusIcon}
              alt='Plus'
              className='pattern__add'
              style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
              onClick={() => setView(STEP_UPLOAD)}
            />
          </div>
        </div>
      </div>
      <DashboardNav
        dashboardNavItems={patternNavItems.map(item => ({
          title: item,
          activeBtn: item === tab,
        }))}
        darkMode={false}
        changeTab={setTab}
      />
      {patterns.length === 0 || view === STEP_UPLOAD ? (
        <AtelierFileUploader {...uploadPanelConfig} />
      ) : (
        <div className='pattern__body'>
          {viewMode === VIEWMODE.LIST ? (
            <AtelierModelUploadsListView
              media={patterns}
              editTileOptions={editTileOptions}
            />
          ) : (
            <AtelierAdditionsPreview images={patterns} setOriginal={setOriginal} />
          )}
        </div>
      )}


      {/* uncomment below to enable traditional pattern */}

      {/* {tab === patternNavItems[0] ? (
        patterns.length === 0 || view === STEP_UPLOAD ? (
          <AtelierFileUploader {...uploadPanelConfig} />
        ) : (
          <div className='pattern__body'>
            {viewMode === VIEWMODE.LIST ? (
              <AtelierModelUploadsListView
                media={patterns}
                editTileOptions={editTileOptions}
              />
            ) : (
              <AtelierAdditionsPreview images={patterns} setOriginal={setOriginal} />
            )}
          </div>
        )
      ) : (
        <div className='pattern__body'>
          <form id={`patternTraditional`} onSubmit={handleSubmit(onSave)}>
            <AtelierTraditionalPattern
              onSelectPattern={onSelectPattern}
              register={register}
              defaultValue={styleUploadModel?.tags?.[0]}
            />
          </form>
        </div>
      )} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '32px 0 24px 40px',
        }}
      >
        <Switch label={'not applicable'} onSwitchChange={setNotApplicableToggle} />
        {view === STEP_UPLOAD && (
          <div className='pattern__actions'>
            {patterns.length > 0 && (
              <button
                className='button-transparent-black mr-2'
                onClick={() => setView(STEP_PREVIEW)}
              >
                View Uploads
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AtelierPattern;

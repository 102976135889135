import React, { useContext } from 'react';
import truncateText from 'utils/truncateText';
import { Link, useParams } from '@reach/router';
import useMessages from 'hooks/useMessages';
import { UserContext } from 'providers/UserProvider';
import { postTeamModel } from 'services/Api';
import { ReadStatus } from './ReadStatus';

export const ChatContacts = props => {
  const { contacts } = props;
  const { brand_model_id } = useParams();

  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { setMessageModelAssoc } = useMessages(idToken, {
    model: null,
    open: false,
  });

  const onClickChat = async contact => {
    if (contact.team) {
      setMessageModelAssoc(contact.team);
    } else {
      const description = `Contacts Chat: ${user?.account?.name} <> ${contact?.name}`;
      const grants = JSON.stringify([
        { model: 'Account', model_id: contact?.key },
        { model: 'Account', model_id: user?.account?.key },
      ]);
      const {
        data: { data: contactTeamCreated },
      } = await postTeamModel(idToken, {
        name: contact?.name,
        grants,
        model: 'Account',
        model_id: user?.account?.key,
        description,
      });
      setMessageModelAssoc(contactTeamCreated);
    }
  };

  return (
    <>
      <div className='livechat__heading'>
        <Link to={`/brand/${brand_model_id}/contacts`}>Direct Chat</Link>
      </div>
      {contacts &&
        contacts.map(contact => {
          const {
            firstName,
            lastName,
            lastMessage,
            profilePictureUrl,
            onlineUser,
            key,
            readStatus = false,
          } = contact;
          return (
            ( firstName !== undefined && lastMessage !== undefined ) && (
            <div
              className='livechat__item'
              key={key}
              onClick={() => onClickChat(contact)}
            >
              {!profilePictureUrl ? (
                <div className='livechat__avatar'>
                  {firstName?.slice(0, 1)}
                  {lastName?.slice(0, 1)}
                  {onlineUser ? <span className='livechat__avatar-online'></span> : null}
                </div>
              ) : (
                <div className='livechat__avatar'>
                  <img src={profilePictureUrl} alt='Profile' />
                  {onlineUser ? <span className='livechat__avatar-online'></span> : null}
                </div>
              )}
              <div className='livechat__content'>
                <div className='livechat__name'>
                  {firstName} {lastName} <ReadStatus read={readStatus} />
                </div>
                <div className='livechat__msg'>{truncateText(lastMessage, 40)}</div>
              </div>
            </div>
            )
          );
        })}
    </>
  );
};

import React, { useMemo, useContext } from 'react';
import moment from 'moment';

import ProductCSVHistoryList from './ProductCSVHistoryList';
import CSVIcon from 'assets/icons/CSV.svg'

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'File Name',
  },
  {
    header: 'Uploaded Date',
  },
  {
    header: 'Metadata',
  },
];

const ProductCSVHistory = ({ csvHistory, editTileOptions, ...props }) => {

  const cellData = useMemo(
    () =>
      csvHistory?.sort((a, b) => b.created - a.created ).map(csv => {
        return {
          key: csv.key,
          col: [
            {
              imageUrl: CSVIcon,
              linkTo: '',
            },
            {
              cell: csv.filename || 'File Name',
              linkTo: '',
            },
            {
              cell:
                moment.unix(csv.created).format('DD MMM ha') || 'Uploaded Date',
              linkTo: '',
            },
            {
              cell: csv.uploaded_by,
              linkTo: '',
            },
          ],
          data: csv,
        };
      }),
    [csvHistory]
  );

  return (
    <ProductCSVHistoryList
      headerGroups={headerGroups}
      showHeader={false}
      cellData={cellData}
      popUpOptions={editTileOptions}
      {...props}
    />
  );
};

export default ProductCSVHistory;

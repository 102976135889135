import { ModalsContext } from 'providers/ModalsProvider';
import React, { useContext, useEffect, useCallback, useState } from 'react';
import truncateText from 'utils/truncateText';
import { UserContext } from 'providers/contexts';
import GroupChatModal from '../GroupChatModal/GroupChatModal';
import { getBrandContacts, setTeam } from 'services/Api';

export interface IGroupChatContact {
  name?: string;
  key?: number;
  checked: boolean;
  profilePicture?: string
}

export const ChatGroups = props => {
  const { groupChats } = props;
  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const {user} = useContext(UserContext);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [fetchingContacts, setFetchingContacts] = useState(false);


  useEffect(() => {
    async function getContacts() {
      const {data} = await getBrandContacts(user.idToken, user.brand.key);
      const mapped = data.data.map((item) =>{
        return { 
          name: `${item.first} ${item.last}`, 
          profilePicture: item.assets.profile_picture.urls.download, 
          key: item.key, 
          checked: false };
      });
      setContacts(mapped);
    }
    if(fetchingContacts) {
      getContacts();
      setFetchingContacts(false);
    }
  }, [fetchingContacts]);

  const modal = (
    <GroupChatModal
      modalOpen={openAppModal}
      modalHeading={'Create Group'}
      modalBtnClose={'Cancel'}
      modalBtnActionLabel={'Create'}
      backgroundColor={'#fff'}
      setModalOpen={setOpenAppModal}
      contacts={contacts}
      selectionChanged={() => {return}}
      selectedContacts={selectedContacts}
      modalBtnActionHandler={()=> {return}}
      fetchContacts={() => {return}}
    />
  );

  useEffect(() => {
    setAppModalProps(prev => ({
      ...prev,
      modalOpen: openAppModal,
      modalHeading: 'Create Group',
      modalBtnClose: 'Cancel',
      modalBtnActionLabel: 'Create',
      backgroundColor: '#fff',
      contacts: contacts,
      setModalOpen: setOpenAppModal,
      selectionChanged: ({key, checked}) => {
        if(checked){
          const newContacts = [];
          const newSelected = [];
          for(let i = 0; i < contacts.length; i++){
            const contact = contacts[i];
            if(contact.key === key){
              contact.checked = checked;
              newContacts.push(contact);
              newSelected.push(contact);
            } else {
              newContacts.push(contact);
            }
          }
          const selected = [...selectedContacts, ...newSelected];
          const ordered = [];
          contacts.forEach(element => {
            if(selected.some(x => x.key === element.key)) {
              ordered.push(element);
            }
          });
          setContacts(newContacts);
          setSelectedContacts(ordered);
        } else {
          const newContacts = [];
          for(let i = 0;i < contacts.length; i++) {
            const contact = contacts[i];
            if(contact.key === key){
              contact.checked = checked;
              newContacts.push(contact);
            }
            else {
              newContacts.push(contact);
            }
          }
          const filtered = selectedContacts.filter((contact) => contact.key !== key);
          const ordered = [];
          contacts.forEach(element => {
            if(filtered.some(x => x.key === element.key)) {
              ordered.push(element);
            }
          });
          setContacts(newContacts);
          setSelectedContacts(ordered);
        }

      },
      fetchContacts: () => {
        if(contacts.length < 1 && !fetchingContacts) {
          setFetchingContacts(true);
      }
      },
      selectedContacts: selectedContacts,
      modalBtnActionHandler: async (groupName) => {
        const accounts = selectedContacts.map(x => x.key).join(',');
        const {data} = await setTeam(user.idToken, user.brand.key, groupName, accounts);
        setSelectedContacts([]);
        const resetContacts = contacts.map(contact => {
          const ctt = {
            checked: false,
            name: contact.name,
            key: contact.key,
            profilePicture: contact.profilePicture
          };

          return ctt;
        });
        setContacts(resetContacts);
      }
    }));
  }, [openAppModal, contacts, selectedContacts]);

  const triggerGroupChatModal = useCallback(() => {
    setAppModalProps(prev => ({
      ...prev,
    }));
    setAppModal(modal);
    setOpenAppModal(true);
  }, []);

  return (
    <>
      <div className='livechat__heading' onClick={() => triggerGroupChatModal()}>
        Group Chat
      </div>

      {groupChats &&
        groupChats.map(({ groupChatName, groupChatMembers }, idx) => (
          <div className='livechat__item' key={idx}>
            <div className='livechat__avatar'>{groupChatName.slice(0, 2)}</div>
            <div className='livechat__content'>
              <div className='livechat__name'>{groupChatName}</div>
              <div className='livechat__msg'>{truncateText(groupChatMembers, 40)}</div>
            </div>
          </div>
        ))}
    </>
  );
};

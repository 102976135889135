import React, { useEffect, useState, useContext, useCallback } from 'react';
import useSWR from 'swr';

import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { firebaseStorageUrl, UploadContext } from 'providers/UploadProvider';
import { UserContext } from 'providers/UserProvider';
import useAccounts from './useAccounts';
import { mmAPI, postMessageModel } from 'services/Api';
import { extToAssetType } from 'constants/assets';
import { useLocationIsSettings } from './useLocation';

type useMessagesModelParams = {
  brand_model_id?: number;
  style_model_id?: number;
  model: MessageModelAssoc;
  onClickMessageAsset?: (arg?) => void;
  open?: boolean;
  reset?: boolean;
};

type useMessagesReturn = {
  messages: any;
  mutateMessages: () => void;
  setMessageModelAssoc: (arg?) => void;
  onMessagesOpen: (model?) => void;
  postMessage: (message) => Promise<any>;
};

export type MessageModelAssoc = {
  key: number;
  KIND: string;
  name: string;
};

const useMessages = (
  idToken: string,
  { model, onClickMessageAsset, open, reset, brand_model_id }: useMessagesModelParams
): useMessagesReturn => {
  const { state, dispatch } = useFrameUI();
  const { setDisplayToast } = useContext(NotificationsContext);

  const { user } = useContext(UserContext);

  const isSettings = useLocationIsSettings();
  const accountsBrand = brand_model_id || (isSettings && user?.brand?.key);
  const { getContactMatch, getContactMetadata } = useAccounts(idToken, {
    brand: accountsBrand,
  });

  const setMessageModelAssoc = messageModelAssoc => {
    dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
    const contactMatch = getContactMatch(messageModelAssoc);
    const chatTypeProps = { type: null, title: null };
    if (contactMatch) {
      chatTypeProps.type = 'direct';
      chatTypeProps.title = getContactMetadata(contactMatch?.model_id)?.first;
    } else if (messageModelAssoc) {
      chatTypeProps.type = 'model';
      chatTypeProps.title = messageModelAssoc?.name;
    }
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_PROPS,
      payload: {
        ...state.chatbar.props,
        ...chatTypeProps,
        model: messageModelAssoc,
      },
    });
  };

  useEffect(() => {
    if (model) {
      setMessageModelAssoc(model);
    }
  }, [model]);

  useEffect(() => {
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_PROPS,
      payload: {
        ...state.chatbar.props,
        onClickMessageAsset,
      },
    });
  }, [onClickMessageAsset]);

  const { data: messages, mutate: mutateMessages } = useSWR(
    state.chatbar?.props?.model?.key
      ? [idToken, state.chatbar.props.model.KIND, state.chatbar.props.model.key]
      : null,
    (idToken, model, model_id) => {
      if (model === 'Style') {
        return mmAPI('/api/message/query/style', idToken, { style: model_id });
      }
      return mmAPI('/api/message/query/object', idToken, { model, model_id });
    },
    { suspense: false }
  );

  useEffect(() => {
    const messagesEffect = async messagesVal => {
      if (messagesVal) {
        const getMessageMetadata = async m => {
          const {
            text: lastMessage,
            sender: firstName,
            sender_id,
            modified: timestamp,
            assets,
            key,
            attachment_type,
            attachment_name,
          } = m;
          const profilePicture = {
            path: getContactMetadata(sender_id)?.assets?.profile_picture?.path,
            metadata: getContactMetadata(sender_id)?.assets?.profile_picture,
            url: null,
          };
          if (profilePicture?.metadata?.progress > 0)
            try {
              profilePicture.url = await firebaseStorageUrl(profilePicture?.path);
            } catch (err) {
              console.log(err);
            }
          const filePath = assets?.[attachment_type]?.path;
          const assetMetadata =
            assets?.[attachment_type]?.progress > 0
              ? await firebaseStorageMetadata(filePath)
              : null;
          const assetUrl =
            assets?.[attachment_type]?.progress > 0
              ? await firebaseStorageUrl(filePath)
              : null;
          return {
            profilePictureUrl: profilePicture.url,
            timestamp,
            lastMessage,
            firstName,
            assets,
            attachment_type,
            attachment_name,
            assetMetadata,
            assetUrl,
            key,
            senderKey: sender_id,
          };
        };

        const getMessages = messages => {
          return Promise.all(messages.map(m => getMessageMetadata(m)));
        };

        const messages = await getMessages(messagesVal);
        dispatch({
          type: FrameUIActionsTypes.CHATBAR_MESSAGES,
          payload: messages,
        });
      }
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: false });
    };
    messagesEffect(messages);
  }, [messages]);

  const { handleFireBaseUpload, firebaseStorageMetadata } = useContext(UploadContext);

  useEffect(() => {
    const openEffect = openVal => {
      if (openVal) {
        dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
      }
    };
    openEffect(open);
  }, [open]);

  const onMessagesOpen = model => {
    if (model) {
      setMessageModelAssoc(model);
    }
    // dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
    dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
  };

  const postMessage = useCallback(
    async values => {
      setDisplayToast(null);
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
      if (!state.chatbar?.props?.model?.key) {
        return;
      }
      const { message: text, file, fileMetadata, sender } = values;
      const { KIND: model, key: model_id } = state.chatbar.props.model;

      const extPopped = file?.name?.split('.')?.pop();
      const attachment_type = extToAssetType(extPopped) || fileMetadata?.type;
      const attachment_name = attachment_type ? file?.name : null;

      try {
        const { data: messageResponse } = await postMessageModel(idToken, {
          model,
          model_id,
          text,
          attachment_type,
          attachment_name,
        });
        return new Promise((resolve, reject) => {
          if (messageResponse?.error) {
            setDisplayToast({
              type: 'error',
              persist: false,
              message: messageResponse.error.message,
            });
            dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: false });
          } else {
            const messageModel = messageResponse.data;
            if (file && attachment_type) {
              const filePath = messageModel.assets[attachment_type]?.path;
              handleFireBaseUpload(
                filePath,
                file,
                messageModel?.assets[attachment_type],
                [attachment_type],
                () => {
                  mutateMessages();
                  resolve(messages);
                },
                fileMetadata
              );
            } else {
              // mutateMessages([...messages]);
              mutateMessages();
              resolve(messages);
            }
          }
        });
      } catch (error) {
        dispatch({
          type: FrameUIActionsTypes.CHATBAR_LOADING,
          payload: false,
          persist: false,
        });
        return Promise.reject(error);
      }
    },
    [state, state.chatbar?.props]
  );

  return {
    messages,
    mutateMessages,
    setMessageModelAssoc,
    onMessagesOpen,
    postMessage,
  };
};

export default useMessages;

import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import classNames from 'classnames';

import '../../Dashboard/InventoryTable/InventoryTable.scss';

import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { ASSET_TYPES } from 'constants/assets';
import ManageIconGray from 'assets/icons/manage-icon-gray.svg';
import DragDotsIcon from 'assets/icons/drag-dots.svg';

export interface ICellData {
  col: IData[];
  key: any;
  data?: any;
  position?: number;
}

export interface IData {
  cell?: string;
  imageUrl?: string;
  icon?: any;
  button?: string;
  color?: string;
  linkTo?: string;
}

export interface IHeaderGroups {
  header: string;
}

export interface IStyleSampleList {
  headerGroups: IHeaderGroups[];
  showHeader?: boolean;
  cellData: ICellData[];
  popUpOptions: IPopUpOptions[];
  placeholder?: string;
  updatePositionsHandler?: any;
  onClickRow?: (itemKey: any) => void;
}

export interface IStyleSampleListRow {
  headerGroups: IHeaderGroups[];
  rowData: ICellData;
  popUpOptions: IPopUpOptions[];
  onPopUpModal: (itemKey: any, ModalComponent: React.ReactElement) => void;
  handleDrag: any;
  handleDrop: any;
  onClick?: (itemKey: any) => void;
}

const StyleSampleList: React.FC<IStyleSampleList> = props => {
  const {
    headerGroups,
    showHeader = true,
    cellData,
    popUpOptions,
    placeholder = 'No resources available.',
    updatePositionsHandler,
    onClickRow,
  } = props;
  const [selectedItem, setSelectedItem] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);

  const handlePopUpModal = (itemKey, ModalComponent) => {
    setSelectedItem(itemKey);
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const [dragId, setDragId] = useState(null);
  const [rows, setRows] = useState(cellData);

  const handleDrag = ev => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = async ev => {
    const dragRow = rows.find(row => row.key === +dragId);

    const rowsCopy = rows.map(row => row);
    const dragRowIndex = rows.findIndex(row => row.key === +dragId);
    const dropRowIndex = rows.findIndex(row => row.key === +ev.currentTarget.id);

    rowsCopy.splice(dragRowIndex, 1);
    rowsCopy.splice(dropRowIndex, 0, dragRow);

    let str = '';
    const newRowState = rowsCopy.map((row, index) => {
      str = str + `${row.key}:${index + 1}` + `${index < rowsCopy.length - 1 ? ';' : ''}`;
      return row;
    });

    await updatePositionsHandler({ position: str });
    setRows(newRowState);
  };

  useEffect(() => {
    setRows(cellData);
  }, [cellData]);

  return (
    <div>
      <table className='table'>
        {showHeader && (
          <thead>
            <tr>
              {headerGroups?.map(({ header }) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {cellData.length === 0 ? (
            <tr>
              <td>{placeholder}</td>
            </tr>
          ) : (
            rows
              ?.sort((a, b) => a.position - b.position)
              .map(rowData => (
                <StyleSampleListRow
                  key={`row-${rowData.key}`}
                  headerGroups={headerGroups}
                  rowData={rowData}
                  popUpOptions={popUpOptions}
                  onPopUpModal={handlePopUpModal}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  onClick={onClickRow}
                />
              ))
          )}
        </tbody>
      </table>
      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: cellData.find(item => item.key === selectedItem)?.data,
        })}
    </div>
  );
};

const StyleSampleListRow: React.FC<IStyleSampleListRow> = props => {
  const {
    headerGroups,
    rowData,
    popUpOptions,
    onPopUpModal,
    onClick,
    handleDrag,
    handleDrop,
  } = props;
  const [open, setOpen] = useState(false);
  const [iconFile, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    const extension = rowData.data.name.split('.').pop();
    if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
      loadIcon(extension);
    }
    return () => setLoadIcons(false);
  }, [loadIcons, rowData.data.name]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <tr
      id={`${rowData.key}`}
      className='drag-reorder-tr'
      draggable={true}
      onDragOver={ev => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
    >
      {rowData.col?.map(({ linkTo, cell, imageUrl, icon, button, color }, index) => (
        <td
          data-th={headerGroups[index].header}
          key={headerGroups[index].header}
          className={classNames({ link: !!linkTo })}
          onClick={() => linkTo && navigate(linkTo)}
        >
          {(imageUrl || icon) && (
            <div className='drag-reorder-wrapper'>
              <img className='drag-reorder-icon' src={DragDotsIcon} />
              {imageUrl && (
                <img
                  style={{ height: '32px', width: '32px' }}
                  className='table-img'
                  src={iconFile || imageUrl}
                  alt='StyleSample'
                  onClick={() =>
                    rowData.data.type === 'folder' && onClick?.(rowData.data.key)
                  }
                />
              )}
              {icon && (
                <img
                  style={{ height: '32px', width: '32px' }}
                  className='table-img icon-folder'
                  src={icon}
                  alt='StyleSample'
                  onClick={() =>
                    rowData.data.type === 'folder' && onClick?.(rowData.data.key)
                  }
                />
              )}
            </div>
          )}
          {button && (
            <button className={`button-badge color-${color}-bg`}>{button}</button>
          )}
          {cell}
        </td>
      ))}

      <td>
        <img
          className='manage-team__dots'
          onClick={() => setOpen(!open)}
          src={ManageIconGray}
          alt='Manage Icon'
        />

        <PopUp open={open} setOpen={setOpen}>
          {popUpOptions?.map(
            ({ selectItem, to, ModalComponent, onClick, download, disabled = false }) =>
              download ? (
                <li
                  style={disabled ? { pointerEvents: 'none', opacity: '0.5' } : {}}
                  className='pop-up__li'
                  key={selectItem}
                >
                  <a
                    href={rowData.data?.assetUrl || rowData.data?.original}
                    download={rowData.data?.assetUrl || rowData.data?.original}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      setOpen(false);
                      onClick?.(rowData.data);
                    }}
                  >
                    {selectItem}
                  </a>
                </li>
              ) : (
                <li
                  style={disabled ? { pointerEvents: 'none', opacity: '0.5' } : {}}
                  className='pop-up__li'
                  onClick={() => {
                    setOpen(false);
                    if (ModalComponent) {
                      onPopUpModal?.(rowData.key, ModalComponent);
                    } else if (onClick) {
                      onClick(rowData.data);
                    }
                  }}
                  key={selectItem}
                >
                  {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                </li>
              )
          )}
        </PopUp>
      </td>
    </tr>
  );
};

export default StyleSampleList;

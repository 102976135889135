import React, { useState } from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import './FolderPreview.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import FolderIcon from 'assets/icons/Icon-folder.svg';
import DotsIcon from 'assets/icons/dots-grey-horizontal.svg';

export interface IFolderPreview {
  name?: string;
  itemData?: any;
  popUpOptions?: IPopUpOptions[];
  onClickFolderPreview?: (name: string) => void;
}

const FolderPreview: React.FC<IFolderPreview> = props => {
  const { name, itemData, popUpOptions, onClickFolderPreview } = props;
  const [openPopUp, setOpenPopUp] = useState(false);

  return (
    <div className='as-folder-preview'>
      <div
        className={classNames('as-folder-preview__heading', {
          'as-folder-preview__heading-active': openPopUp,
        })}
      >
        <span
          className='as-folder-preview__heading-flex'
          onClick={() => onClickFolderPreview?.(name)}
        >
          <img src={FolderIcon} className='as-folder-preview__folder-icon' alt={name} />
          {name}
        </span>
        <div className='as-folder-preview__dots'>
          <img
            src={DotsIcon}
            alt='Open Pop Up'
            onClick={() => setOpenPopUp(!openPopUp)}
          />
        </div>
      </div>
      <PopUp open={openPopUp} setOpen={setOpenPopUp}>
        {popUpOptions &&
          popUpOptions.map(({ selectItem, to, onClick }) => (
            <li
              className='pop-up__li'
              onClick={() => {
                setOpenPopUp(false);
                if (onClick) {
                  onClick(itemData);
                }
              }}
              key={selectItem}
            >
              {to ? <Link to={to}>{selectItem}</Link> : selectItem}
            </li>
          ))}
      </PopUp>
    </div>
  );
};

export default FolderPreview;

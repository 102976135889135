import React from 'react';
import { Link } from '@reach/router';
import useSWR from 'swr';
import LogRocket from 'logrocket';
import moment from 'moment';

import 'components/Dashboard/UploadSketches/AdditionalReference.scss';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import AtelierFileUploader from '../AtelierFileUploader';
import AtelierAdditionsPreview from './AtelierModelUploadsPreview';
import AtelierModelUploadsListView from './AtelierModelUploadsListView';
import Tooltip from 'components/Common/Tooltip/Tooltip';
import { AssetMetadataProps } from 'components/Asset';
import Switch from 'components/Common/Switch/Switch';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import { CreateModelModal } from 'components/Common/Modal';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { AssetProgress } from '../../StyleSummary/StyleSampleReview';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import {
  mmAPI,
  postModel,
  deleteModel,
  postActivityMethod,
  putModel,
} from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { ASSET_TYPES, getAssetTypeFromFileType } from 'constants/assets';
import HistoryIcon from 'assets/icons/history.svg';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import SlideIcon from 'assets/icons/Icon-slide.svg';
import PlusIcon from 'assets/icons/plus-btn.svg';

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Graphics' | 'References' | 'Final';
};

export interface AtelierGalleryImage {
  key: string;
  name: string;
  type: string;
  original?: string;
  thumbnail?: string;
  localFile?: File;
  localUrl?: string;
  save?: boolean;
  asset?: [string, AssetMetadataProps];
  inProgress?: boolean;
  created?: number;
  progres?: number;
  imageAsFileSeed?: any;
  path?: number[];
  pathname?: string;
  onDeleteHandler?: (item: AtelierGalleryImage) => void;
  onRenameHandler?: (item: AtelierGalleryImage) => void;
  onUploadHandler?: () => void;
  onClickHandler?: (item: AtelierGalleryImage) => void;
}

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';

const VIEWMODE = {
  SLIDE: 'SLIDE',
  LIST: 'LIST',
};

export interface IAtelierAdditions {
  brand_model_id: string;
  style_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
  setSteps?: (arg) => void;
  onSetStepComplete?: (arg) => void;
  onClickContinue?: () => void;
  setOriginal?: (arg) => void;
}

const AtelierAdditions: React.FC<IAtelierAdditions> = props => {
  const {
    brand_model_id,
    style_model_id,
    atelierUploadConfig,
    setSteps,
    onSetStepComplete,
    onClickContinue,
    setOriginal,
  } = props;

  const { user } = React.useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = React.useContext(NotificationsContext);

  const { endpoint, heading } = atelierUploadConfig;

  const [step, setStep] = React.useState(STEP_UPLOAD);

  const [viewMode, setViewMode] = React.useState(VIEWMODE.SLIDE);
  const [notApplicableToogle, setNotApplicableToggle] = React.useState(false);

  const { data: styleUploadModels } = useSWR(
    [endpoint, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );
  const graphicNavItems = ['Graphic', 'Print'];
  const [tab, setTab] = React.useState(graphicNavItems[0]);

  const [styleUploadModel, setStyleUploadModel] = React.useState(null);
  React.useEffect(() => {
    const styleUploadModelsEffect = async () => {
      if (['Techpack', 'Graphics'].includes(atelierUploadConfig.model)) {
        const model = atelierUploadConfig.model.toLowerCase();
        if (styleUploadModels?.length >= 1) {
          setStyleUploadModel(styleUploadModels[0]);
        } else if (!styleUploadModel) {
          const name = `${atelierUploadConfig.model} - ${user?.style?.name} - ${user?.brand?.name}`;
          const response = await postModel(idToken, model, {
            brand: brand_model_id,
            style: style_model_id,
            name,
            notes: name,
          });
          const { data: modelData } = response.data;
          setStyleUploadModel(modelData);
        }
      } else if (user?.style) {
        setStyleUploadModel(user?.style);
      }
    };
    styleUploadModelsEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.style,
    styleUploadModel,
    styleUploadModels,
    brand_model_id,
    style_model_id,
    user.brand,
    atelierUploadConfig.model,
    idToken,
  ]);

  const { data: uploadModelReferences, mutate: mutateUploadModelReferences } = useSWR(
    styleUploadModel
      ? [
          `/api/reference/query/model`,
          idToken,
          ['Techpack', 'Graphics'].includes(atelierUploadConfig.model)
            ? atelierUploadConfig.model
            : 'Style',
          styleUploadModel.key,
        ]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const [referenceModels, setReferenceModels] = React.useState<AtelierGalleryImage[]>([]);
  const [imagesFromFiles, setImagesFromFiles] = React.useState<AtelierGalleryImage[]>([]);
  const [imagesFromFilesUploading, setImagesFromFilesUploading] = React.useState<
    AtelierGalleryImage[]
  >([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [refId, setRefId] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [, setModalOpen] = React.useState(false);
  const [, setActiveModalComponent] = React.useState(null);

  React.useEffect(() => {
    const uploadModelReferencesEffect = async () => {
      if (uploadModelReferences) {
        let filteredReferences;
        if (atelierUploadConfig.model === 'Final') {
          filteredReferences = uploadModelReferences.filter(item =>
            item.tags.includes('final')
          );
        } else {
          filteredReferences = uploadModelReferences.filter(
            item => item.tags.length == 0
          );
        }
        const imagesFromReferences = referenceModels.filter(r =>
          filteredReferences.some(u => u.key === r.key)
        );
        const newUploadModelReferences = filteredReferences.filter(
          r => !imagesFromReferences.some(u => u.key === r.key)
        );
        if (newUploadModelReferences.length === 0) return;
        const imagesFromUploadModelReferences = (
          await Promise.all(
            newUploadModelReferences.map(t => referenceModelGalleryTransform(t))
          )
        ).filter(r => !!r) as AtelierGalleryImage[];
        const newReferenceModels = [
          ...imagesFromReferences,
          ...imagesFromUploadModelReferences,
        ] as AtelierGalleryImage[];
        newReferenceModels.sort((a, b) => b.created - a.created);
        setReferenceModels(newReferenceModels);
        setImagesFromFiles(prev =>
          prev.filter(c => !newReferenceModels.some(r => r.key === c.key))
        );
        setImagesFromFilesUploading([]);

        setStep(STEP_PREVIEW);
      }
    };
    uploadModelReferencesEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadModelReferences]);

  const { firebaseStorageUrl } = React.useContext(UploadContext);
  const getFirebaseStorageUrl = async asset => {
    return asset && asset.path ? await firebaseStorageUrl(asset.path) : null;
  };

  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);
  const onDeleteHandler = React.useCallback(
    item => {
      setDialogProps({
        dialogBody: `Deleting assets can’t be undone. Are you sure you want to delete?`,
        btnActionHandler: async () => {
          const { data: referenceDeleted } = await deleteModel(
            idToken,
            'reference',
            item.key
          );
          if (referenceDeleted.error) {
            setDisplayToast({ type: 'error', message: referenceDeleted.error.message });
          } else {
            setReferenceModels(prev => prev.filter(c => c.key !== item.key));
            setImagesFromFiles(prev => prev.filter(c => c.key !== item.key));

            setDisplayToast({
              type: 'success',
              persist: false,
              message: `Asset has been deleted successfully.`,
            });
          }
        },
      });
      setOpenDialog(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken]
  );

  const onRenameHandler = React.useCallback(
    item => {
      console.log(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onArchiveHandler = React.useCallback(
    async item => {
      try {
        await putModel(idToken, 'reference', item.key, {
          archived: true,
          brand: brand_model_id,
        });
        setReferenceModels(prev => prev.filter(c => c.key !== item.key));
        setImagesFromFiles(prev => prev.filter(c => c.key !== item.key));
        setDisplayToast({
          type: 'success',
          persist: false,
          message: `Asset has been archived successfully.`,
        });
      } catch (error) {
        setDisplayToast({
          type: 'success',
          persist: false,
          message: `Error occured while archiving asset.${error}`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-downloaded',
        asset_type: atelierUploadConfig?.model
      });
      LogRocket.track('reference-downloaded', {
        reference: props.key,
        user: JSON.stringify(user),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'AtelierModelUploads: error on activity POST',
          journey: 'reference-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user),
        },
      });
    }
  };

  const referenceModelGalleryTransform = React.useCallback(
    async referenceModelVal => {
      const referenceTransformed = {
        key: referenceModelVal.key,
        type: referenceModelVal.type,
        name: referenceModelVal.name,
        created: referenceModelVal.created,
        path: referenceModelVal.path,
        onDeleteHandler,
        onRenameHandler,
        onClickHandler: onClickActivityHandler,
      } as AtelierGalleryImage;
      try {
        if (referenceModelVal.type === 'folder') {
          referenceTransformed.inProgress = false;
        } else {
          const imgSrc = await getFirebaseStorageUrl(
            referenceModelVal.assets[referenceModelVal.type]
          );
          referenceTransformed.original = referenceTransformed.thumbnail = imgSrc;
          referenceTransformed.inProgress =
            referenceModelVal.assets[referenceModelVal.type].progress === 0;
        }
        return Promise.resolve(referenceTransformed);
      } catch (e) {
        return null;
      }
    },
    [currentPath]
  );

  const fileGalleryTransform = React.useCallback(
    async file => {
      // lookup type from file
      const { type } = getAssetTypeFromFileType(file) || {};
      if (!type) {
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `${file.name} is not a supported file type.`,
        });
        return null;
      }

      const response = await postModel(idToken, 'reference', {
        type,
        brand: brand_model_id,
        model: ['Techpack', 'Graphics'].includes(atelierUploadConfig.model)
          ? atelierUploadConfig.model
          : 'Style',
        model_id: styleUploadModel.key,
        style:props.style_model_id,
        name: file.name,
        tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
        path: currentPath.length > 0 ? currentPath.join(',') : null,
      });
      const graphicReferenceData = response?.data?.data;
      const imgSrc = URL.createObjectURL(file);
      return Promise.resolve({
        type,
        name: file.name,
        original: imgSrc,
        thumbnail: imgSrc,
        localFile: file,
        localUrl: imgSrc,
        save: true,
        asset: [type, graphicReferenceData?.assets[type]],
        progress: -1,
        imageAsFileSeed: file,
        key: graphicReferenceData.key,
        path: currentPath,
        created: moment().unix(),
        onDeleteHandler,
        onUploadHandler: () => {
          setSteps(prev => {
            return prev.map(p =>
              p.slug === atelierUploadConfig.model.toLowerCase() && !p.completed
                ? { ...p, completed: true }
                : { ...p }
            );
          });
          onSetStepComplete({
            [`status_${atelierUploadConfig.model.toLowerCase()}`]: true,
          });
          mutateUploadModelReferences();
        },
        onClickHandler: onClickActivityHandler,
      } as AtelierGalleryImage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idToken,
      brand_model_id,
      atelierUploadConfig.model,
      styleUploadModel?.key,
      currentPath,
    ]
  );

  const onAddNewFiles = async newFiles => {
    const filesArray = Array.from(newFiles);
    const imagesFromFilesResolved = (
      await Promise.all(filesArray.map((file: File) => fileGalleryTransform(file)))
    ).filter(f => !!f);
    if (imagesFromFilesResolved.length > 0) {
      setImagesFromFiles(prev => [...imagesFromFilesResolved, ...prev]);
      setImagesFromFilesUploading(imagesFromFilesResolved);
      setStep(STEP_PREVIEW);
    }
  };

  const uploadPanelConfig = {
    fileType: Object.values(ASSET_TYPES)
      .reduce((extensions, types) => {
        return extensions.concat(types.extensions);
      }, [])
      .join(', '),
    multiple: true,
    disabled: notApplicableToogle,
    onChangeHandler: onAddNewFiles,
    emptyStateFileTypes: 'File types:  DXF AAMA/ASTM and RUL .jpg .pdf .png etc',
  };

  const images = [...imagesFromFiles, ...referenceModels];

  const deleteModelActionHandler = data => {
    onDeleteHandler(data);
  };

  const renameModelActionHandler = data => {
    setOpenModal(true);
    setRefId(data.key);
    // onRenameHandler(data);
  };

  const createModelActionHandler = async values => {
    const { name } = values;
    const { data: referenceUpdate } = await putModel(idToken, 'reference', refId, {
      name,
      brand: brand_model_id,
    });
    if (referenceUpdate.data?.error) {
      setDisplayToast({ type: 'error', message: referenceUpdate.data?.error?.message });
    } else {
      setDisplayToast({ type: 'success', message: `Reference name updated` });
      setReferenceModels(prev => [...prev.filter(r => r.key !== refId)]);
      mutateUploadModelReferences();
    }
  };

  const editTileOptions = [
    {
      selectItem: 'Download',
      download: true,
    },
    {
      selectItem: 'Delete',
      onClick: deleteModelActionHandler,
    },
    {
      selectItem: 'Rename',
      onClick: renameModelActionHandler,
    },
    {
      selectItem: 'Archive',
      onClick: onArchiveHandler,
    },
  ];

  const rendertooltipTitle = (param: string) => {
    switch (param) {
      case 'Techpack & Specs':
        return `Please upload tech pack & specs files we support most file types
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Graphic & Prints':
        return `Upload graphic asset in coresponding section. Upload additonal references that didn’t include in other section
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Additional References':
        return `Upload additonal references that didn’t include in other section 
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Final Files':
        return `Once a Product has been Approved, collate all of the up to date and approved files associated with the Product and upload here.
        For example, the 3D file, any approved Branding, Graphics, Prints, Trims and hardware all can be uploaded at once for access at any time in the lifecycle of the Product. 
        Only upload approved files. 
        Supported file types: ${uploadPanelConfig.fileType}`;
      default:
        return '';
    }
  };

  const onCreateFolder = async () => {
    await postModel(idToken, 'reference', {
      type: 'folder',
      brand: brand_model_id,
      model: ['Techpack', 'Graphics'].includes(atelierUploadConfig.model)
        ? atelierUploadConfig.model
        : 'Style',
      model_id: styleUploadModel.key,
      name: 'untitled folder',
      tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
      path: currentPath.length > 0 ? currentPath.join(',') : null,
      pathname: '/' + currentPathname.join('/'),
    });
    mutateUploadModelReferences();
  };

  const onClickFolder = reference_key => {
    setCurrentPath(prev => [...prev, reference_key]);
  };

  const pages = React.useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < currentPath.length; i += 1) {
      const key = currentPath[i];
      const reference = images.find(r => +r.key === +key);
      link += `/${reference.name}`;
      const page: IPages = {
        title: reference.name,
      };
      if (i < currentPath.length - 1) {
        page.link = link;
        page.onClick = () => setCurrentPath(currentPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (currentPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setCurrentPath(currentPath.slice(0, currentPath.length - 1)),
      });
    }
    return pagesArray;
  }, [currentPath, images]);

  const currentPathname = currentPath.map(key => images.find(r => +r.key === +key).name);

  const filteredImages = images.filter(r => {
    const rPath = r.path ?? [];
    const len = Math.max(rPath.length, currentPath.length);
    for (let i = 0; i < len; i++) {
      if (rPath[i] != currentPath[i]) {
        return false;
      }
    }
    return true;
  });

  const popUpOptions = [
    {
      selectItem: 'Add new',
      onClick: () => {
        setStep(STEP_UPLOAD);
      },
    },
    {
      selectItem: 'Create folder',
      onClick: () => {
        onCreateFolder();
      },
    },
  ] as IPopUpOptions[];

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  return (
    <div className='additional-reference'>
      <div className='additional-reference__heading'>
        <div className='additional-reference__heading-wrapper'>
          {heading}
          <div className='additional-reference__heading-icons'>
            <img
              className='additional-reference__history'
              src={HistoryIcon}
              alt='history'
              onClick={() => console.log('History clicked')}
            />
            <Tooltip tooltipText={rendertooltipTitle(heading)} />
            {viewMode === VIEWMODE.SLIDE ? (
              <img
                className='additional-reference__viewmode'
                style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={SlideIcon}
                alt='Rail'
                onClick={() => setViewMode(VIEWMODE.LIST)}
              />
            ) : (
              <img
                className='additional-reference__viewmode'
                style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={ListIconBlack}
                alt='List'
                onClick={() => setViewMode(VIEWMODE.SLIDE)}
              />
            )}
            <img
              src={PlusIcon}
              alt='Plus'
              className='additional-reference__add'
              style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
              onClick={() => setOpen(!open)}
            />
            <PopUp open={open} setOpen={setOpen}>
              {popUpOptions &&
                popUpOptions.map(({ selectItem, to, onClick, ModalComponent }) => (
                  <li
                    className='pop-up__li'
                    onClick={() => {
                      setOpen(false);
                      if (ModalComponent) {
                        handlePopUpModal(ModalComponent);
                      } else if (onClick) {
                        onClick();
                      }
                    }}
                    key={selectItem}
                  >
                    {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                  </li>
                ))}
            </PopUp>
          </div>
        </div>
      </div>
      {heading === 'Graphic & Prints' && (
        <DashboardNav
          dashboardNavItems={graphicNavItems.map(item => ({
            title: item,
            activeBtn: item === tab,
          }))}
          darkMode={false}
          changeTab={setTab}
        />
      )}
      <div className='additional-reference__folder-path'>
        <Breadcrumbs pages={pages} />
      </div>
      {step === STEP_UPLOAD ? (
        <AtelierFileUploader images={filteredImages} {...uploadPanelConfig} />
      ) : (
        <>
          <div className='additional-reference__body'>
            {viewMode === VIEWMODE.SLIDE ? (
              <AtelierAdditionsPreview
                images={filteredImages.filter(s => s.type !== 'folder')}
                setOriginal={setOriginal}
              />
            ) : (
              <>
                {imagesFromFilesUploading?.map(upload => (
                  <AssetProgress key={upload.key} {...upload} />
                ))}
                <AtelierModelUploadsListView
                  media={filteredImages}
                  editTileOptions={editTileOptions}
                  onClickRow={onClickFolder}
                />
              </>
            )}
            <CreateModelModal
              formId='Rename'
              modalHeading='Rename'
              modelName=''
              btnCloseLabel='CANCEL'
              btnActionLabel='RENAME'
              open={openModal}
              setOpen={setOpenModal}
              modelInputLabel='NEW NAME'
              btnActionHandler={createModelActionHandler}
              closeOnSubmit={true}
            />
          </div>

          <div className='additional-reference__actions'>
            {onClickContinue && (
              <button className='button-primary' onClick={onClickContinue}>
                Continue
              </button>
            )}
          </div>
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 24px 40px',
        }}
      >
        <Switch label={'not applicable'} onSwitchChange={setNotApplicableToggle} />
        {step === STEP_UPLOAD && (
          <div className='additional-reference__actions'>
            {filteredImages.length > 0 && (
              <button
                className='button-transparent-black mr-2'
                onClick={() => setStep(STEP_PREVIEW)}
              >
                View Uploads
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AtelierAdditions;

import React, { useState, useContext, createRef } from 'react';
import { useForm } from 'react-hook-form';
import * as markerjs2 from 'markerjs2';

import Modal from 'components/Common/Modal/Modal';
import TextAreaInput from 'components/Dashboard/FormComponents/TextAreaInput';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { UserContext } from 'providers/UserProvider';
import useMessages from 'hooks/useMessages';

export type AssetAnnotationModal = {
  heading: string;
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  imgSrc: string;
  reference: any;
};

const AssetAnnotationModal: React.FC<AssetAnnotationModal> = props => {
  const { heading, open, setOpen, imgSrc, reference } = props;

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { postMessage, setMessageModelAssoc, mutateMessages } = useMessages(idToken, {
    model: reference,
    open: false,
  });
  const [loading, setLoading] = useState(false);

  const [imgSrcAnnotated, setImgSrcAnnotated] = useState(null);
  const imgRef = createRef<HTMLImageElement>();

  const { dispatch } = useFrameUI();
  const { handleSubmit, register, reset } = useForm({});

  const showMarkerArea = e => {
    if (!imgSrcAnnotated || e.type === 'click') {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.settings.displayMode = 'popup';
      markerArea.availableMarkerTypes = markerArea.BASIC_MARKER_TYPES;
      markerArea.renderAtNaturalSize = false;
      markerArea.renderWidth = imgRef.current.width;
      markerArea.renderHeight = imgRef.current.height;

      const closeAnnotationEvenAction = () => {
        setOpen(false);
      };
      markerArea.addCloseEventListener(closeAnnotationEvenAction);
      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener((dataUrl, state) => {
        markerArea.removeCloseEventListener(closeAnnotationEvenAction);
        setImgSrcAnnotated(dataUrl);
      });
      
      // launch marker.js
      markerjs2.Activator.addKey(process.env.REACT_APP_markerjs_license_key)
      markerArea.show();
    }
  };

  const onPostMessage = async values => {
    if (loading) {
      return;
    }
    setLoading(true);
    values['file'] = imgSrcAnnotated;
    values['fileMetadata'] = { type: 'image', contentType: 'image/png' };
    dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
    await postMessage?.(values);
    reset();
    setOpen(false);
    dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
    setLoading(false);
  };

  return (
    <>
      <div id='asset-annoation-modal'>
        <Modal
          modalHeading={heading || 'Annotate'}
          modalBtnClose={'cancel'}
          modalBtnActionLabel={'Save'}
          modalBtnActionHandler={handleSubmit(onPostMessage)}
          backgroundColor='#fff'
          modalOpen={open}
          setModalOpen={setOpen}
          closeOnClickAway={false}
        >
          <img
            src={imgSrcAnnotated || imgSrc}
            ref={imgRef}
            onLoad={showMarkerArea}
            onClick={showMarkerArea}
          />
          <div className='collection-access-modal__label'>
            <form onSubmit={handleSubmit(onPostMessage)}>
              <TextAreaInput
                name={'message'}
                required={false}
                register={register}
                placeholder='Optional, a summary of annotations'
                update={true}
                fullWidth={true}
                onKeyDownHandler={handleSubmit(onPostMessage)}
                disabled={loading}
              />
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AssetAnnotationModal;

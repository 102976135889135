import React, { useContext } from 'react';
import { auth } from '../firebase';
import { NotificationsContext } from 'providers/contexts';
import ForgotPassword from 'components/Authentication/ForgotPassword/ForgotPassword';
import { Toast } from 'components';

const PasswordReset = () => {
  const { setDisplayToast } = useContext(NotificationsContext);

  const sendResetEmail = values => {
    const { email } = values;

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setDisplayToast({
          type: 'success',
          message: `If you have a user account with us, you should receive an email with instructions`,
        });
      })
      .catch(err => {
        if (err?.code === 'auth/user-not-found') {
          setDisplayToast({
            type: 'success',
            message: `If you have a user account with us, you should receive an email with instructions`,
          });
        } else if (err?.code === 'auth/user-disabled') {
          setDisplayToast({
            type: 'error',
            message:
              'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
          });
        }
      });
  };

  return (
    <>
      <Toast />
      <ForgotPassword
        emailFieldLabel='email address'
        title='Forgot Password?'
        subtitle='Enter your email address and we’ll send you a link to reset your password.'
        backBtnUrl='/'
        backBtnTxt='Back to Sign in'
        submitBtnTxt='Submit'
        onResetPassword={sendResetEmail}
      />
    </>
  );
};

export default PasswordReset;

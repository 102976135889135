import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from '@reach/router';
import useSWR from 'swr';
import _ from 'lodash';

import './CustomizeSignOff.scss';
import ProductMedia from '../../Dashboard/ProductMedia/ProductMedia';
import ProductSlider from '../../Dashboard/ProductSlider/ProductSlider';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { filterProductListing, mmAPI } from 'services/Api';
import { firebaseStorageUrl } from 'providers/UploadProvider';
import { APIMedia } from 'components/VirtualAtelier/StyleSummary/StyleSampleReview';
import InfoIcon from 'assets/icons/Icon-info-circle-full.svg';
import Switch from 'components/Common/Switch/Switch';
import LogRocket from 'logrocket';

export type ProductMediaManage = {
  listingsPosition: Record<string, { keys: any[]; positions: number[] }>;
  setListingsPosition: unknown;
};

const ProductMediaManage: React.FC<ProductMediaManage> = props => {
  const { listingsPosition, setListingsPosition } = props;
  const [editOrderToggle, setEditOrderToggle] = useState(false);
  const [media, setMedia] = useState([]);
  const [listingData, setListingData] = useState(null);
  const [listingImageData, setListingImageData] = useState([]);
  const { brand_model_id } = useParams();
  const { imageUploadProgress } = useContext(UploadContext);

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { data: brandMedia, mutate: mutateBrandMedia } = useSWR(
    ['/api/media/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    const mediaEffect = async () => {
      if (!brandMedia) return;

      const mediaSrc = _.fromPairs(media.map(m => [m.key, m.src]));
      const detachedMedia = [];
      for (const m of brandMedia) {
        if (!m.listingUniqueId && !m.sku) {
          if (
            imageUploadProgress[m?.assets?.image?.path] === undefined ||
            imageUploadProgress[m?.assets?.image?.path] === 100
          ) {
            detachedMedia.push({
              ...m,
              src: mediaSrc[m.key],
            });
          }
        }
      }

      const getMediaAssetUrls = async (m: APIMedia) => {
        let src = m?.src;
        if (!src) {
          try {
            src = await firebaseStorageUrl(m?.assets?.image?.path);
          } catch (e) {
            console.log(e);
          }
        }
        return {
          ...m,
          src,
        };
      };
      const getMediaAssetsResolved = mediaModels => {
        return Promise.all(mediaModels.map(m => getMediaAssetUrls(m)));
      };

      const detachedMediaResolved = await getMediaAssetsResolved(detachedMedia);
      setMedia(detachedMediaResolved.filter((m: APIMedia) => m.src));
    };
    mediaEffect();
  }, [brandMedia, imageUploadProgress]);

  // get all media for brand and set in state, this state will be updated when images drag/drop
  const getListings = async () => {
    const res = await filterProductListing(idToken, brand_model_id);
    setListingData(res?.data?.data);
  };
  useEffect(() => {
    getListings();
  }, []);

  const listingDataEffect = async listingData => {
    if (!listingData) return;
    let updatedData = null;
    console.log('listingData', listingData);
    updatedData = await Promise.all(
      listingData
        .filter(l => l.brand === parseInt(brand_model_id))
        .map(async listing => {
          try {
            const slides = [];
            // media is denormalized onto listing to make retrieval faster
            const images = Object.values<APIMedia>(listing.media);
            for await (const image of images) {
              try {
                let pathResized;
                if (image) {
                  const path = image?.assets?.image?.path;
                  const pathResizeIndex = path.lastIndexOf('.png');

                  pathResized = Array.from(path);

                  pathResized.splice(pathResizeIndex, 0, '_300x400');
                  let firebaseImageUrl = null;
                  firebaseImageUrl = image
                    ? await firebaseStorageUrl(pathResized.join(''))
                    : null;
                  if (firebaseImageUrl) {
                    slides.push({
                      key: image.key,
                      mediaKey: image.key,
                      imageUrl: firebaseImageUrl,
                      name: image.name,
                      created: image.created,
                      modified: image.modified,
                      position: image.position,
                    });
                  }
                }
              } catch (e) {
                console.error('listing media thumb');
                console.error(e);
                console.error('error listing', listing);
              }
            }
            return {
              ...listing,
              linkTo: `/brand/${brand_model_id}/products/listing/${listing.key}`,
              slides: slides.sort((a, b) => a.position - b.position),
            };
          } catch (error) {
            LogRocket.captureException(error);
            console.error('listing media thumbs');
            console.error(error);
            console.error('error listing', listing);
          }
        })
    );
    console.log('setListingImageData from updatedData', updatedData);
    setListingImageData(updatedData);
  };
  useEffect(() => {
    listingDataEffect(listingData);
  }, [listingData, brand_model_id]);

  const tooltipTxt =
    'Drag and drop to associate media with a listing. Or to remove an image association from a listing.';
  return (
    <div className='customize-sign-off customize-sign-off__maxHeight'>
      <div className='product-upload__header'>
        <div className='product-upload__heading'>Product Image Management</div>
        <span className='product-upload__info'>
          <img src={InfoIcon} alt='Info Icon' />
          {tooltipTxt && (
            <div className='tooltip'>
              <span className='tooltip__txt'>{tooltipTxt}</span>
            </div>
          )}
        </span>
      </div>
      <div className='customize-sign-off__wrap'>
        <div className='customize-sign-off__block customize-sign-off__scrollable'>
          <div className='customize-sign-off__heading'>
            <Link to={`/brand/${brand_model_id}/products`}>Product Listings</Link>
          </div>
          <div className='product-upload__select'>
            <Switch label={'edit order'} onSwitchChange={setEditOrderToggle} />
          </div>
          {listingImageData.map(item => {
            const { key, style_name, slides } = item;
            return (
              <ProductSlider
                key={key}
                productName={style_name}
                slides={slides}
                listing={item}
                mutateBrandMedia={mutateBrandMedia}
                mutateListingData={getListings}
                listingsPosition={listingsPosition}
                setListingsPosition={setListingsPosition}
                editOrderToggle={editOrderToggle}
                listingData={listingData}
                setListingData={setListingData}
              />
            );
          })}
        </div>
        <ProductMedia
          media={media}
          onDeleteMedia={deletedMediaKey =>
            setMedia(prev => prev.filter(m => m.key !== deletedMediaKey))
          }
          mutateBrandMedia={mutateBrandMedia}
          mutateListingData={getListings}
        />
      </div>
    </div>
  );
};

export default ProductMediaManage;

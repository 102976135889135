import React, { useState, useContext, useEffect } from 'react';
import { navigate, Link } from '@reach/router';
import { useForm } from 'react-hook-form';
import {
  getBrandCategories,
  updateProductListing,
  apiDeleteProduct,
  deleteMediaModel,
  postMediaMethodDetach,
} from 'services/Api';
import InnerImageZoom from 'react-inner-image-zoom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './ProductListingDashboard.scss';
import './ListingPage.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import SelectInput from 'components/Dashboard/FormComponents/SelectInput';
import TextInput from 'components/Dashboard/FormComponents/TextInput';
import TextAreaInput from 'components/Dashboard/FormComponents/TextAreaInput';
import { ConfirmDialog } from 'components';

import NextIcon from 'assets/icons/arrow-next.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import ArrowIcon from 'assets/icons/angle-right-gray.svg';
import DotsIcon from 'assets/icons/manage-icon-gray.svg';

import DownloadIcon from 'assets/icons/download.svg';
import ViewLargerIcon from 'assets/icons/view-larger.svg';
import CloseIcon from 'assets/icons/close.svg';
import { UserContext } from 'providers/UserProvider';
import { ModalsContext, NotificationsContext } from 'providers/contexts';
import { mutate } from 'swr';
import { sortMediaPosition } from 'utils/sort';
import PlaceholderImg from 'assets/images/placeholder-image.png';

export interface IProductInfo {
  title: string;
  content?: string;
  accordion: boolean;
  type: string;
  name: string;
}

export interface ICategories {
  title: string;
  category: string;
  key: number;
}

export interface IImages {
  imageUrl?: string;
  key?: string;
  shot?: string;
  name?: string;
  position?: number;
}

export interface IProduct {
  images: IImages[];
  productInfo: IProductInfo[];
  categories?: ICategories[];
  heading?: string;
  style_number?: string;
  sku: string;
  upc: string;
  lookName: string;
  line_model_id: string;
  line_name: string;
  collection_model_id: string;
  collection_name: string;
}

interface IListing {
  key: string;
  brand: number;
  style: any;
  addition: boolean;
}

export interface IProductListingDashboard {
  listing: IProduct[];
  mutateListing?: any;
  popUpOptions?: IPopUpOptions[];
  lookNavigationVisible: boolean;
  listingObject: IListing;
  editParam?: boolean;
  brand_model_id: string;
  listing_model_id: string;
}

const ListingPage: React.FC<IProductListingDashboard> = props => {
  const { setDisplayToast } = useContext(NotificationsContext);
  const { user } = useContext(UserContext);
  const { idToken, brand } = user;

  const {
    listing,
    lookNavigationVisible,
    listingObject,
    editParam = false,
    listing_model_id,
    brand_model_id,
  } = props;
  const [openAccordion, setOpenAccordion] = useState([]);
  const [currentLook, setCurrentLook] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [edit, setEdit] = useState(editParam);
  const [cats, setCats] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const listingImages =
    listing[currentLook].images.length > 0
      ? listing[currentLook].images.sort(sortMediaPosition)
      : [
          { imageUrl: PlaceholderImg, key: 'placeholder1' },
          { imageUrl: PlaceholderImg, key: 'placeholder2' },
          { imageUrl: PlaceholderImg, key: 'placeholder3' },
        ];

  const pages = [
    { title: brand.name, link: `/brand/${brand.key}/products` },
    {
      title: `${listing[currentLook].line_name ? listing[currentLook].line_name : ''}`,
      link: `/brand/${brand.key}/products?lines=${listing[currentLook].line_model_id}`,
    },
    {
      title: `${
        listing[currentLook].collection_name ? listing[currentLook].collection_name : ''
      }`,
      link: `/brand/${brand.key}/products?collections=${listing[currentLook].collection_model_id}`,
    },
  ];

  useEffect(() => {
    const getCategories = async () => {
      const res = await getBrandCategories(idToken, brand.key);
      if (!res?.data?.error) {
        const selectInputCats = res?.data?.data?.map(item => {
          return { itemKey: item.key, name: item.name };
        });
        setCats(selectInputCats);
      } else {
        setDisplayToast({ type: 'error', message: 'Error getting categories' });
      }
    };
    cats === null && getCategories();
  }, []);

  const updateListing = () => {
    handleSubmit(async e => {
      const dirtyValuesSubmitted = dirtyValues(formState?.dirtyFields as Set<string>, e);
      const updatedCategories = [];
      const updatedCategoriesNames = [];
      // parse categories from Categories select inputs and format for api
      Object.entries(e).forEach(item => {
        const [key, value] = item;
        if (key.includes('Category')) {
          delete e[key];
          // @ts-ignore
          const [{ itemKey: catKey, name }] = value;
          updatedCategories.push(catKey);
          updatedCategoriesNames.push(name);
        }
      });
      const params = {
        ...e,
        ...dirtyValuesSubmitted,
        ...(updatedCategories.length && { categories: updatedCategories.toString() }),
        ...(updatedCategoriesNames.length && {
          category_names: updatedCategoriesNames.toString(),
        }),
      };
      params.brand = brand_model_id;
      params.location = window.location.href;
      const res = await updateProductListing(idToken, listingObject.key, params);
      if (!res?.data?.error) {
        props?.mutateListing();
        setEdit(false);
      } else {
        setDisplayToast({ type: 'error', message: res.data?.error?.message });
      }
    })();
  };

  const deleteListingActionHandler = async () => {
    const { key: listing_key } = listingObject;

    try {
      const response = await apiDeleteProduct(idToken, listing_key, {
        brand: brand_model_id,
      });

      if (response.data?.error) {
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else {
        setDisplayToast({
          type: 'success',
          message: `Product has been deleted successfully.`,
        });

        setTimeout(() => {
          navigate(`/brand/${listingObject.brand}/products`);
        }, 2000);
      }
    } catch (e) {
      setDisplayToast({
        type: 'error',
        message: e.response?.data?.error?.message || e.toString(),
      });
    }
    setModalOpen(false);
  };

  const [formDefaultValues, setFormDefaultValues] = useState({});

  useEffect(() => {
    const listingEffect = listingVal => {
      const defaultValues = listingVal[currentLook].productInfo?.reduce(
        (values, field) => {
          values[field.name] = field.content;
          return values;
        },
        {}
      );
      setFormDefaultValues(defaultValues);
    };
    listingEffect(listing);
  }, [currentLook, listing]);

  const { register, handleSubmit, errors, control, formState } = useForm({
    defaultValues: formDefaultValues,
  });

  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);

  const detachMediaActionHandler = React.useCallback(
    async (media_model_id, style_number?, sku?, shot?, name?) => {
      await postMediaMethodDetach(idToken, {
        media: media_model_id,
        brand: brand_model_id,
        name,
      });
      try {
        setDisplayToast({
          type: 'info',
          message: 'Media association has been removed from this listing.',
        });
        props?.mutateListing();
      } catch (error) {
        console.log('postMediaMethodDetach', error);
        setDisplayToast({
          type: 'error',
          message: 'Media association could not be removed from this listing.',
        });
      }
    },
    []
  );

  const popUpOptions = React.useMemo(
    () => [
      {
        selectItem: 'Delete Image',
      },
    ],
    []
  );

  return (
    <>
      <div className='product-listing-dashboard__download'>
        {/* <img
          src={DownloadIcon}
          alt='Download'
          className='product-listing-dashboard__download pr-4'
        /> */}
        <Link to={`/brand/${brand_model_id}/style/${listingObject?.style}`}>
          <button className='button-primary button-smallest'>virtual atelier</button>
        </Link>
      </div>
      <div className='container'>
        <div className='product-listing-dashboard listing-page'>
          <Breadcrumbs pages={pages} />
          <div className='row'>
            <div className='col-lg-8 col-md-12'>
              <div className='product-listing-dashboard__slider-col'>
                <div className='product-listing-dashboard__thumbnail'>
                  <div className='product-listing-dashboard__right-rail'>
                    {listingImages.map(({ imageUrl, key }, idx) => (
                      <img
                        key={key}
                        className={
                          currentImage === idx
                            ? 'product-listing-dashboard__small-img product-listing-dashboard__current-img'
                            : 'product-listing-dashboard__small-img'
                        }
                        src={imageUrl}
                        onClick={() => setCurrentImage(idx)}
                        alt={listing[currentLook].heading}
                      />
                    ))}
                  </div>
                </div>
                <div className='product-listing-dashboard__slider-main'>
                  <div className='product-listing-dashboard__main'>
                    <TransitionGroup component={null}>
                      <CSSTransition
                        classNames='fade-in'
                        timeout={500}
                        key={currentImage}
                      >
                        <>
                          <img
                            className='product-listing-dashboard__main-img'
                            src={listingImages[currentImage]?.imageUrl || PlaceholderImg}
                            alt={listing[currentLook].heading}
                          />
                          {popUpOptions?.length > 0 && (
                            <img
                              className='product-media__dots'
                              src={DotsIcon}
                              alt={`Media management actions`}
                              onClick={() => {
                                setDialogProps({
                                  dialogBody:
                                    'Are you sure you would like to delete this image?',
                                  btnActionHandler: () => {
                                    detachMediaActionHandler(
                                      listingImages[currentImage].key,
                                      listing[currentLook].style_number,
                                      listing[currentLook].sku,
                                      listingImages[currentImage].shot,
                                      listingImages[currentImage].name
                                    );
                                  },
                                });
                                setOpenDialog(true);
                              }}
                            />
                          )}
                        </>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>

                  <div className='product-listing-dashboard__main-footer'>
                    <button
                      className='button-dashboard'
                      onClick={() => setLightboxOpen(!lightboxOpen)}
                    >
                      <img src={ViewLargerIcon} alt='View Larger' className='btn-icon' />
                      View Larger
                    </button>
                    <div className='product-listing-dashboard__arrows'>
                      <img
                        className='product-listing-dashboard__main-prev'
                        src={PrevIcon}
                        alt='Previous'
                        onClick={() =>
                          setCurrentImage(
                            currentImage === 0
                              ? listingImages.length - 1
                              : currentImage - 1
                          )
                        }
                      />
                      <img
                        className='product-listing-dashboard__main-next'
                        src={PrevIcon}
                        alt='Next'
                        onClick={() =>
                          setCurrentImage(
                            currentImage === listingImages.length - 1
                              ? 0
                              : currentImage + 1
                          )
                        }
                      />
                    </div>
                  </div>

                  {lookNavigationVisible ? (
                    <div className='product-listing-dashboard__count'>
                      <img
                        className='look-listing__prev'
                        src={PrevIcon}
                        alt='Previous'
                        onClick={() =>
                          setCurrentLook(
                            currentLook === 0 ? listing.length - 1 : currentLook - 1
                          )
                        }
                      />
                      Look {currentLook + 1} / {listing.length}
                      <img
                        className='look-listing__next'
                        src={NextIcon}
                        alt='Next'
                        onClick={() =>
                          setCurrentLook(
                            currentLook === listing.length - 1 ? 0 : currentLook + 1
                          )
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-12'>
              {edit && (
                <div>
                  <div className='controls-bar__btns product-listing-dashboard__btns'>
                    <button
                      className='button-transparent-black'
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className='button-primary'
                      onClick={() => {
                        updateListing();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div className='product-listing-dashboard__info listing-page__info'>
                {listingObject.addition && (
                  <button className='button-badge color-white-3-bg border solid 4px'>
                    addition
                  </button>
                )}
                {!edit ? (
                  <div className='product-listing-dashboard__heading'>
                    <h3>{listing[currentLook].heading}</h3>
                    <div className='product-listing-dashboard__edit'>
                      <img
                        src={DotsIcon}
                        alt='Edit'
                        onClick={() => setOpenPopUp(!openPopUp)}
                      />
                      <PopUp open={openPopUp}>
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setEdit(true);
                            setOpenPopUp(false);
                          }}
                        >
                          Edit
                        </li>
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpenPopUp(false);
                            setModalOpen(true);
                          }}
                        >
                          Delete
                        </li>
                      </PopUp>
                    </div>
                  </div>
                ) : (
                  <div className='row pb-1'>
                    <TextInput
                      name='style_name'
                      update={true}
                      value={listing[currentLook].heading}
                      label='Style Name'
                      fullWidth={true}
                      register={register}
                      validate={value => {
                        return (
                          [/^[A-Za-z0-9 ]*$/].every(pattern => pattern.test(value)) ||
                          'Invalid Style Name. Alphanumeric characters expected.'
                        );
                      }}
                    />
                    {errors?.style_name && (
                      <div className='error-text w-100'>
                        {errors?.style_name?.message}
                      </div>
                    )}
                  </div>
                )}
                {listing[currentLook].categories &&
                  listing[currentLook].categories.map(({ title, category, key }) =>
                    !edit ? (
                      <div className='product-listing-dashboard__categories' key={key}>
                        <div className='product-listing-dashboard__cat-title'>
                          {title}
                        </div>
                        <div className='product-listing-dashboard__category'>
                          {category}
                        </div>
                      </div>
                    ) : (
                      <div className='row pb-1'>
                        <SelectInput
                          name={title}
                          options={cats}
                          control={control}
                          value={category}
                          label={title}
                          itemKey={key}
                          key={key}
                          update
                          fullWidth
                        />
                      </div>
                    )
                  )}
                {listing[currentLook].productInfo &&
                  listing[currentLook].productInfo.map(
                    ({ title, content, accordion, type, name }, idx) =>
                      !edit ? (
                        <div className='product-listing-dashboard__row' key={idx}>
                          <h5
                            className={
                              accordion ? 'product-listing-dashboard__title' : ''
                            }
                            onClick={
                              openAccordion.includes(idx)
                                ? () =>
                                    setOpenAccordion(openAccordion.filter(e => e !== idx))
                                : () => setOpenAccordion([...openAccordion, idx])
                            }
                          >
                            {title}
                            {accordion ? (
                              <img
                                className={
                                  openAccordion.includes(idx)
                                    ? 'arrow-bottom'
                                    : 'arrow-right'
                                }
                                src={ArrowIcon}
                                alt='Open'
                              />
                            ) : null}
                          </h5>
                          {accordion ? (
                            <div
                              className={
                                openAccordion.includes(idx)
                                  ? 'visible-accordion'
                                  : 'hidden-accordion'
                              }
                            >
                              <div className='product-listing-dashboard__content'>
                                <h4>{content}</h4>
                              </div>
                            </div>
                          ) : (
                            <div className={accordion ? 'hidden' : 'visible'}>
                              <div className='product-listing-dashboard__content'>
                                <h4>{content}</h4>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        {
                          input: (
                            <div className='row pb-1'>
                              <TextInput
                                name={name}
                                update={true}
                                value={content}
                                label={title}
                                fullWidth={true}
                                register={register}
                                key={`${name}-input`}
                              />
                            </div>
                          ),
                          textarea: (
                            <div className='row pb-1'>
                              <TextAreaInput
                                label={title}
                                name={name}
                                update={true}
                                fullWidth
                                value={content}
                                register={register}
                                key={`${name}-textarea`}
                              />
                            </div>
                          ),
                        }[type]
                      )
                  )}
                {!edit ? (
                  <div className='product-listing-dashboard__categories pt-4'>
                    <div className='product-listing-dashboard__cat-title'>SKU</div>
                    <div className='product-listing-dashboard__category'>
                      {listing[currentLook].sku}
                    </div>
                  </div>
                ) : (
                  <div className='row pb-1'>
                    <TextInput
                      name='sku'
                      update={true}
                      value={listing[currentLook].sku}
                      label='SKU'
                      fullWidth={true}
                      register={register}
                    />
                  </div>
                )}
                {listing[currentLook].upc &&
                  (!edit ? (
                    <div className='product-listing-dashboard__categories pt-1'>
                      <div className='product-listing-dashboard__cat-title'>UPC</div>
                      <div className='product-listing-dashboard__category'>
                        {listing[currentLook].upc}
                      </div>
                    </div>
                  ) : (
                    <TextInput
                      name='upc'
                      update={true}
                      value={listing[currentLook].upc}
                      label='SKU'
                      fullWidth={true}
                      register={register}
                    />
                  ))}
                <div className='product-listing-dashboard__look-name'>
                  {listing[currentLook].lookName &&
                    `Look ${listing[currentLook].lookName}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            {listing[currentLook].heading}
            <div className='product-listing-dashboard__lightbox-buttons-wrap'>
              <div className='product-listing-dashboard__lightbox-arrows'>
                <img
                  className='product-listing-dashboard__main-prev'
                  src={PrevIcon}
                  alt='Previous'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === 0 ? listingImages.length - 1 : currentImage - 1
                    )
                  }
                />
                <img
                  className='product-listing-dashboard__main-next'
                  src={PrevIcon}
                  alt='Next'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === listingImages.length - 1 ? 0 : currentImage + 1
                    )
                  }
                />
              </div>
              <img
                src={CloseIcon}
                alt='Close'
                onClick={() => setLightboxOpen(!lightboxOpen)}
              />
            </div>
          </div>
          <div className='product-listing-dashboard__lightbox-inner'>
            <TransitionGroup component={null}>
              <CSSTransition classNames='fade-in' timeout={200} key={currentImage}>
                <InnerImageZoom
                  fullscreenOnMobile={true}
                  className='product-listing-dashboard__main-img'
                  src={listingImages[currentImage]?.imageUrl || '//satyr.io/1280x2:3'}
                />
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </CSSTransition>

      {modalOpen && (
        <ConfirmDialog
          dialogBody='Deleting product can’t be undone. Are you sure you want to continue delete this product ?'
          btnActionHandler={deleteListingActionHandler}
          open={modalOpen}
          setOpen={setModalOpen}
        />
      )}
    </>
  );
};

export default ListingPage;

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function dirtyValues(dirtyFields: Set<string> | boolean, allValues: any): any {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(allValues)) {
    return allValues;
  }
  // Here, we have an object
  return (
    dirtyFields &&
    Object.fromEntries(
      Array.from(dirtyFields).map((rhfDirtyKey: string) => {
        const formKey = rhfDirtyKey.indexOf('.')
          ? rhfDirtyKey.split('.')[0]
          : rhfDirtyKey;
        return [formKey, dirtyValues(true, allValues[formKey])];
      })
    )
  );
}

import React from 'react';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { DropdownList } from 'components/Dashboard/FilterBar/FilterBar';

type SidebarStateType = {
  open: boolean;
  loading: boolean;
  props: any;
};

type ChatbarStateType = {
  open: boolean;
  loading: boolean;
  props: any;
  messages: any[];
};

type LocationBarStateType = {
  backLinkTo: string | (() => void);
  title: string;
};

type FilterBarStateType = {
  visible: boolean;
  infoIconVisible: boolean;
  infoIconHandler: () => void;
  searchVisible: boolean;
  options: IPopUpOptions[];
  dropdownListVisible: boolean;
  dropdownList: DropdownList[];
  viewModeIconVisible: boolean;
};

type DashboardNavStateType = {
  visible: boolean;
  options: IPopUpOptions[];
};

type InfoNavStateType = {
  visible: boolean;
};

type SelectedCollectionType = {
  model: any;
};

type InitialFrameUIStateType = {
  filterBar: FilterBarStateType;
  dashboardNav: DashboardNavStateType;
  sidebar: SidebarStateType;
  chatbar: ChatbarStateType;
  locationBar: LocationBarStateType;
  infoBar: InfoNavStateType;
  selectedCollection: SelectedCollectionType
};

const initialFrameUIState: InitialFrameUIStateType = {
  filterBar: {
    visible: false,
    infoIconVisible: false,
    infoIconHandler: null,
    searchVisible: false,
    options: [],
    dropdownListVisible: false,
    dropdownList: [],
    viewModeIconVisible: false,
  },
  dashboardNav: {
    visible: false,
    options: [],
  },
  sidebar: {
    open: false,
    loading: false,
    props: {},
  },
  chatbar: {
    open: false,
    loading: false,
    props: {},
    messages: [],
  },
  locationBar: {
    backLinkTo: null,
    title: null,
  },
  infoBar: {
    visible: false,
  },
  selectedCollection: {
    model: {}
  },
};

type FrameUIContextType = {
  state: InitialFrameUIStateType;
  dispatch: React.Dispatch<FrameUIActions>;
};

const FrameUIContext = React.createContext<FrameUIContextType>({
  state: initialFrameUIState,
  dispatch: () => null,
});

export enum FrameUIActionsTypes {
  FILTER_BAR_OPTIONS = 'FILTER_BAR_OPTIONS',
  FILTER_BAR_VISIBLE = 'FILTER_BAR_VISIBLE',
  FILTER_BAR_INFOICON_VISIBLE = 'FILTER_BAR_INFOICON_VISIBLE',
  FILTER_BAR_INFOICON_HANDLER = 'FILTER_BAR_INFOICON_HANDLER',
  FILTER_BAR_SEARCH_VISIBLE = 'FILTER_BAR_SEARCH_VISIBLE',
  FILTER_BAR_DROPDOWNLIST_VISIBLE = 'FILTER_BAR_DROPDOWNLIST_VISIBLE',
  FILTER_BAR_DROPDOWNLIST_CONDITIONS = 'FILTER_BAR_DROPDOWNLIST_CONDITIONS',
  FILTER_BAR_VIEWMODE_ICON_VISIBLE = 'FILTER_BAR_VIEWMODE_ICON_VISIBLE',
  FILTER_BAR_RESET = 'FILTER_BAR_RESET',
  DASHBOARD_NAV_VISIBLE = 'DASHBOARD_NAV_VISIBLE',
  DASHBOARD_NAV_RESET = 'DASHBOARD_NAV_RESET',
  SIDEBAR_OPEN = 'SIDEBAR_OPEN',
  SIDEBAR_LOADING = 'SIDEBAR_LOADING',
  SIDEBAR_PROPS = 'SIDEBAR_PROPS',
  LOCATIONBAR_TITLE = 'LOCATIONBAR_TITLE',
  LOCATIONBAR_BACK_ONCLICK = 'LOCATIONBAR_BACK_ONCLICK',
  CHATBAR_OPEN = 'CHATBAR_OPEN',
  CHATBAR_LOADING = 'CHATBAR_LOADING',
  CHATBAR_PROPS = 'CHATBAR_PROPS',
  CHATBAR_MESSAGES = 'CHATBAR_MESSAGES',
  CHATBAR_RESET = 'CHATBAR_RESET',
  INFO_BAR_VISIBLE = 'INFO_BAR_VISIBLE',
  SET_SELECTED_COLLECTION = 'SET_SELECTED_COLLECTION'
}
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type FilterBarPayload = {
  [FrameUIActionsTypes.FILTER_BAR_OPTIONS]: IPopUpOptions[];
  [FrameUIActionsTypes.FILTER_BAR_VISIBLE]: boolean;
  [FrameUIActionsTypes.FILTER_BAR_INFOICON_VISIBLE]: boolean;
  [FrameUIActionsTypes.FILTER_BAR_INFOICON_HANDLER]: (arg?) => void;
  [FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_VISIBLE]: boolean;
  [FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_CONDITIONS]: DropdownList[];
  [FrameUIActionsTypes.FILTER_BAR_SEARCH_VISIBLE]: boolean;
  [FrameUIActionsTypes.FILTER_BAR_VIEWMODE_ICON_VISIBLE]: boolean;
  [FrameUIActionsTypes.FILTER_BAR_RESET]: null;
  [FrameUIActionsTypes.DASHBOARD_NAV_VISIBLE]: boolean;
  [FrameUIActionsTypes.DASHBOARD_NAV_RESET]: null;
  [FrameUIActionsTypes.SIDEBAR_OPEN]: boolean;
  [FrameUIActionsTypes.SIDEBAR_LOADING]: boolean;
  [FrameUIActionsTypes.SIDEBAR_PROPS]: { [key: string]: any };
  [FrameUIActionsTypes.CHATBAR_OPEN]: boolean;
  [FrameUIActionsTypes.CHATBAR_LOADING]: boolean;
  [FrameUIActionsTypes.CHATBAR_PROPS]: { [key: string]: any };
  [FrameUIActionsTypes.CHATBAR_MESSAGES]: any[];
  [FrameUIActionsTypes.CHATBAR_RESET]: null;
  [FrameUIActionsTypes.LOCATIONBAR_TITLE]: string;
  [FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK]: string | (() => void);
  [FrameUIActionsTypes.INFO_BAR_VISIBLE]: boolean;
  [FrameUIActionsTypes.SET_SELECTED_COLLECTION]: { model : any};
};

export type FrameUIActions = ActionMap<FilterBarPayload>[keyof ActionMap<
  FilterBarPayload
>];

function sidebarReducer(state: SidebarStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.SIDEBAR_OPEN: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case FrameUIActionsTypes.SIDEBAR_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case FrameUIActionsTypes.SIDEBAR_PROPS: {
      return {
        ...state,
        props: action.payload,
      };
    }
    default:
      return state;
  }
}

function chatbarReducer(state: ChatbarStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.CHATBAR_OPEN: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case FrameUIActionsTypes.CHATBAR_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case FrameUIActionsTypes.CHATBAR_PROPS: {
      return {
        ...state,
        props: action.payload,
      };
    }
    case FrameUIActionsTypes.CHATBAR_MESSAGES: {
      return {
        ...state,
        messages: action.payload,
      };
    }
    case FrameUIActionsTypes.CHATBAR_RESET: {
      return {
        ...state,
        props: {},
        loading: false,
        open: false,
        messages: [],
      };
    }
    default:
      return state;
  }
}

function filterBarReducer(state: FilterBarStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.FILTER_BAR_RESET: {
      return initialFrameUIState.filterBar;
    }
    case FrameUIActionsTypes.FILTER_BAR_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_INFOICON_VISIBLE: {
      return {
        ...state,
        infoIconVisible: action.payload,
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_INFOICON_HANDLER: {
      return {
        ...state,
        infoIconHandler: action.payload,
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_VISIBLE: {
      return {
        ...state,
        dropdownListVisible: action.payload,
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_CONDITIONS: {
      return {
        ...state,
        dropdownList: [...action.payload],
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_OPTIONS: {
      return {
        ...state,
        options: [...action.payload],
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_SEARCH_VISIBLE: {
      return {
        ...state,
        searchVisible: action.payload,
      };
    }
    case FrameUIActionsTypes.FILTER_BAR_VIEWMODE_ICON_VISIBLE: {
      return {
        ...state,
        viewModeIconVisible: action.payload,
      };
    }
    default:
      return state;
  }
}

function dashboardNavReducer(state: DashboardNavStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.DASHBOARD_NAV_RESET: {
      return {
        ...state,
        visible: false,
        options: [],
      };
    }
    case FrameUIActionsTypes.DASHBOARD_NAV_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }
    default:
      return state;
  }
}

function locationBarReducer(state: LocationBarStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.LOCATIONBAR_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK: {
      return {
        ...state,
        backLinkTo: action.payload,
      };
    }
    default:
      return state;
  }
}

function infoBarReducer(state: InfoNavStateType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.INFO_BAR_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }
    default:
      return state;
  }
}

function setCollectionReducer(state: SelectedCollectionType, action: FrameUIActions) {
  switch (action.type) {
    case FrameUIActionsTypes.SET_SELECTED_COLLECTION: {
      return {
        ...state,
        model: action.payload,
      };
    }
    default:
      return state;
  }
}

const mainReducer = (
  { filterBar, dashboardNav, sidebar, chatbar, locationBar, infoBar, selectedCollection }: InitialFrameUIStateType,
  action: FrameUIActions
) => ({
  sidebar: sidebarReducer(sidebar, action),
  chatbar: chatbarReducer(chatbar, action),
  filterBar: filterBarReducer(filterBar, action),
  dashboardNav: dashboardNavReducer(dashboardNav, action),
  locationBar: locationBarReducer(locationBar, action),
  infoBar: infoBarReducer(infoBar, action),
  selectedCollection: setCollectionReducer(selectedCollection, action)
});

const FrameUIProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(mainReducer, initialFrameUIState);
  return (
    <FrameUIContext.Provider value={{ state, dispatch }}>
      {children}
    </FrameUIContext.Provider>
  );
};

const useFrameUI = (): FrameUIContextType => {
  const context = React.useContext(FrameUIContext);
  if (!context) {
    throw new Error(`useFrameUI must be used within a FrameUIProvider`);
  }
  const { state, dispatch } = context;

  return {
    state,
    dispatch,
  };
};

export { FrameUIProvider, useFrameUI };

import { useState, useEffect, useLayoutEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useRefDimensions(ref, isSvg = false) {
  const [dim, setDim] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });

  useLayoutEffect(() => {
    if (ref && ref.current) {
      if (isSvg) {
        const { height, width, x, y } = ref.current.getBBox();
        setDim({
          height,
          width,
          top: y,
          left: x,
        });
      } else {
        setDim({
          height: ref.current.offsetHeight,
          width: ref.current.offsetWidth,
          top: ref.current.offsetTop,
          left: ref.current.offsetLeft,
        });
      }
    }
  }, [ref, isSvg]);

  return dim;
}

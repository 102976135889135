import React, { useContext, useMemo } from 'react';
import useSWR from 'swr';
import { UserContext } from 'providers/UserProvider';
import { mmAPI } from 'services/Api';
import InventoryTable from 'components/Dashboard/InventoryTable/InventoryTable';
import { classNamesFromStatus } from 'components/VirtualAtelier/StyleSummary/StyleSummary';

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'STYLE NAME',
  },
  {
    header: 'STYLE NO',
  },
  {
    header: 'CATEGORIES',
  },
  {
    header: 'Line',
  },
  {
    header: 'Sizing',
  },
  {
    header: 'colour',
  },
  {
    header: 'Status',
  },
];

const AtelierInventoryTable = ({ listings, editTileOptions, ...props }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { brand_model_id } = props;

  const { data: styles } = useSWR(
    [`/api/style/query/brand`, idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, {
        brand,
      });
    },
    {
      suspense: true,
    }
  );

  const cellData = useMemo(
    () =>
      listings?.map(listing => {
        const status = styles?.find(s => s.key === listing.style)?.status;

        return {
          key: listing.key,
          col: [
            {
              imageUrl: listing.imageUrl,
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_number || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.category_names.filter(l => !!l).join(', ') || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.line_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.size || '',
              linkTo: listing.linkTo,
            },
            {
              cell:
                listing.color?.name ||
                listing.color?.hexCode ||
                listing.color?.hexCode ||
                listing.color?.pantone ||
                typeof listing.color === 'object'
                  ? JSON.stringify(listing.color)
                  : listing.color,
              linkTo: listing.linkTo,
            },
            {
              cell: <button className={classNamesFromStatus(status)}>{status}</button>,
              linkTo: listing.linkTo,
            },
          ],
          data: listing,
        };
      }),
    [listings, styles]
  );

  return (
    <InventoryTable
      headerGroups={headerGroups}
      cellData={cellData}
      popUpOptions={editTileOptions}
      {...props}
    />
  );
};

export default AtelierInventoryTable;

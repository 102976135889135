import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { navigate, useLocation } from '@reach/router';
import useSWR, { mutate } from 'swr';
import { mmAPI, listingAPI, apiDeleteProduct, apiDeleteProducts } from 'services/Api';
import {
  UserContext,
  UploadContext,
  ViewModeContext,
  NotificationsContext,
  PaginationContext,
} from 'providers/contexts';
import useSidebar from 'hooks/useSidebar';
import {
  useLocationIsAtelier,
  useLocationIsProducts,
  useLocationPageName,
} from 'hooks/useLocation';
import { VIEWMODE } from 'providers/ViewModeProvider';
import { ConfirmDialog, Pagination } from 'components';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import ProductInventoryTable from 'components/Dashboard/InventoryTable/ProductInventoryTable';
import AtelierInventoryTable from 'components/Dashboard/InventoryTable/AtelierInventoryTable';
import ProductsGridView from './ProductsGridView';
import ProductsLineView from './ProductsLineView';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { ProductDetailModal } from 'components/Dashboard/ProductDetail/ProductDetailModal';
import { isSuperset } from 'utils/isSuperset';
import { sortMediaPosition } from 'utils/sort';
import PlaceholderImg from 'assets/images/placeholder-image.png';

import './ProductsList.scss';

import MinusIcon from 'assets/icons/Icon-hr.svg';
import TrashIcon from 'assets/icons/trash.svg';

const ProductsFilterList = props => {
  const { brand_model_id, accessGranted } = props;
  const { user } = useContext(UserContext);
  const { state, dispatch } = useFrameUI();
  const { dropdownList } = state?.filterBar;

  const { setDisplayToast } = useContext(NotificationsContext);
  const { viewMode, setViewMode, setViewsList, setShowLabel, viewSettings } = useContext(
    ViewModeContext
  );

  const location = useLocation();

  const isAtelier = useLocationIsAtelier();
  const isProducts = useLocationIsProducts();
  const pageName = useLocationPageName();

  const queries = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return {
      search: query.get('search') || '',
      lines: query.get('lines'),
      collections: query.get('collections'),
      categories: query.get('categories'),
    };
  }, [location.search]);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const {
    productsListCurPage,
    setProductsListCurPage,
    productsListPerPage,
    setProductsListPerPage,
  } = useContext(PaginationContext);
  const { idToken, brand } = user;

  const [productsList, setProductsList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [curIndex, setCurIndex] = useState(0);
  const [curCount, setCurCount] = useState(0);

  useEffect(() => {
    setViewsList([VIEWMODE.TABLE, VIEWMODE.CARD, VIEWMODE.LARGE]);
    setShowLabel(false); // to show labels, set `true`
  }, []);

  useEffect(() => {
    if (viewSettings[pageName] && viewSettings[pageName] != viewMode) {
      setViewMode(viewSettings[pageName]);
    }
  }, [pageName]);

  const listingSWRConfig = {
    query: [
      '/api/listing/query/filter',
      idToken,
      queries.lines,
      queries.collections,
      queries.categories,
      queries.search,
      productsListCurPage,
      productsListPerPage,
    ],
    modelQuery: [`/api/brand/model/${brand_model_id}`, idToken],
  };

  const { data: listingData } = useSWR(
    idToken ? listingSWRConfig.query : null,
    (
      url,
      idToken,
      queryLines,
      queryCollections,
      queryCategories,
      querySearch,
      curPage,
      perPage
    ) => {
      if (!querySearch || querySearch == '') {
        return listingAPI(url, idToken, {
          brand: brand_model_id,
          lines: queryLines,
          collections: queryCollections,
          categories: queryCategories,
          pagination: true,
          cursor: curPage * perPage,
          limit: perPage,
        });
      }
      return listingAPI(url, idToken, {
        brand: brand_model_id,
        lines: queryLines,
        collections: queryCollections,
        categories: queryCategories,
        pagination: false,
      });
    },
    {
      suspense: queries.search ? false : true,
    }
  );

  const pages = [
    { title: brand.name, link: `/brand/${brand.key}` },
    { title: listingData?.data[0]?.collection_name, link: `/brand/${brand.key}/collections` },
    { title: 'Products' },
  ];

  const { data: modelData } = useSWR(
    idToken ? listingSWRConfig.modelQuery : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const listingConditions = useMemo(() => {
    const listingDataValues = listingData?.data ?? [];
    const conditionStores = listingDataValues.reduce(
      (stores, listing) => {
        const { lines, collections, categories } = stores;
        listing.category_names.forEach((c, i) => {
          return !categories[c] && (categories[c] = listing.categories[i]);
        });
        !lines[listing.line_name] && (lines[listing.line_name] = listing.line);
        !collections[listing.collection_name] &&
          (collections[listing.collection_name] = listing.collection);
        return { lines, collections, categories };
      },
      { lines: {}, collections: {}, categories: {} }
    );
    const listingFilters = {
      lines: Object.entries(conditionStores.lines).map(([c, key]) => ({ key, label: c })),
      collections: Object.entries(conditionStores.collections).map(([c, key]) => ({
        key,
        label: c,
      })),
      categories: Object.entries(conditionStores.categories).map(([c, key]) => ({
        key,
        label: c,
      })),
    };
    const oldFilters = {
      lines: dropdownList.find(item => item.key === 'lines')?.options || [],
      collections: dropdownList.find(item => item.key === 'collections')?.options || [],
      categories: dropdownList.find(item => item.key === 'categories')?.options || [],
    };
    const newFilters = {
      lines:
        oldFilters.lines.length != 0 && queries.lines?.length > 0
          ? oldFilters.lines
          : listingFilters.lines,
      collections:
        oldFilters.collections.length != 0 && queries.collections?.length > 0
          ? oldFilters.collections
          : listingFilters.collections,
      categories:
        oldFilters.categories.length != 0 && queries.categories?.length > 0
          ? oldFilters.categories
          : listingFilters.categories,
    };
    const [lines, collections, categories] = Object.entries(newFilters).map(
      ([, filters]) =>
        filters.map(item => ({
          ...item,
          isChecked: false,
        }))
    );
    return [
      { key: 'lines', label: 'Line', options: lines },
      { key: 'collections', label: 'Collection', options: collections },
      { key: 'categories', label: 'Categories', options: categories },
    ];
  }, [listingData?.data]);

  const productsListFromListingData = useCallback(
    async listings => {
      return await Promise.all(
        listings
          .filter(l => l.brand === parseInt(brand_model_id))
          .map(async listing => {
            const [firstImage] = Object.entries(listing.media).sort((a, b) =>
              sortMediaPosition(a[1], b[1])
            );
            let pathResized;
            if (firstImage && firstImage.length === 2) {
              const path = firstImage[1]?.assets?.image?.path;
              const pathResizeIndex = path.indexOf('.png');
              pathResized = [...path];
              pathResized.splice(pathResizeIndex, 0, '_300x400');
            }
            let firebaseImageUrl = null;
            try {
              if (firstImage) {
                firebaseImageUrl = await firebaseStorageUrl(pathResized.join(''));
              }
            } catch (e) {
              console.error('firebaseStorageUrl error', e);
            }
            try {
              if (firebaseImageUrl === null && firstImage) {
                firebaseImageUrl = await firebaseStorageUrl(
                  firstImage[1]?.assets?.image?.path
                );
              }
            } catch (e) {
              console.error('firebaseStorageUrl error', e);
            }
            if (!listing.style_name) console.error(listing);

            return {
              ...listing,
              linkTo: !isAtelier
                ? `/brand/${brand_model_id}/products/listing/${listing.key}`
                : `/brand/${brand_model_id}/style/${listing.style}`,
              imageUrl: firebaseImageUrl || PlaceholderImg,
            };
          })
      );
    },
    [brand_model_id, isAtelier]
  );

  useEffect(() => {
    const listingsEffect = async () => {
      if (!listingData) return;
      if (!queries.search || queries.search == '') {
        const { cursor, more, data: listings } = listingData;
        const data = await productsListFromListingData(listings);
        const curInd = cursor - productsListPerPage;
        const curCnt = data.length;
        const totalCnt = curInd + curCnt + more;

        setProductsListCurPage(parseInt(curInd / productsListPerPage));
        setCurIndex(curInd);
        setCurCount(curCnt);
        setTotalCount(totalCnt);
        setPageCount(Math.ceil(totalCnt / productsListPerPage));
        setProductsList(data);
      } else {
        const { data: listings } = listingData;
        const filteredData = listings.filter(p =>
          [
            p?.style_number,
            p?.style_name,
            p?.materials,
            p?.line_name,
            p?.collection_name,
          ].some(t => t.toLowerCase()?.indexOf(queries.search.toLowerCase()) >= 0)
        );
        const data = await productsListFromListingData(filteredData);
        setProductsList(data);
      }
    };
    listingsEffect();
  }, [listingData, productsListPerPage, queries.search, productsListFromListingData]);

  const onGotoPage = useCallback(page => {
    setProductsListCurPage(page);
  }, []);

  const onProductUpload = useCallback(() => {
    const uploadRoute = `/brand/${brand_model_id}/products/upload`;
    navigate(uploadRoute);
  }, [brand_model_id]);

  const popUpOptions = useMemo(
    () => [
      {
        selectItem: 'Product Upload',
        onClick: onProductUpload,
      },
    ],
    [onProductUpload]
  );

  const { onSidebarOpen } = useSidebar(idToken, { brand_model_id });

  useEffect(() => {
    if (isAtelier) {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: 'Virtual Atelier',
      });
    }

    return () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: null,
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const visibleVal = isSuperset(new Set(['owner']), new Set(accessGranted));
    if (visibleVal) {
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_INFOICON_VISIBLE,
        payload: false,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_INFOICON_HANDLER,
        payload: () => {
          onSidebarOpen(modelData, { jobType: 'designer' });
        },
      });
    }
  }, [accessGranted]);

  useEffect(() => {
    const frameUIActionsEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_SEARCH_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_VISIBLE,
        payload: true,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_DROPDOWNLIST_CONDITIONS,
        payload: listingConditions,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_INFOICON_VISIBLE,
        payload: true,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: popUpOptions,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_VIEWMODE_ICON_VISIBLE,
        payload: true,
      });

      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };

    return frameUIActionsEffect();
  }, [modelData, listingConditions]);

  const onViewFullDetails = useCallback(product => {
    navigate(product.linkTo);
  }, []);
  const onViewProduct = useCallback(product => {
    dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: false });
    navigate(product.linkTo);
  }, []);
  const onEditProduct = useCallback(product => {
    dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: false });
    navigate(product.linkTo + '?edit=true');
  }, []);
  const deleteProductActionHandler = useCallback(
    async data => {
      const { key: listing_key } = data;

      const response = await apiDeleteProduct(idToken, listing_key, {
        brand: brand_model_id,
      });
      if (response.data?.error) {
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else {
        mutate(listingSWRConfig.query);
        setDisplayToast({
          type: 'success',
          persist: false,
          message: `Product has been deleted successfully.`,
        });
      }
    },
    [idToken, listingSWRConfig.query, apiDeleteProduct]
  );

  const editTileOptions = useMemo(
    () => [
      {
        selectItem: 'Quick View',
        ModalComponent: <ProductDetailModal btnActionHandler={onViewFullDetails} />,
      },
      {
        selectItem: 'View',
        onClick: onViewProduct,
      },
      {
        selectItem: 'Edit',
        onClick: onEditProduct,
      },
      {
        selectItem: 'Delete',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Deleting product can’t be undone. Are you sure you want to continue delete the product ?'
            btnActionHandler={deleteProductActionHandler}
          />
        ),
      },
    ],
    [onViewFullDetails, onViewProduct, onEditProduct, deleteProductActionHandler]
  );

  const onChangePerPage = useCallback(per_page => {
    setProductsListPerPage(per_page);
    setProductsListCurPage(0);
  }, []);

  const [selectedProductKeys, setSelectedProductKeys] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedProductKeys([]);
  }, [productsList]);

  const { data: styles } = useSWR(
    [`/api/style/query/brand`, idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );
  useEffect(() => {
    console.log('styles', styles);
  }, [styles]);

  const handleToggle = useCallback(
    product_key => {
      if (selectedProductKeys.includes(product_key)) {
        setSelectedProductKeys(selectedProductKeys.filter(key => key !== product_key));
      } else {
        setSelectedProductKeys([...selectedProductKeys, product_key]);
      }
    },
    [selectedProductKeys]
  );
  const handleSelectAll = useCallback(() => {
    setSelectedProductKeys(productsList.map(product => product.key));
  }, [productsList]);
  const handleDeselectAll = useCallback(() => {
    setSelectedProductKeys([]);
  }, []);

  const deleteProductsActionHandler = useCallback(async () => {
    setOpen(false);

    const response = await apiDeleteProducts(idToken, selectedProductKeys);
    const successProducts = [],
      errorProducts = [];
    Object.keys(response.data.data).map(key => {
      const data = response.data.data[key];
      const product = productsList.find(p => p.key == key);
      const { style_name } = product;
      if (data.error) {
        errorProducts.push(style_name);
      } else {
        successProducts.push(style_name);
      }
    });

    if (errorProducts.length > 0) {
      setDisplayToast({
        type: 'error',
        message: `Error while deleting; ${errorProducts.join(', ')}`,
      });
    } else {
      setDisplayToast({
        type: 'success',
        message: `Products have been deleted successfully.`,
      });
    }
    mutate(listingSWRConfig.query);
  }, [idToken, selectedProductKeys, productsList, listingSWRConfig.query]);

  return (
    <div className='ProductsList'>
      <Breadcrumbs pages={pages} />

      {selectedProductKeys.length > 0 && (
        <div className='ProductsList__selection'>
          <img src={MinusIcon} alt='Unselect' onClick={handleDeselectAll} />
          {selectedProductKeys.length} of {productsList.length} items selected
          <span className='nunito-bold color-white ml-2' onClick={handleSelectAll}>
            Select all
          </span>
          {selectedProductKeys.length > 0 && (
            <img
              src={TrashIcon}
              alt='Delete'
              className='delete-products'
              onClick={() => setOpen(true)}
            />
          )}
        </div>
      )}

      {(!viewSettings.atelier || viewSettings.atelier === VIEWMODE.TABLE.key) &&
        isAtelier && (
          <AtelierInventoryTable
            listings={productsList}
            editTileOptions={editTileOptions}
            selectedProductKeys={selectedProductKeys}
            placeholder='No listings are available'
            handleToggle={handleToggle}
            handleSelectAll={handleSelectAll}
            handleDeselectAll={handleDeselectAll}
            {...props}
          />
        )}

      {viewSettings.products === VIEWMODE.TABLE.key && isProducts && (
        <ProductInventoryTable
          listings={productsList}
          styles={styles}
          editTileOptions={editTileOptions}
          selectedProductKeys={selectedProductKeys}
          placeholder='No listings are available'
          handleToggle={handleToggle}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
        />
      )}

      {(viewSettings[isAtelier ? 'atelier' : 'products'] === VIEWMODE.CARD.key ||
        viewSettings[isAtelier ? 'atelier' : 'products'] === VIEWMODE.LARGE.key) && (
        <ProductsGridView
          size={
            viewSettings[isAtelier ? 'atelier' : 'products'] === VIEWMODE.LARGE.key
              ? 'lg'
              : 'md'
          }
          listings={productsList}
          editTileOptions={editTileOptions}
          selectedProductKeys={selectedProductKeys}
          placeholder='No listings are available'
          handleToggle={handleToggle}
        />
      )}
      {totalCount > 0 && (!queries.search || queries.search === '') && (
        <div className='mt-4'>
          <Pagination
            pageCount={pageCount}
            curPage={productsListCurPage}
            onGotoPage={onGotoPage}
            perPage={productsListPerPage}
            curIndex={curIndex}
            curCount={curCount}
            totalCount={totalCount}
            onChangePerPage={onChangePerPage}
          />
        </div>
      )}
      {queries.search && (
        <div className='mt-4'>
          <div className='pagination__detail-text'>
            Showing {productsList.length} of {totalCount} items.
          </div>
        </div>
      )}

      {open && (
        <ConfirmDialog
          dialogBody='Deleting products can’t be undone. Are you sure you wan’t to continue delete selected products ?'
          btnActionHandler={deleteProductsActionHandler}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default ProductsFilterList;

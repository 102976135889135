import React, { useMemo } from 'react';
import map from 'lodash.map';
import InventoryTable from 'components/Dashboard/InventoryTable/InventoryTable';
import { classNamesFromStatus } from 'components/VirtualAtelier/StyleSummary/StyleSummary';

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'SKU',
  },

  {
    header: 'STYLE NAME',
  },
  {
    header: 'STYLE NO',
  },
  {
    header: 'CATEGORIES',
  },
  {
    header: 'Line',
  },
  {
    header: 'Sizing',
  },
  {
    header: 'colour',
  },
  {
    header: 'Status',
  },
];

const ProductInventoryTable = ({ listings, styles, editTileOptions, ...otherProps }) => {
  const cellData = useMemo(
    () =>
      map(listings, listing => {
        const status = styles?.find(s => parseInt(s.key) === parseInt(listing.style))
          ?.status;
        return {
          key: listing.key,
          col: [
            {
              imageUrl: listing.imageUrl,
              linkTo: listing.linkTo,
            },
            {
              cell: listing.sku || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_number || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.category_names.filter(l => !!l).join(', ') || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.line_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.size || '',
              linkTo: listing.linkTo,
            },
            {
              cell:
                listing.color?.name ||
                listing.color?.hexCode ||
                listing.color?.hexCode ||
                listing.color?.pantone ||
                typeof listing.color === 'object'
                  ? JSON.stringify(listing.color)
                  : listing.color,
              linkTo: listing.linkTo,
            },
            {
              cell: (
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ marginRight: '15px' }}
                    className={classNamesFromStatus(status)}
                  >
                    {status}
                  </button>
                  {listing.addition && (
                    <div>
                      <button className='button-badge color-white-3-bg border'>a</button>
                    </div>
                  )}
                </div>
              ),
              linkTo: listing.linkTo,
            },
          ],
          data: listing,
        };
      }),
    [listings, styles]
  );

  return (
    <InventoryTable
      headerGroups={headerGroups}
      cellData={cellData}
      popUpOptions={editTileOptions}
      {...otherProps}
    />
  );
};

export default ProductInventoryTable;

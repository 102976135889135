import React from 'react';
import { CSSTransition } from 'react-transition-group';
import useClickAway from 'hooks/useClickAway';
import './ProductDetailModal.scss';

import CloseIcon from 'assets/icons/close.svg';
import { classNamesFromStatus } from 'components/VirtualAtelier/StyleSummary/StyleSummary';

type ProductDetailModalProps = {
  data: any;
  btnActionHandler?: (values) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProductDetailModal = (props: ProductDetailModalProps) => {
  const { data, btnActionHandler, open, setOpen } = props;

  const node = React.useRef();
  useClickAway(node, setOpen);

  const onViewFullDetails = () => {
    setOpen(false);
    btnActionHandler?.(data);
  };

  return (
    <>
      <CSSTransition
        in={open}
        classNames='modal product-detail-modal'
        timeout={300}
        unmountOnExit
        onExited={() => setOpen(false)}
      >
        <div className='modal modal--regular modal--rounded'>
          <div className='modal__content' ref={node}>
            <div className='modal__header'>
              <h2 className='modal__title'>Product Detail</h2>
              <img src={CloseIcon} alt='Close' onClick={() => setOpen(!open)} />
            </div>
            <div className='modal__inner' style={{ backgroundColor: '#fff' }}>
              <div className='row product'>
                <div className='col-5'>
                  <img className='product__image' src={data.imageUrl} alt='Product' />
                </div>
                <div className='col-7'>
                  <div className='row'>
                    <div className='col-12 product__title'>
                      <label>Style Name</label>
                      <div>{data.style_name}</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='product__property'>
                        <label>Garment / </label>
                        <span>{data.category_names.filter(l => !!l).join(', ')}</span>
                      </div>
                      <div className='product__property'>
                        <label>Department / </label>
                        <span>{data.department}</span>
                      </div>
                      <div className='product__property'>
                        <label>Sizing / </label>
                        <span>{data.size}</span>
                      </div>
                      <div className='product__property'>
                        <label>Style No / </label>
                        <span>{data.style_number}</span>
                      </div>
                      <div className='product__property'>
                        <label>Color / </label>
                        <span>
                          {data.color.name}/{data.color.code}
                        </span>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='product__property'>
                        <label>Sku / </label>
                        <span>{data.sku}</span>
                      </div>
                      <div className='product__property'>
                        <label>Units / </label>
                        <span>{data.units}</span>
                      </div>
                      <div className='product__property'>
                        <label>Status</label>
                        <button className={classNamesFromStatus(data.status)}>
                          {data.status}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal__footer'>
              <button
                className='button-transparent-gray button-smaller'
                onClick={onViewFullDetails}
              >
                View Full Details
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import './CreateTemporaryPasswordModal.scss';
import firebase from 'firebase';
import { NotificationsContext } from 'providers/NotificationsProvider';

type EnableMfaAuthModalProps = {
  btnActionHandler?: (values) => void;
  formId?: string;
  stepTitle?: string;
  mfaSteps?: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EnableMfaAuthModal = (props: EnableMfaAuthModalProps) => {
  const { setDisplayToast } = useContext(NotificationsContext);
  const recaptchaRef = React.useRef(null);
  const { formId, btnActionHandler, open, setOpen } = props;
  const { handleSubmit, register } = useForm();
  const [step, setStep] = useState<number>(1);
  const [verificationPhone, setPhoneVerified] = useState('');

  const [isRecaptchaPassed, setIsRecaptchaPassed] = useState(false);
  const [resolverSession, setResolverSession] = useState(null);
  const [mfaVerificationId, setMfaVerificationId] = useState(null);


  const onSubmit = async values => {
    const auth = firebase.auth();
    if (step === 1) {
      auth.currentUser
        .reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            values.password
          )
        )
        .then(() => {
          setStep(2);
        })
        .catch(async error => {
          if (error.code === 'auth/multi-factor-auth-required') {
            if (!isRecaptchaPassed) {
              await (window as any).recaptchaVerifier.verify();
            }
            try {
              setResolverSession(error.resolver);
              const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
              const phoneInfoOptions = {
                multiFactorHint: error.resolver.hints[0],
                session: error.resolver.session,
              };

              const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                (window as any).recaptchaVerifier
              );
              setMfaVerificationId(verificationId);
              setStep(2);
            } catch (error) {
              setDisplayToast({ type: 'error', message: error.message });
            }
          } else {
            setDisplayToast({ type: 'error', message: error.message });
          }
        });
    } else if (step === 2) {
      setPhoneVerified(values.phone_number);
      const phoneNo = values.phone_number;
      if (!isRecaptchaPassed) {
        await (window as any).recaptchaVerifier.verify();
        return;
      }
      try {
        const auth = firebase.auth();
        const user = auth.currentUser;
        const session = await user.multiFactor.getSession();

        const phoneOpts = {
          phoneNumber: phoneNo,
          session,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneOpts,
          (window as any).recaptchaVerifier
        );
        setMfaVerificationId(verificationId);
        setStep(3);
      } catch (error) {
        if (error.code === 'auth/unverified-email') {
          const auth = firebase.auth();
          const user = auth.currentUser;
          user.sendEmailVerification();
          setDisplayToast({
            type: 'error',
            message: error.message + ' Please check your account for email verification',
          });
        } else if (error.code === 'auth/requires-recent-login') {
          console.log('error.code', error, error.code);
          setStep(1);
        } else {
          setDisplayToast({
            type: 'error',
            message: error.message,
          });
        }
        return Promise.reject(error);
      }
    } else if (step === 3) {
      try {
        const cred = firebase.auth.PhoneAuthProvider.credential(
          mfaVerificationId,
          values.verification_code
        );
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );
        const auth = firebase.auth();
        const user = auth.currentUser;
        await user.multiFactor.enroll(multiFactorAssertion, 'phone number');
        setDisplayToast({ type: 'success', message: 'Enrollment Successful' });
        window.location.reload();
      } catch (error) {
        setDisplayToast({ type: 'error', message: error.message });
      }
    }
  };

  useEffect(() => {
    setStep(1);
  }, [open]);

  useEffect(() => {
    const recaptchaRenderEffect = () => {
      try {
        (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          recaptchaRef.current,
          {
            size: 'normal',
            callback: response => {
              setIsRecaptchaPassed(true);
            },
            'expired-callback': () => {
              setIsRecaptchaPassed(false);
            },
          }
        );
        (window as any).recaptchaVerifier.render().then(widgetId => {
          (window as any).recaptchaWidgetId = widgetId;
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (
      props.stepTitle === props.mfaSteps.AUTHENTICATE ||
      props.stepTitle === props.mfaSteps.PHONE_NUMBER ||
      props.stepTitle === props.mfaSteps.LIST_FACTORS ||
      props.stepTitle === props.mfaSteps.INFO
    ) {
      recaptchaRenderEffect();
    }

    return () => {
      if (
        props.stepTitle === props.mfaSteps.AUTHENTICATE ||
        props.stepTitle === props.mfaSteps.PHONE_NUMBER ||
        props.stepTitle === props.mfaSteps.LIST_FACTORS ||
        props.stepTitle === props.mfaSteps.INFO
      ) {
        (window as any).recaptchaVerifier.clear();
      }
    };
  }, [props.mfaSteps.AUTHENTICATE, props.mfaSteps.INFO, props.mfaSteps.LIST_FACTORS, props.mfaSteps.PHONE_NUMBER, props.stepTitle]);

  return (
    <Modal
      modalHeading='Enable Two-Factor Auth'
      modalBtnClose='cancel'
      modalBtnActionLabel={step === 3 ? 'verify' : 'continue'}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId={formId}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div className='control-block'>
          <div className='user-information d-flex justify-content-between text-uppercase'>
            <span>User information</span>
            <span>Step {step} of 3</span>
          </div>
          {step === 1 && (
            <div>
              <p
                style={{
                  fontSize: '16px',
                  color: '#999A9A',
                  fontFamily: 'Nunito',
                  marginTop: '68px',
                  marginBottom: '24px',
                }}
              >
                Start by entering your password so that we know it’s you. Then we’ll walk
                you through two more simple steps
              </p>
              <label className='control--label control--required'>Password</label>
              <input
                ref={register}
                name='password'
                required
                className='control control--text'
                type='password'
                placeholder='password'
              />
            </div>
          )}
          {step === 2 && (
            <div>
              <p
                style={{
                  fontSize: '16px',
                  color: '#999A9A',
                  fontFamily: 'Nunito',
                  marginTop: '68px',
                  marginBottom: '24px',
                }}
              >
                Now add your phone number
              </p>
              <label className='control--label'>Phone Number</label>
              <input
                name='phone_number'
                className='control control--full-width control--text'
                type='text'
                ref={register}
              />
            </div>
          )}
          {step === 3 && (
            <div>
              <p
                style={{
                  fontSize: '16px',
                  color: '#999A9A',
                  fontFamily: 'Nunito',
                  marginTop: '68px',
                  marginBottom: '24px',
                }}
              >
                We sent a verification code to the number {verificationPhone}
              </p>
              <label className='control--label'>verification code</label>
              <input
                name='verification_code'
                className='control control--full-width control--text'
                type='text'
                ref={register}
              />
            </div>
          )}
        </div>
        <div id='recaptcha-container' ref={recaptchaRef} style={{ display: step !== 2 ? "none" : "" }} />
      </form>
    </Modal>
  );
};

import React, { useContext, useState, useReducer, useEffect } from 'react';
import './BrandCustomization.scss';
import TemplateModalImg from 'assets/images/template_modal.png';
import TemplateGridImg from 'assets/images/template_grid.png';
import BrandSidebar from './BrandSidebar';
import ControlsBar from '../ControlsBar/ControlsBar';
import { UserContext, NotificationsContext } from 'providers/contexts';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import { getBrandModel, updateBrand } from 'services/Api';
import LoginScreen from 'components/Authentication/Login/LoginScreen/LoginScreen';
import ModalTemplate from 'components/ModalTemplate/ModalTemplate';
import ImageGrid from 'components/ImageGrid/ImageGrid';
import TemplateSelection from './TemplateSelection';

import MMLogoBlack from 'assets/images/modern-mirror-logo-black.svg';
import MenuIcon from 'components/Common/Frame/MenuIcon';

export interface BrandCustomizationProps {}

const initialState = {
  panelBackground: { id: 'panelBackground', open: false, color: '' },
  panelFont: { id: 'panelFont', open: false, color: '' },
  loginFont: { id: 'loginFont', open: false, color: '' },
  loginBackground: { id: 'loginBackground', open: false, color: '' },
  editTemplate: false,
  customize: false,
  tab: 'Landing Page',
  template: '',
  logo: null,
  bgImage: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'setOpen':
      return {
        ...state,
        [action.id]: { ...state[action.id], open: action.open },
      };
    case 'setColor':
      return {
        ...state,
        [action.id]: { ...state[action.id], color: action.color },
      };
    case 'setEditTemplate':
      return {
        ...state,
        editTemplate: action.payload,
      };
    case 'setTemplate':
      return {
        ...state,
        template: action.payload,
      };
    case 'setCustomize':
      return {
        ...state,
        customize: action.payload,
      };
    case 'setTab':
      return {
        ...state,
        tab: action.payload,
      };
    case 'setLogo':
      return {
        ...state,
        logo: action.payload,
      };
    case 'setBgImage':
      return {
        ...state,
        bgImage: action.payload,
      };
    default:
      throw new Error();
  }
}

const BrandCustomization: React.FC<BrandCustomizationProps> = () => {
  const [saveChanges, setSaveChanges] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { tab, logo, bgImage } = state;
  const {
    user: { brand, idToken },
    setUser,
  } = useContext(UserContext);
  const { setDisplayToast } = useContext(NotificationsContext);

  const submitChanges = async edit => {
    setSaveChanges(edit);
    const template = {
      login: {
        backgroundColor: state?.loginBackground?.color,
        fontColor: state?.loginFont?.color,
      },
      landing: {
        template: state?.template,
        backgroundColor: state?.panelBackground?.color,
        fontColor: state?.panelFont?.color,
      },
    };
    const response = await updateBrand(idToken, {
      brandKey: brand?.key,
      template,
    });
    if (response.data?.error) {
      setDisplayToast({ type: 'error', message: response.data?.error?.message });
    } else {
      setUser(prevUser => ({
        ...prevUser,
        ...response?.data?.data,
      }));
      setDisplayToast({ type: 'success', message: 'Change saved successfully' });
      onUploadHandler();
    }
  };

  const onUploadHandler = async () => {
    const brandSync = await getBrandModel(idToken, { key: brand?.key });
    setUser(prevUser => ({
      ...prevUser,
      brand: brandSync.data?.data,
    }));
  };

  const [dashboardNavItems, setDashboardNavItems] = React.useState([
    { title: 'Landing Page', activeBtn: false },
    { title: 'Login', activeBtn: false },
  ]);

  const setColor = ({ id, color }) => {
      dispatch({
        type: 'setColor',
        id,
        color,
      });
  };

  const setTemplate = ({ template }) => {
    template && dispatch({ type: 'setTemplate', template });
  };

  useEffect(() => {
    setDashboardNavItems(dashboardNavItems => [
      { title: 'Landing Page', activeBtn: tab === dashboardNavItems[0].title },
      { title: 'Login', activeBtn: tab === dashboardNavItems[1].title },
    ]);
  }, [tab]);

  const setColors = () => {
      setColor({
        id: initialState.loginBackground.id,
        color: brand?.template?.login?.backgroundColor,
      });
      setColor({
        id: initialState.loginFont.id,
        color: brand?.template?.login?.fontColor,
      });
      setColor({
        id: initialState.panelBackground.id,
        color: brand?.template?.landing?.backgroundColor,
      });
      setColor({
        id: initialState.panelFont.id,
        color: brand?.template?.landing?.fontColor,
      });
      setTemplate({ template: brand?.template?.landing?.template });
    }

  useEffect(() => {
    setColors()
  }, [brand?.template]);

  useEffect(() => {
    if (cancelChanges) {
      setColors()
      setCancelChanges(false)
    }
  }, [cancelChanges]);


  useEffect(() => {
    dispatch({ type: 'setLogo', payload: brand?.assets?.logo });
  }, [brand?.assets?.logo]);

  useEffect(() => {
    dispatch({ type: 'setBgImage', payload: brand?.assets?.hero });
  }, [brand?.assets?.hero]);

  const gridTemplate = state.template === 'gridtemplate';

  const getGridImages = () => {
    const assets = brand?.assets;
    return {
      image_2: assets?.image_2,
      image_3: assets?.image_3,
      image_4: assets?.image_4,
      image_5: assets?.image_5,
      image_6: assets?.image_6,
      image_7: assets?.image_7,
    };
  };

  const onColorChange = (id, color) => {
    setColor({
      id,
      color: color.hex,
    });
    onClickColorPicker(id);
  };

  const toggleColorPicker = (id, open) => {
    dispatch({ type: 'setEditTemplate', payload: false });
    dispatch({ type: 'setOpen', id: id, open: open });
    if (open) {
      onClickColorPicker(id);
    }
  };

  const onClickColorPicker = id => {
    dispatch({ type: 'setCustomize', payload: true });
    if (
      [state.panelBackground.id, state.panelFont.id].includes(id) &&
      tab !== dashboardNavItems[0].title
    ) {
      dispatch({ type: 'setTab', payload: dashboardNavItems[0].title });
    } else if (
      [state.loginBackground.id, state.loginFont.id].includes(id) &&
      tab !== dashboardNavItems[1].title
    ) {
      dispatch({ type: 'setTab', payload: dashboardNavItems[1].title });
    }
  };

  return (
    <div className='brand-customization'>
      <div style={{ display: 'flex' }}>
        <BrandSidebar
          brand={brand}
          saveChanges={saveChanges}
          state={state}
          onUploadHandler={onUploadHandler}
          onColorChange={onColorChange}
          toggleColorPicker={toggleColorPicker}
          setEditTemplate={(display = true) =>
            dispatch({ type: 'setEditTemplate', payload: display })
          }
          handleUploadButtonClicked={({ logoFile, bgImageFile }) => {
            dispatch({ type: 'setEditTemplate', payload: false });
            dispatch({ type: 'setTab', payload: 'Login' });
            dispatch({ type: 'setCustomize', payload: true });
            if (logoFile) {
              logo.urls.download = URL.createObjectURL(logoFile);
              dispatch({
                type: 'setLogo',
                payload: logo,
              });
            }
            if (bgImageFile) {
              dispatch({
                type: 'setBgImage',
                payload: URL.createObjectURL(bgImageFile),
              });
            }
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <ControlsBar submitChanges={submitChanges} dispatch={dispatch} state={state} setCancelChanges={setCancelChanges} />
          <div className='brand-content__background-color'>
            {state.editTemplate ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  marginTop: '12%',
                }}
              >
                <TemplateSelection
                  id='modaltemplate'
                  templateImg={TemplateModalImg}
                  selectedTemplate={!gridTemplate}
                  dispatch={dispatch}
                />
                <TemplateSelection
                  id='gridtemplate'
                  templateImg={TemplateGridImg}
                  selectedTemplate={gridTemplate}
                  dispatch={dispatch}
                />
              </div>
            ) : (
              <div className='brand-content__content-background' style={{marginBottom: 0}}>
                <DashboardNav
                  dashboardNavItems={dashboardNavItems}
                  darkMode={false}
                  changeTab={t => dispatch({ type: 'setTab', payload: t })}
                />
                {tab === dashboardNavItems[1].title ? (
                  <LoginScreen
                    logo={logo}
                    bgImage={bgImage}
                    title='Login'
                    centered={false}
                    lightColor={state.loginBackground.color}
                    darkColor={state.loginFont.color}
                    logoPublic={false}
                  />
                ) : (
                  <div
                    className='brand-content__content'
                    style={{ background: state.panelBackground.color }}
                  >
                    <div style={{ backgroundColor: 'white' }}>
                      {gridTemplate ? (
                        <ImageGrid
                          images={getGridImages()}
                          aspectRatio='1-1'
                          saveImages={saveChanges}
                          onUploadHandler={onUploadHandler}
                          fontColor={state.panelFont.color}
                        />
                      ) : (
                        <div
                          className='brand-content__preview'
                          style={{ background: state.panelBackground.color }}
                        >
                          <div className='brand-content__menu'>
                            <MenuIcon
                              fontColor={state.panelFont.color}
                              openSidebar={false}
                            />
                          </div>
                          <ModalTemplate
                            brand={brand}
                            saveImages={saveChanges}
                            onUploadHandler={onUploadHandler}
                            edit={state.customize}
                            logo={MMLogoBlack}
                            fontColor={state.panelFont.color}
                            handleUploadButtonClicked={() =>
                              dispatch({ type: 'setCustomize', payload: true })
                            }
                          />
                          <div></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCustomization;

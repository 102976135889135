/* eslint-disable no-undef */
import React, { useEffect, useContext, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocationSearchParam } from 'hooks/useLocation';
import { UserContext, NotificationsContext } from 'providers/contexts';
import { inviteMethodParse, postRecaptchaMethod } from 'services/Api';
import { ERRORS } from '../constants';
import { LoadingAlert } from 'components';
import useSWR from 'swr';
import LoginScreen from 'components/Authentication/Login/LoginScreen/LoginScreen';
import logo from 'assets/images/mm_logo_final_black.png';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import firebase from 'firebase/app';
import LogRocket from 'logrocket';

const bgImage =
  'https://images.unsplash.com/photo-1536891259032-26d57921dc41?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80';

const SIGNIN_PROCESS = {
  LOADING: 'loading',
  NORMAL: 'normal',
  INVITED: 'invited',
  MFA: 'mfa',
};

const SIGNIN_MESSAGES = {
  AccountNotFound: 'Account does not exist',
  EMAIL_NOT_FOUND: 'Email does not exist',
  INVALID_PASSWORD: 'Wrong password',
  TOO_MANY_ATTEMPTS_RESET_PASSWORD:
    'Too many failed login attempts. Reset your password.',
  ERROR: ERRORS.AUTH_MM_API_LOGIN_FAILED,
  SUCCESS: 'Login success',
};

const MFA_STEP = {
  LIST: 'list_mfa',
  VERIFICATION_CODE: 'verification_code',
};

const SignIn = () => {
  const { user, setUser } = useContext(UserContext);
  const { idToken } = user;
  const recaptchaRef = React.useRef(null);
  const [isRecaptchaPassed, setIsRecaptchaPassed] = useState(false);
  const inviteTokenParam = useLocationSearchParam('token');

  const { setDisplayToast } = useContext(NotificationsContext);

  const [signInProcess, setSignInProcess] = useState(
    inviteTokenParam ? SIGNIN_PROCESS.LOADING : SIGNIN_PROCESS.NORMAL
  );
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [messageEmail, setMessageEmail] = useState('');
  const [messagePassword, setMessagePassword] = useState('');

  const [mfaVerificationId, setMfaVerificationId] = useState(null);
  const [mfaStep, setMfaStep] = useState(MFA_STEP.LIST);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [mfaError, setMfaError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [customToken, setCustomToken] = useState('');
  const [selectedHint, setSelectedHint] = useState(null);
  const [selectedPhone, setPhoneVerified] = useState(null);
  const [resolverSession, setResolverSession] = useState(null);
  const [enrolledFactors, setEnrolledFactors] = useState([]);
  const [disableSelection, setDisableSelection] = useState(false);

  const { data: invite } = useSWR(
    inviteTokenParam ? [inviteTokenParam] : null,
    token => {
      return inviteMethodParse(token);
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    const inviteEffect = () => {
      if (!invite) {
        setSignInProcess(SIGNIN_PROCESS.NORMAL);
        return;
      }

      const { account, status } = invite;
      if (!account) {
        navigate(`/invite?token=${inviteTokenParam}`);
        return;
      }

      const { email, data: inviteData } = invite;
      console.log('inviteData', inviteData);
      //#TODO parse out team name from grants
      const brand = inviteData?.find(model => model.KIND === 'Brand');
      const team = inviteData?.find(model => model.KIND === 'Team');
      if (!user?.account) {
        setDisplayToast({
          persist: true,
          type: 'info',
          message: `${email} has been invited to access ${brand && brand.name}`,
        });
        LogRocket.captureMessage(`Invite flow started for ${email}`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'SignIn',
            step: 'inviteEffect',
          },
          extra: {
            // additional arbitrary data associated with the event
            invite,
            message: `${email} has been invited to access ${brand && brand.name}`,
            brand: brand.key,
            brandName: brand.name,
          },
        });
        setUser(prevUser => ({
          ...prevUser,
          invite,
        }));
        setSignInProcess(SIGNIN_PROCESS.INVITED);
        console.log('SIGNIN_PROCESS.INVITED', SIGNIN_PROCESS.INVITED);
        navigate('/');
      }
    };
    inviteEffect(invite);
  }, [invite]);

  useEffect(() => {
    const recaptchaRenderEffect = () => {
      try {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          recaptchaRef.current,
          {
            size: 'normal',
            callback: response => {
              setIsRecaptchaPassed(true);
            },
            'expired-callback': () => {
              setIsRecaptchaPassed(false);
            },
          }
        );
        window.recaptchaVerifier.render().then(widgetId => {
          window.recaptchaWidgetId = widgetId;
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (signInProcess === SIGNIN_PROCESS.MFA) {
      recaptchaRenderEffect();
    }

    return () => {
      if (signInProcess === SIGNIN_PROCESS.MFA) {
        window.recaptchaVerifier.clear();
      }
    };
  }, [signInProcess]);

  const signInMMAPIWithEmailAndPasswordHandler = (email, password) => {
    try {
      const auth = firebase.auth();
      auth
        .signInWithEmailAndPassword(email, password)
        .then(userCredential => {
          navigate('/');
        })
        .catch(error => {
          if (error.code === 'auth/multi-factor-auth-required') {
            try {
              setResolverSession(error.resolver);
              setEnrolledFactors(error.resolver.hints);
              setSignInProcess(SIGNIN_PROCESS.MFA);
            } catch (error) {
              setDisplayToast({ type: 'error', message: error.message });
            }
          } else if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
            setDisplayToast({ type: 'error', message: 'Invalid email or password' });
          } else if (error.code === 'auth/too-many-requests') {
            setDisplayToast({ type: 'error', message: error.message });
          } else {
            setDisplayToast({ type: 'error', message: "Something went wrong, please contact support" });
          }
        });
    } catch (error) {
      let message;
      if (
        error?.message?.includes('TOO_MANY_ATTEMPTS_TRY_LATER') &&
        error.type === 'FirebaseError'
      ) {
        message = SIGNIN_MESSAGES.TOO_MANY_ATTEMPTS_RESET_PASSWORD;
        navigate('/password-reset');
      } else {
        message =
          SIGNIN_MESSAGES[error.message] || error.message || SIGNIN_MESSAGES.ERROR;
      }
      setDisplayToast({ type: 'error', message });
    }
  };

  const verifyMfaCode = async verificationCode => {
    if (!isRecaptchaPassed) {
      return;
    }
    if (verificationCode === '') {
      setDisplayToast({ type: 'error', message: 'Verification code empty!' });
      return;
    }

    setMfaError('');
    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        mfaVerificationId,
        verificationCode
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );
      setSignInProcess(SIGNIN_PROCESS.LOADING);
      await resolverSession
        .resolveSignIn(multiFactorAssertion)
        .then(userCredential => {
          setDisplayToast({ type: 'success', message: SIGNIN_MESSAGES.SUCCESS });
        })
        .catch(error => {
          setDisplayToast({ type: 'error', message: error.message });
        });
    } catch (error) {
      setMfaError(error);
      setDisplayToast({ type: 'error', message: error.message });
    }
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      email: user.invite?.email,
    },
  });
  const registrationHandler = ({ email, password }) => {
    if (!isRecaptchaPassed) {
      return;
    }
    /** Triggers MM API identity request */

    signInMMAPIWithEmailAndPasswordHandler(email, password);
  };

  const validateEmail = event => {
    const email = event.target.value;
    if (email === '') {
      setIsValidPassword(false);
      setMessagePassword('Email is required!');
    }
  };
  const choseFactor = async factor => {
    setSelectedHint(factor);
    setPhoneVerified(factor.phoneNumber);
    try {
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const phoneInfoOptions = {
        multiFactorHint: factor,
        session: resolverSession.session,
      };
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );
      setMfaVerificationId(verificationId);
      setMfaStep(MFA_STEP.VERIFICATION_CODE);
      setDisableSelection(true);
    } catch (error) {
      setDisplayToast({ type: 'error', message: error.message });
    }
  };

  const handleRecaptcha = async value => {
    try {
      const verify = await postRecaptchaMethod(idToken, {
        captcha_value: value,
      });
      setIsRecaptchaPassed(verify.data.data.success);
      if (!verify.data.data.success) {
        setDisplayToast({ type: 'error', message: 'Verification Unsuccessful' });
      }
    } catch (error) {
      setIsRecaptchaPassed(false);
    }
  };

  const renderLoginForm = () => (
    <>
      <div
        className={!isValidEmail ? 'control-block control-block--error' : 'control-block'}
      >
        <label className='control--label'>{`Email`}</label>
        <input
          name={`email`}
          placeholder={invite?.email}
          defaultValue={invite?.email}
          className='control control--full-width control--text'
          type='text'
          onChange={validateEmail}
          ref={register({
            required: 'Email is required.',
          })}
        />
      </div>
      {!isValidEmail && <span className='error-text'>{messageEmail}</span>}

      <div
        className={
          !isValidPassword ? 'control-block control-block--error' : 'control-block'
        }
      >
        <label className='control--label'>{`Password`}</label>
        <input
          name={`password`}
          placeholder={invite?.password}
          defaultValue={invite?.password}
          className='control control--full-width control--text'
          type='password'
          ref={register({
            required: 'Password is required.',
          })}
        />
      </div>
      {errors.password && <span className='error-text'>{errors.password.message}</span>}

      {!isValidPassword && <span className='error-text'>{messagePassword}</span>}
      <div className='pt-2 pb-2'>
        <Checkboxes label='Remember me' />
      </div>
      <button className='button-primary' type='submit'>
        Sign In
      </button>
      <div className='login-form__links'>
        <Link to='/password-reset' className='login-form__link'>
          Forgot password?
        </Link>
      </div>
    </>
  );

  const renderMfaForm = () => (
    <>
      {mfaStep === MFA_STEP.LIST && (
        <>
          <div className='block'>
            <label className='control--label'>Chose Two-step authentication Factor</label>{' '}
            <div className='mt-2 mb-2'>
              {enrolledFactors.map(factor => (
                <div key={factor.uid}>
                  <label className='inline-flex items-center'>
                    <input
                      onChange={e => {
                        choseFactor(factor);
                      }}
                      type='radio'
                      name='factorGroup'
                      value={factor.uid}
                    />
                    <span className='ml-2'>{factor.phoneNumber}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {mfaStep === MFA_STEP.VERIFICATION_CODE && (
        <>
          <div className='control-block'>
            <label className='control--label'>Enter code sent to {selectedPhone}</label>
            <input
              name='verify-code'
              value={mfaCode}
              onChange={e => setMfaCode(e.target.value)}
              className='control control--full-width control--text'
              type='text'
            />
          </div>
          <button
            className='button-primary'
            type='button'
            onClick={e => {
              e.preventDefault();
              verifyMfaCode(mfaCode);
            }}
          >
            Verify code
          </button>
        </>
      )}
      {mfaError && <span className='error-text'>{mfaError}</span>}
    </>
  );

  const renderSignInForm = () => (
    <>
      {signInProcess !== SIGNIN_PROCESS.MFA ? renderLoginForm() : renderMfaForm()}
      {signInProcess !== SIGNIN_PROCESS.MFA ? (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_recaptchaKey}
          onChange={handleRecaptcha}
        />
      ) : (
        <div id='recaptcha-container' ref={recaptchaRef} />
      )}
    </>
  );

  return (
    <>
      {signInProcess === SIGNIN_PROCESS.LOADING && (
        <>
          <LoadingAlert />
          <div style={{ display: 'none' }} id='recaptcha-container' ref={recaptchaRef} />
        </>
      )}
      {(signInProcess === SIGNIN_PROCESS.NORMAL ||
        signInProcess === SIGNIN_PROCESS.INVITED ||
        signInProcess === SIGNIN_PROCESS.MFA) && (
        <LoginScreen
          bgImage={invite?.data[0]?.assets.hero || bgImage}
          logo={invite?.data[0]?.assets.logo || logo}
          title='Login'
          centered={signInProcess === SIGNIN_PROCESS.INVITED ? false : true}
          forgotPasswordLink='/password-reset'
          onSubmit={handleSubmit(registrationHandler)}
        >
          {renderSignInForm()}
        </LoginScreen>
      )}
    </>
  );
};

export default SignIn;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import useClickAway from 'hooks/useClickAway';
import TextBox from 'components/Common/TextBox/TextBox';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import CloseIcon from 'assets/icons/close.svg';
import './GroupChatModal.scss';
import { IGroupChatContact} from 'components/Common/LiveChat/ChatGroups';

export interface IGroupChatModal {
  backgroundColor?: string;
  modalHeading: string;
  modalSubheading?: string;
  modalBtnClose?: string;
  modalBtnActionLabel?: string;
  modalBtnActionHandler?: (event: React.SyntheticEvent) => void;
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  formHandlerId?: string;
  edit?: boolean;
  secondaryBtnAction?: () => void;
  secondaryBtnActionLabel?: string;
  closeOnClickAway?: boolean;
  contacts: IGroupChatContact[];
  selectedContacts: IGroupChatContact[];
  selectionChanged?: ({key: number, checked: boolean}) => void;
  fetchContacts?:() => void;
}

const GroupChatModal: React.FC<IGroupChatModal> = props => {
  const {
    modalBtnClose,
    backgroundColor,
    modalHeading,
    modalSubheading,
    modalOpen,
    setModalOpen,
    modalBtnActionLabel = 'Done',
    modalBtnActionHandler,
    formHandlerId,
    secondaryBtnAction,
    secondaryBtnActionLabel,
    edit,
    closeOnClickAway = true,
    contacts,
    selectionChanged,
    selectedContacts,
    fetchContacts
  } = props;

  useEffect(() =>{
    if(fetchContacts) {
      fetchContacts();
    }
  },[fetchContacts]);

  const node = React.useRef();
  useClickAway(node, closeOnClickAway ? setModalOpen : null);
  const { register, control } = useForm({});

  const [inputValidation, setInputValidation] = useState(null);
  const [formState, setFormState] = useState('pristine');
  const [groupNameError, setGroupNameError] = useState(null);

  let selectedArea;
  if(formState === 'dirty' && (!selectedContacts || selectedContacts.length < 1)) {
    selectedArea = <div className='selectionMessage'>
      <span className="material-icons md-24">check_circle</span>
      At least 1 selection required.
      </div>;
  } else {
    selectedArea = selectedContacts &&
      selectedContacts.map(({ name, profilePicture, key, checked }) => (
        <div className='group-chat-modal__contact' key={key}>
              <div className='group-chat-modal__user-selected'>
                <div
                  className='group-chat-modal__profile-pic'
                  style={{ backgroundImage: `url(${profilePicture} )` }}
                ></div>
                {name}
              </div>
            </div>
      ))
  }


  return (
    <>
      <CSSTransition
        in={modalOpen}
        classNames='modal'
        timeout={300}
        unmountOnExit
        onExited={() => setModalOpen(false)}
      >
        <div className='modal modal--medium group-chat-modal'>
          <div className='modal__content' ref={node}>
            <div className='modal__header'>
              <h2 className='modal__title'>
                {modalHeading}
                {modalSubheading && (
                  <span className='modal__subheading'>{modalSubheading}</span>
                )}
              </h2>
              <img src={CloseIcon} alt='Close' onClick={() => setModalOpen(!modalOpen)} />
            </div>
            <div className='modal__inner' style={{ backgroundColor: backgroundColor }}>
              <div className='group-chat-modal__row'>
                <div className='group-chat-modal__left'>
                  <div className='group-chat-modal__group-name'>
                    <TextBox
                      name='labelText'
                      required={true}
                      label={'Name Your Group'}
                      register={register}
                      cssClass={inputValidation}
                      onFocus={() => {
                        setInputValidation(null);
                        setGroupNameError(null);
                      }}
                    />
                    {
                      groupNameError && (
                        <div className="errorBox">
                          <div className='errorAlert'>
                            <span className="material-icons em">check_circle</span>
                            Error Message
                          </div>
                          <span className='text'>{groupNameError}</span>
                        </div>
                      )
                    }
                  </div>
                  <div className='group-chat-modal__search'>
                    <input type='text' style={{width: "100%"}} placeholder='Search people you want to add' />
                  </div>
                  <div className='group-chat-modal__contacts'>
                    {contacts &&
                      contacts.map(({ name, profilePicture, key, checked }) => (
                        <div className='group-chat-modal__contact' key={key}>
                          <div className='group-chat-modal__user'>
                            <div
                              className='group-chat-modal__profile-pic'
                              style={{ backgroundImage: `url(${profilePicture} )` }}
                            ></div>
                          </div>
                          <Checkboxes label={name} name={name} checked={checked} onChange={() => selectionChanged(
                            {
                              key, 
                              checked: !checked
                              })} />
                        </div>
                      ))}
                  </div>
                </div>
                <div className='group-chat-modal__right'>
                  {selectedArea}
                </div>
              </div>
            </div>
            <div className='modal__footer'>
              {modalBtnClose && (
                <button
                  className='button-transparent-black button-smaller'
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  {modalBtnClose}
                </button>
              )}
              {edit && secondaryBtnAction && (
                <button
                  className='button-transparent-black button-smaller'
                  onClick={() => secondaryBtnAction()}
                >
                  {secondaryBtnActionLabel}
                </button>
              )}
              <button
                form={formHandlerId}
                className='button-primary'
                onClick={() => {
                  setFormState('dirty');
                  if(control.getValues().labelText && selectedContacts.length > 0){
                    modalBtnActionHandler(control.getValues().labelText);
                    setModalOpen(false);
                  } else {
                    setInputValidation('invalid');
                    setGroupNameError("Group name is required.");
                  }
                  
                }}
              >
                {modalBtnActionLabel}
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default GroupChatModal;

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { Link, useParams } from '@reach/router';
import './ManageTeam.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ModalsContext } from 'providers/ModalsProvider';
import ManageIcon from 'assets/icons/manage-icon.svg';
import ManageIconGray from 'assets/icons/manage-icon-gray.svg';
import classNames from 'classnames';
import map from 'lodash.map';
import { ConditionalWrap } from 'components/ConditionalWrap';

import { NotificationsContext, UserContext } from 'providers/contexts';
import { ConfirmDialog } from 'components/Common/Dialog/ConfirmDialog';
import {
  apiDeleteInvitedTeamMember,
  deleteBrandMethodRemoveTeamMember,
  deleteTeamMethodRemoveMember,
} from 'services/Api';
import { mutate } from 'swr';
import { makeCsv, getTableDataForExport } from 'utils/csv';
import DownloadIcon from 'assets/icons/download-gray.svg';
import useTeam from 'hooks/useTeam';
import ViewTeamProfile from './ViewTeamProfile';

export type IMembers = {
  key: string;
  name: string;
  email: string;
  accountType: string;
  activeAccount: string;
  status: string;
  inviteURL?: string;
  department: string;
};
export interface IMembersTeam {
  team?: any;
  brand?: any;
  members: IMembers[];
}

const ManageTeam: React.FC<IMembersTeam> = props => {
  const { team, members } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);
  const { setSelectedMember } = React.useContext(ModalsContext);

  const { brand_model_id } = useParams();

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const { mutateAll } = useTeam(idToken, {
    brand_model_id,
  });

  const handlePopUpModal = useCallback(ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  }, []);

  const handleSelectedMember = key => {
    setSelectedMember(key);
  };

  const [teamRoute, setTeamRoute] = useState(null);

  useEffect(() => {
    const teamEffect = () => {
      if (!team) return;
      const routeGrant = team.grants.find(g =>
        ['Line', 'Collection', 'Style'].includes(g.model)
      );
      let route = null;
      if (routeGrant?.model?.toLowerCase() === 'style')
        route = `/brand/${team?.model_id}/${routeGrant.model.toLowerCase()}/${
          routeGrant.model_id
        }`;
      else if (routeGrant?.model)
        route = `/brand/${team?.model_id}/products?${routeGrant.model.toLowerCase()}s=${
          routeGrant.model_id
        }`;
      else route = `/brand/${team?.model_id}/products`;
      setTeamRoute(route);
    };
    teamEffect();
  }, [team]);

  const deleteTeamInviteActionHandler = useCallback(
    async member => {
      const { key: invite_key } = member;

      try {
        const response = await apiDeleteInvitedTeamMember(
          idToken,
          invite_key,
          brand_model_id
        );
        if (response.data?.error) {
          setDisplayToast({ type: 'error', message: response.data?.error?.message });
        } else {
          mutate([
            `/api/invite/query/${team ? 'team' : 'brand'}/`,
            idToken,
            team ? team.key : brand_model_id,
          ]);
          mutateAll();
          setDisplayToast({
            type: 'success',
            message: `Invite has been deleted successfully.`,
          });
        }
      } catch (e) {
        setDisplayToast({
          type: 'error',
          message: e.response?.data?.error?.message || e.toString(),
        });
      }
    },
    [idToken, team?.key, brand_model_id]
  );

  const deleteTeamMemberActionHandler = useCallback(
    async member => {
      const { key } = member;
      try {
        let response = null;
        if (team) {
          response = await deleteTeamMethodRemoveMember(idToken, {
            account: key,
            team: team.key,
          });
        } else {
          response = await deleteBrandMethodRemoveTeamMember(idToken, {
            account: key,
            brand: brand_model_id,
          });
        }
        if (response.data?.error) {
          setDisplayToast({ type: 'error', message: response.data?.error?.message });
        } else {
          mutate([
            `/api/account/query/${team ? 'team' : 'brand'}/`,
            idToken,
            team ? team.key : brand_model_id,
          ]);
          setDisplayToast({
            type: 'success',
            message: `Team member has been successfully deactivated.`,
          });
        }
      } catch (e) {
        setDisplayToast({
          type: 'error',
          message: e.response?.data?.error?.message || e.toString(),
        });
      }
    },
    [idToken, team?.key]
  );

  const popUpOptions = useMemo(
    () => [
      {
        selectItem: 'Revoke Access',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to revoke access for this team member?'
            btnActionHandler={deleteTeamMemberActionHandler}
          />
        ),
      },
      {
        selectItem: 'Revoke Invite',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to delete this invited team member?'
            btnActionHandler={deleteTeamInviteActionHandler}
          />
        ),
      },
      // { selectItem: 'Edit' },
      {
        selectItem: 'View',
        ModalComponent: (
          <ViewTeamProfile
            member={members}
            setModalOpen={setModalOpen}
            setActiveModalComponent={setActiveModalComponent}
          />
        ),
      },
      // { selectItem: 'Reassign user' },
    ],
    [
      deleteTeamMemberActionHandler,
      handleSelectedMember,
      deleteTeamInviteActionHandler,
      members,
    ]
  );

  if (members?.length === 0) {
    return <></>;
  }

  return (
    <div className='manage-team'>
      <div className='manage-team__table'>
        <div className='manage-team__header'>
          <h2 className='manage-team__headline'>
            <ConditionalWrap
              condition={teamRoute}
              wrap={children => <Link to={teamRoute}>{children}</Link>}
            >
              {team?.name || team?.description || 'Members'}
            </ConditionalWrap>
            <button
              type='button'
              onClick={() =>
                makeCsv(
                  getTableDataForExport(members, [
                    { Header: 'Name', accessor: 'name' },
                    { Header: 'email', accessor: 'email' },
                    { Header: 'Account Active', accessor: 'activeAccount' },
                    { Header: 'Status', accessor: 'status' },
                    { Header: 'Invite URL', accessor: 'inviteURL' },
                    { Header: 'department', accessor: 'department' },
                    { Header: 'key', accessor: 'key' },
                  ]),
                  `brand-${brand_model_id}-team.csv`
                )
              }
            >
              <img src={DownloadIcon} alt='Download' />
            </button>
          </h2>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Department</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {map(
              members,
              ({ name, email, department, activeAccount, inviteURL, status, key }) => (
                <tr key={key}>
                  <td>
                    <label className='control control--checkbox'>
                      <input type='checkbox' />
                      <span className='control__indicator'></span>
                    </label>
                  </td>
                  <td data-th='Name'>{name}</td>
                  <td data-th='Email'>{email}</td>
                  <td data-th='Status'>
                    {activeAccount === 'active' && (
                      <div
                        className={classNames({
                          'button-badge color-info-3-bg':
                            status.indexOf('onboarding') >= 0,
                          'button-badge color-onboarded-bg':
                            status.indexOf('onboarded') >= 0,
                        })}
                      >
                        {status}
                      </div>
                    )}
                    {activeAccount === 'invited' && (
                      <CopyToClipboard text={inviteURL}>
                        <div className='button-badge color-inactive-bg'>Invited</div>
                      </CopyToClipboard>
                    )}
                    {activeAccount === 'deactivated' && (
                      <div className='button-badge color-inactive-bg'>Deactivated</div>
                    )}
                  </td>
                  <td data-th='Department'>{department}</td>
                  <td>
                    <img
                      className='manage-team__dots'
                      onClick={() => {
                        setOpen(true);
                        setSelectedItem(key);
                      }}
                      src={selectedItem === key ? ManageIconGray : ManageIcon}
                      alt='Manage Icon'
                    />

                    <PopUp open={open && selectedItem === key}>
                      {map(
                        popUpOptions,
                        ({ selectItem, to, ModalComponent }) =>
                          !(
                            (activeAccount === 'invited' && selectItem === 'View') ||
                            (activeAccount === 'invited' &&
                              selectItem === 'Revoke Access') ||
                            (activeAccount === 'active' && selectItem === 'Revoke Invite')
                          ) && (
                            <li
                              className='pop-up__li'
                              onClick={() => {
                                setOpen(false);
                                if (ModalComponent) {
                                  handlePopUpModal(ModalComponent);
                                  handleSelectedMember(key);
                                }
                              }}
                              key={selectItem}
                            >
                              {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                            </li>
                          )
                      )}
                    </PopUp>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        {ActiveModalComponent &&
          modalOpen &&
          React.cloneElement(ActiveModalComponent, {
            open: modalOpen,
            setOpen: setModalOpen,
            data: members.find(item => item.key === selectedItem),
          })}
      </div>
    </div>
  );
};

export default ManageTeam;

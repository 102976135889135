import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import useClickAway from 'hooks/useClickAway';
import { IModal } from 'models/Dashboard/IModal';
import './Modal.scss';

import CloseIcon from 'assets/icons/close.svg';

export interface IModalButons {
  btnUrl?: string;
  btnTxt: string;
}

const Modal: React.FC<IModal> = props => {
  const {
    modalBtnClose,
    backgroundColor,
    modalHeading,
    modalSubheading,
    modalOpen,
    setModalOpen,
    modalBtnActionLabel,
    modalBtnActionHandler,
    modalBtnCloseHandler,
    formHandlerId,
    secondaryBtnAction,
    secondaryBtnActionLabel,
    edit,
    closeOnClickAway = true,
    className,
    footer = null,
  } = props;
  const node = React.useRef();
  useClickAway(node, closeOnClickAway ? setModalOpen : null);

  return (
    <>
      <CSSTransition
        in={modalOpen}
        classNames='modal'
        timeout={300}
        unmountOnExit
        onExited={() => setModalOpen(false)}
      >
        <div className={classNames(className, 'modal modal--regular')}>
          <div className='modal__content' ref={node}>
            <div className='modal__header'>
              <h2 className='modal__title'>
                {modalHeading}
                {modalSubheading && (
                  <span className='modal__subheading'>{modalSubheading}</span>
                )}
              </h2>
              <img
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setModalOpen(!modalOpen);
                  modalBtnCloseHandler?.();
                }}
              />
            </div>
            <div className='modal__inner' style={{ backgroundColor: backgroundColor }}>
              {props.children}
            </div>
            <div className='modal__footer'>
              {footer && <div className='custom__footer'>{footer}</div>}
              {modalBtnClose && (
                <button
                  className='button-transparent-black button-smaller'
                  onClick={e => {
                    e.preventDefault();
                    setModalOpen(!modalOpen);
                  }}
                >
                  {modalBtnClose}
                </button>
              )}
              {edit && secondaryBtnActionLabel && secondaryBtnAction && (
                <button
                  className='button-transparent-black button-smaller'
                  onClick={e => {
                    e.preventDefault();
                    secondaryBtnAction();
                  }}
                >
                  {secondaryBtnActionLabel}
                </button>
              )}
              {modalBtnActionLabel && (
                <button
                  form={formHandlerId}
                  className='button-primary'
                  onClick={modalBtnActionHandler}
                >
                  {modalBtnActionLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Modal;

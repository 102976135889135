import moment from 'moment';
import React from 'react';
import TimeAgo from 'react-time-ago';

type NotificationSegment = {
  displayText: string;
  url: string;
  accented: boolean;
}

type NotificationItem = {
  profileImg: string;
  firstName: string;
  lastName: string;
  segments: Array<NotificationSegment>
  when: number;
};
interface INotificationProp {
  notification: NotificationItem;
}

const NotificationItem: React.FC<INotificationProp> = props => {
  // console.log('PROPS', props)
  const { notification } = props;

  const { profileImg, firstName, lastName, when } = notification;

  let display = null;
  let counter = 1;
  let space = "";

  return (
    <div className='notifications__item'>
      {profileImg ? (
        <img className='notifications__img' src={profileImg} alt='Profile' />
      ) : (
        <span className='notifications__thumbnail'>
          {firstName.slice(0, 1)}

          {lastName.slice(0, 1)}
        </span>
      )}

      <div className='notifications__content'>
        <p className='notifications__action' style={{ fontWeight: 600 }}>
          {notification.segments &&
              notification.segments.map(segment => {
                if(counter > 1) {
                  space = '3px'
                }

                if(segment.url && segment.url.length) {
                  if(!segment.accented) {
                    display = <a style={{ fontWeight: 400, paddingLeft:space }} key={counter} href={segment.url} target="_work">{segment.displayText}</a>
                  } else {
                    display = <a style={{paddingLeft: space}} key={counter} href={segment.url} target="_work">{segment.displayText}</a>
                  }
                  
                } else {
                  if(!segment.accented) {
                    display = <span style={{ fontWeight: 400, paddingLeft: space}}>{segment.displayText}</span>
                  } else {
                    display = <span style={{paddingLeft: space}}>{segment.displayText}</span>
                  }
                   
                }
                counter += 1;
                return display;
              })
          }
        </p>

        <p className='notifications__time'>
          <TimeAgo date={when} />
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;

import { Link } from '@reach/router';
import { ConfirmDialog } from 'components/Common/Dialog/ConfirmDialog';
import PopUp from 'components/Common/PopUp/PopUp';
import React from 'react';
import { deleteMediaModel } from 'services/Api';
import DotsIcon from 'assets/icons/dots-grey-horizontal.svg';
import './ProductMedia.scss';
import { UserContext } from 'providers/UserProvider';

const ProductMediaThumb = props => {
  const { user } = React.useContext(UserContext);
  const { idToken } = user;
  const { src, name, key, onDragStart } = props.metadata;

  const deleteMediaActionHandler = React.useCallback(media_model_id => {
    console.log('Remove Media:', media_model_id);
    const response = deleteMediaModel(idToken, media_model_id);
    props.onDeleteMedia?.(media_model_id);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = React.useState(null);
  const popUpOptions = React.useMemo(
    () => [
      {
        selectItem: 'Delete Image',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to delete this image?'
            btnActionHandler={deleteMediaActionHandler}
          />
        ),
      },
    ],
    []
  );

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  return (
    <>
      <div className='product-media__img-block' key={key}>
        <div className='ratio3-4 ratio-wrap'>
          <img
            className='ratio-img product-media__img'
            src={src}
            alt={name}
            data-mediakey={key}
            data-medianame={name}
            draggable='true'
            onDragStart={onDragStart}
          />
        </div>
        {ActiveModalComponent &&
          modalOpen &&
          React.cloneElement(ActiveModalComponent, {
            open: modalOpen,
            setOpen: setModalOpen,
            data: key,
            model: props.metadata,
          })}
        <div className='product-media__dots-container' style={{ position: 'relative' }}>
          <img
            className='product-media__dots'
            src={DotsIcon}
            alt='Image options'
            onClick={e => {
              setOpen(!open);
            }}
          ></img>
          <PopUp open={open} setOpen={setOpen}>
            {popUpOptions &&
              popUpOptions.map(({ selectItem, ModalComponent }) => (
                <li
                  className='pop-up__li'
                  onClick={() => {
                    setOpen(false);
                    if (ModalComponent) {
                      handlePopUpModal(ModalComponent);
                    }
                    // onClick?.(model);
                  }}
                  key={selectItem}
                >
                  {selectItem}
                </li>
              ))}
          </PopUp>
        </div>
        <p className='product-media__p'>{name}</p>
      </div>
    </>
  );
};

export default ProductMediaThumb;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { navigate, useLocation, useParams } from '@reach/router';
import { readString, jsonToCSV } from 'react-papaparse';
import LogRocket from 'logrocket';

import './ProductUpload.scss';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import ModalBatchListings, {
  IUploadedProducts,
} from 'components/Dashboard/ModalUploadDetails/ModalBatchListings';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import DragAndDrop from 'components/Upload/DragAndDrop';
import { DataGrid } from './ProductCSVGrid';
import { CSVResult } from './ProductCSV';
import {
  APIWithAuthToken,
  apiPostListingMethodBatch,
  apiPostListingMethodBatchString,
} from 'services/Api';
import { NotificationsContext, UserContext } from 'providers/contexts';
import InfoIcon from 'assets/icons/Icon-info-circle-full.svg';
import InfoIconDark from 'assets/icons/info-panel-open.svg';
import PlusIcon from 'assets/icons/plus-btn.svg';

import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import ProductCSVHistory from './ProductCSVHistory';

import DownloadIcon from 'assets/icons/Icon-download.svg';
import { useTranslation, Trans } from 'react-i18next';
import useSWR from 'swr';
import moment from 'moment';
import useFeatureFlags from 'hooks/useFeatureFlags';

export interface IProductUpload {
  pages: IPages[];
  tooltipTxt: string;
  fileType: string;
  popUpOptions: IPopUpOptions[];
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeHandler?: (event: React.SyntheticEvent) => void;
  onDoneHandler?: (event: React.SyntheticEvent) => void;
  uploadedProducts?: IUploadedProducts[];
  setUploadedProducts?: React.Dispatch<React.SetStateAction<IUploadedProducts[]>>;
  brandModelId?: string;

  stepSaveHandler?: (event: React.SyntheticEvent) => void;
  setStepSaveHandler?: (arg?) => void;
}

const ProductUpload: React.FC<IProductUpload> = props => {
  const { tooltipTxt, pages, fileType, brandModelId } = props;
  const { setStepSaveHandler } = props;
  const { setDisplayToast } = useContext(NotificationsContext);

  const { t } = useTranslation();

  const btnActionOne = 'download data template';

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { brand_model_id } = useParams();

  const { pythonVersion } = useFeatureFlags();

  const { data: sheetsResponse } = useSWR(
    pythonVersion === 3 ? ['/api/parsedspreadsheets/query/brand', brand_model_id] : null,
    (url, brand) => {
      return APIWithAuthToken({ url, params: { brand } });
    },
    {
      suspense: false,
    }
  );

  const [CSVHistory, setCSVHistory] = useState(null);

  useEffect(() => {
    console.log('sheets', sheetsResponse);
    if (sheetsResponse?.data?.data) setCSVHistory(sheetsResponse?.data?.data)
  }, [sheetsResponse]);

  const [modalOpen, setModalOpen] = useState(false);
  const [addition, setAddition] = useState(false);
  const [uploadedProducts, setUploadedProducts] = useState([]);
  const [uploadedContext, setUploadedContext] = useState([]);
  const checkboxRef = useRef();

  const productUploadNavItems = ['Data Sheet', 'History'];
  const [tab, setTab] = useState(productUploadNavItems[0]);

  const onDoneHandler = event => {
    !uploadedError
      ? navigate(`/brand/${brandModelId}/products/media/upload`)
      : setModalOpen(false);
  };

  const [csvString, setCsvString] = useState<CSVResult>({});
  const [csvRows, setCsvRows] = useState([]);
  const [csvColumns, setCsvColumns] = useState<any>([]);

  const onProductCSVTextHandler = event => {
    setDisplayToast(false);
    const { data } = readString(event.currentTarget.value, { skipEmptyLines: 'greedy' });

    const parsedRowZero = (data[0] as string[]).join('\t');
    setCsvColumns(parsedRowZero.split('\t'));
    setCsvString({
      columns: parsedRowZero.split('\t'),
      rows: event.currentTarget.value,
    });
  };

  useEffect(() => {
    const onProductCSVStringHandler = csvStringVal => {
      if (!csvStringVal.rows) return;
      const {
        data: [columns, ...rows],
        ...results
      } = readString(csvStringVal.rows, { skipEmptyLines: 'greedy' });
      if (!columns.map(c => c.toLowerCase()).includes('listing unique id')) {
        setDisplayToast({
          type: 'error',
          persist: true,
          message:
            "'Listing Unique Id' required column is missing. Please revise CSV file.",
        });
        setShowCSVParsedTable(false);
        return;
      }
      setCsvColumns(columns);
      setCsvRows(rows);
      setShowCSVParsedTable(true);
      if (csvString.formData) {
        setStepSaveHandler(() => event =>
          onProductCSVFileSubmit(csvString, event)
        );
      } else {
        setStepSaveHandler(() => event => onProductCSVTextSubmit(columns, rows));
      }
    };
    onProductCSVStringHandler(csvString);
  }, [csvString]);

  const onProductCSVTextSubmit = async (csvColumns, csvRows) => {
    setModalOpen(true);
    const batchUploadResponse = await apiPostListingMethodBatchString(idToken, {
      preflight: false,
      spreadsheet: jsonToCSV({ fields: csvColumns, data: csvRows }),
      brand: brandModelId,
    });
    onProductBatchUploadResponse(batchUploadResponse);
  };

  const onProductBatchUploadResponse = batchUploadResponse => {
    const modalProductListings = batchUploadResponse.data?.created?.map(listing => {
      const {
        errors,
        style_name,
        style,
        style_number,
        sku,
        upc,
        category_names,
        categories,
        color,
        size,
        wholesale_prices,
        key,
      } = listing;
      return {
        styleNum: listing.style_number,
        productName: listing.style_name,
        collection: listing.collection_name,
        updated: false,
        fileType: 'listing',
        completed: Object.keys(listing.errors).length === 0,
        style_name,
        style,
        style_number,
        sku,
        upc,
        categories,
        category_names,
        size,
        wholesale_prices,
        color,
        errors,
        key,
      };
    });
    const modalProductListingsUpdated = batchUploadResponse.data?.updated?.map(
      listing => {
        const {
          errors,
          style_name,
          style,
          style_number,
          sku,
          upc,
          category_names,
          categories,
          color,
          size,
          wholesale_prices,
          key,
        } = listing;
        return {
          styleNum: listing.style_number,
          productName: listing.style_name,
          collection: listing.collection_name,
          updated: Object.keys(listing.errors).length === 0,
          fileType: 'listing',
          completed: Object.keys(listing.errors).length === 0,
          style_name,
          style,
          style_number,
          sku,
          upc,
          categories,
          category_names,
          size,
          wholesale_prices,
          color,
          errors,
          key,
        };
      }
    );
    const mergedProductListings = modalProductListings.concat(
      modalProductListingsUpdated
    );
    setUploadedProducts(mergedProductListings);

    const modalUploadContext = batchUploadResponse.data?.context;
    setUploadedContext(modalUploadContext);

    if (!mergedProductListings.length && batchUploadResponse.data?.errors.length > 0) {
      // assumed that error type exists in i18n list and message represents the column field name with the error
      setUploadedError(t(`productUpload.reviewSpreadsheet`));
      LogRocket.captureMessage(`api#postListingMethodBatch`, {
        tags: {
          // additional data to be grouped as "tags"
          journey: 'brand/products/upload',
          step: 'csvUpload',
        },
        extra: {
          // additional arbitrary data associated with the event
          brand: brandModelId,
        },
      });
    }
    setModalOpen(true);
  };

  const onChangeHandler = event => {
    const [csvFile] = event.currentTarget.files;
    onCSVFileHandler(csvFile);
  };
  const onCSVFileHandler = csvFile => {
    if (!csvFile) {
      setDisplayToast({ type: 'error', message: 'CSV file not detected. ' });
      return;
    }
    setDisplayToast(false);
    setUploadedError(false);
    setUploadedProducts(null);
    const formDataSpreadsheet = new FormData();
    formDataSpreadsheet.append('spreadsheet', csvFile, csvFile.name);

    const reader = new FileReader();

    reader.readAsText(csvFile);

    reader.onload = function () {
      setCsvString({
        filename: csvFile.name,
        rows: reader.result,
        formData: formDataSpreadsheet,
      });
    };

    reader.onerror = function () {
      setDisplayToast({ type: 'error', message: reader.error });
    };
  };

  const [uploadedError, setUploadedError] = useState(null);
  const onProductCSVFileSubmit = async ({ filename, formData: formDataSpreadsheet }, event) => {
    setModalOpen(true);
    try {
      const batchUploadResponse = await apiPostListingMethodBatch(idToken, {
        preflight: false,
        spreadsheet_columns: true,
        formDataSpreadsheet,
        brand: brandModelId,
        addition: checkboxRef.current.checked || false,
        filename,
      });
      onProductBatchUploadResponse(batchUploadResponse);
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: `ProductUpload onProductCSVFileSubmit api#postListingMethodBatch`,
          journey: 'brand/products/upload',
          step: 'csvUpload',
        },
        extra: {
          // additional arbitrary data associated with the event
          formDataSpreadsheet,
          brand: brandModelId,
          user,
        },
      });
      let errorType = null;
      let errorMessage = null;
      // assumed that error type exists in i18n list and message represents the column field name with the error
      if (pythonVersion === 3) {
        const {
          response: { data },
        } = error;
        errorType = data.error.type;
        errorMessage = data.error.message;
      } else {
        errorType = error.type;
        errorMessage = error.message;
      }
      setUploadedError(t(`productUpload.${errorType}`, { missingField: errorMessage }));
      setDisplayToast({
        type: 'error',
        persist: true,
        message: `Review the CSV upload for errors. You can also reach our Client Services Department by email at: clientsupport@themodernmirror.com`,
      });
    }
  };

  const [showCSVPasteInput, setShowCSVPasteInput] = useState(false);
  const [showCSVParsedTable, setShowCSVParsedTable] = useState(false);

  const onDropCSVHandler = droppedFiles => {
    const [csvFile] = droppedFiles;
    onCSVFileHandler(csvFile);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const editTileOptions = [
    {
      selectItem: 'Download',
      disabled: false,
      download: true,
      onClick: () => console.log("CSV Downloaded"),
    },
  ];

  return (
    <div className='product-upload'>
      <div className='product-upload__header'>
        <div className='product-upload__heading-wrapper'>
          <div className='product-upload__heading'>Product Upload</div>
          <div className='product-upload__subheading'>
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className='product-upload__heading-icons'>
          <img className='info-icon' src={InfoIcon} alt='Info Icon' />
          {tooltipTxt && (
              <div className='tooltip'>
                <p className='tooltip__txt'>{tooltipTxt}</p>
              </div>
            )}
          <img
            src={PlusIcon}
            alt='Plus'
            className='additional-reference__add'
            style={{ cursor: tab !== productUploadNavItems[0] ? 'pointer' : 'not-allowed' }}
            onClick={() => setTab('Data Sheet')}
          />
        </div>
      </div>
      <div className='prodcut-upload__dashboard-nav'>
        <DashboardNav
          dashboardNavItems={productUploadNavItems.map(item => ({
            title: item,
            activeBtn: item === tab,
          }))}
          darkMode={false}
          changeTab={setTab}
        />
      </div>

      {tab === productUploadNavItems[0] && (
        <>
          <div className='product-upload__controls'>
            <div className='product-upload__select'>
              <div className='product-upload__info'>
                <img src={InfoIcon} alt='Info Icon' />
                File Upload Requirement
                {tooltipTxt && (
                  <div className='tooltip'>
                    <p className='tooltip__txt'>{tooltipTxt}</p>
                  </div>
                )}
              </div>
            </div>
            <div className='product-upload__btns'>
              <button className='button-dashboard color-white-bg'>
                <a
                  className={`cursor-pointer`}
                  href={`/templates/modern-mirror-product-blank-template.xlsx`}
                  download={`modern-mirror-product-blank-template.xlsx`}
                  title={`modern-mirror-product-blank-template.xlsx`}
                >
                  {btnActionOne}
                  <img className='btn-upload-img' src={DownloadIcon} alt='Upload' />
                </a>
              </button>
            </div>
          </div>
          <DragAndDrop onDropHandler={onDropCSVHandler}>
            <div className='product-upload__drag-area'>
              <div className='product-upload__txt'>
                <p className='product-upload__instructions'>
                  <>
                    Drag and drop, select files
                    {/* Optional Copy Paste function that we keeping for a future feature
                    ======================================================================
                    , or{' '}
                    <span onClick={() => setShowCSVPasteInput(true)}>copy/paste CSV</span>  */}
                  </>
                </p>
                <p className='txt-gray-light'>File types: {fileType}</p>
              </div>
              <div className='image-upload'>
                <label htmlFor='batchUpload' className='button-small color-white-bd-bg mt-4'>
                  Select File
                </label>
                <input
                  id='batchUpload'
                  type='file'
                  onChange={onChangeHandler}
                  name='batchUpload'
                  onClick={onInputClick}
                />
              </div>
            </div>
          </DragAndDrop>
            
          {showCSVParsedTable && (
            <>
              <div className='product-upload__info-area'>
                <div className='product-upload__infosave'>
                  <img src={InfoIconDark} alt='Info Icon' />
                  <span>Info: Save to sync this data with Modern Mirror</span>
                </div>
              </div>
              <div className='product-upload__select'>
                <div className='product-upload__info'>
                  CSV Preview Sync: {csvRows.length ? `${csvRows.length} records parsed` : ''}
                  <>
                    <div className='tooltip'>
                      <p className='tooltip__txt'>
                        <p>Save to sync this data with Modern Mirror.</p>
                      </p>
                    </div>
                  </>
                </div>
              </div>
              <div className='product-upload__preview-area'>
                {csvColumns && <DataGrid dataColumns={csvColumns} dataSrc={csvRows} />}
              </div>
              <div className='pt-2 pb-2'>
                <div className='Checkboxes'>
                  <div className='control-group'>
                    <label className='control control--checkbox mt-2'>
                      {'MARK AS ADDITION'}
                      <input type='checkbox' ref={checkboxRef} />
                      <span className='control__indicator'></span>
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
          
          {/* Optional Copy Paste function that we keeping for a future feature
          =======================================================================
          {showCSVPasteInput && (
            <>
              <div className='product-upload__controls'>
                <div className='product-upload__select'>
                  <div className='product-upload__info'>
                    <img src={InfoIcon} alt='Info Icon' />
                    Copy/Paste requirement
                    <div className='tooltip'>
                      <p className='tooltip__txt'>
                        Import records from spreadsheets e.g. Excel or Google Sheets. Column
                        names must match the product template.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='product-upload__btns'></div>
              </div>
      
              <div className='product-upload__info'></div>
              <div className='product-upload__paste-area'>
                <div className='product-upload__txt'>
                  <p className='product-upload__instructions'>Paste CSV</p>
                </div>
                <div className='image-upload'>
                  <textarea name='productCSVText' onChange={onProductCSVTextHandler} />
                </div>
              </div>
            </>
          )} */}
        </>
      )}

      {tab === productUploadNavItems[1] && (
        <div>
          <ProductCSVHistory
            csvHistory={CSVHistory}
            editTileOptions={editTileOptions}
          />
        </div>
      )}

      <ModalBatchListings
        modalHeading='Product Upload Details'
        uploadedProducts={uploadedProducts}
        uploadedContext={uploadedContext}
        uploadedError={uploadedError}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setUploadedProducts={setUploadedProducts}
        setUploadedError={setUploadedError}
        onDoneHandler={onDoneHandler}
      />
    </div>
  );
};

export default ProductUpload;

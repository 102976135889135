import React, { useContext, useState, useEffect, ReactElement } from 'react';
import { UserContext, UploadContext } from 'providers/contexts';
import { DragAndDrop } from './index';
import Loader from './Loader/Loader';

interface AssetProps {
  asset?: [string, AssetMetadataProps];
  signed?: boolean;
  saveImages?: boolean;
  setSaveImage?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: ReactElement;
  className?: string;
  imageAsFileSeed?: File;
  upload?: boolean;
  onUploadHandler?: () => void;
  overlay?: boolean;
  onDropHandler?: (files: File[]) => void;
  crop?: boolean;
}

export interface AssetMetadataProps {
  path?: string;
  progress?: number;
  key?: number;
  urls?: any;
}

const Asset: React.FC<AssetProps> = ({
  asset,
  signed,
  children,
  saveImages,
  setSaveImage,
  className,
  upload = true,
  onUploadHandler = null,
  imageAsFileSeed = null,
  overlay,
  onDropHandler,
  crop = false,
}) => {
  const { handleFireBaseUpload, firebaseStorageUrl, imageUrl } = useContext(
    UploadContext
  );
  const [type, metadata] = asset;

  const [imageAsFile, setImageAsFile] = useState(imageAsFileSeed);
  const [imageAsUrl, setImageAsUrl] = useState({});

  useEffect(() => {
    async function getFirebaseUrl(metadataDep) {
      if (!metadataDep) return;
      if (metadataDep.progress > 0) {
        if (signed) {
          setImageAsUrl(prevImageAsUrl => ({
            ...prevImageAsUrl,
            [type]: metadataDep.urls.signed.download,
          }));
        } else {
          const fireBaseUrl = await firebaseStorageUrl(metadataDep.path);
          setImageAsUrl(prevImageAsUrl => ({ ...prevImageAsUrl, [type]: fireBaseUrl }));
        }
      }
    }
    getFirebaseUrl(metadata);
  }, [
    signed,
    type,
    metadata?.progress,
    metadata?.urls?.signed?.download,
    metadata?.path,
  ]);

  useEffect(() => {
    const imageUrlEffect = imageUrlDep => {
      if (imageUrlDep && imageUrlDep[type]) {
        setImageAsUrl(prevImageAsUrl => ({
          ...prevImageAsUrl,
          [type]: imageUrlDep[type],
          ['file']: imageUrlDep['file'],
        }));
      }
    };
    imageUrl && imageUrl['key'] === metadata?.key && imageUrlEffect(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    const imageAsFileEffect = imageAsFileDep => {
      if (imageAsFileDep) {
        const reader = new FileReader();
        reader.onload = () => {
          setImageAsUrl(prevImageAsUrl => ({ ...prevImageAsUrl, [type]: reader.result }));
        };
        reader.readAsDataURL(imageAsFileDep);
      }
    };
    imageAsFileEffect(imageAsFile);
  }, [imageAsFile]);

  useEffect(() => {
    if (saveImages) {
      const imageFile = imageAsUrl['file'] || imageAsFile;
      handleFireBaseUpload(metadata.path, imageFile, metadata, type, onUploadHandler);
      setSaveImage?.(false);
    }
  }, [saveImages]);

  const handleUploadButtonClicked = e => {
    const [image] = e.target.files;
    setImageAsFile(image);
  };

  const imageAsUrlOrSeed =
    imageAsUrl[type] || (imageAsFileSeed && URL.createObjectURL(imageAsFileSeed));
  // console.log(children?.props?.src, metadata?.path, type);
  // console.log('imageAsUrlOrSeed', imageAsUrlOrSeed);

  const childImageView = React.cloneElement(children, {
    ...(imageAsUrlOrSeed && !overlay ? { src: imageAsUrlOrSeed } : {}),
    ...(imageAsUrlOrSeed && overlay
      ? {
          style: { backgroundImage: `url(${imageAsUrlOrSeed})`, backgroundSize: 'cover' },
        }
      : {}),
    ...(imageAsUrlOrSeed && children.props.className
      ? { className: `${children.props.className} asset__loaded` }
      : { className: `${children.props.className} asset__loading` }),
    ...(children.type !== 'img'
      ? {
          handleUploadButtonClicked: e => {
            if (upload && !crop) {
              handleUploadButtonClicked(e);
            }
            children.props.handleUploadButtonClicked?.(e);
          },
        }
      : {}),
  });
  // console.log('childImageView', childImageView?.props?.src);
  return (
    <div className={className}>
      {upload ? (
        <DragAndDrop setImageAsFile={setImageAsFile} onDropHandler={onDropHandler}>
          {childImageView}
        </DragAndDrop>
      ) : childImageView?.props?.src === imageAsUrlOrSeed ||
        metadata?.progress === 0 ||
        !metadata ? (
        childImageView
      ) : (
        <Loader active={true} relative={true}></Loader>
      )}
    </div>
  );
};

export default Asset;

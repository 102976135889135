import React, { useEffect, useState, useContext } from 'react';
import { useLocationSearchParam } from 'hooks/useLocation';
import { navigate } from '@reach/router';
import { NotificationsContext } from 'providers/contexts';
import firebase from 'firebase/app';

const VerifyEmail = () => {
  const oobCode = useLocationSearchParam('code');
  const { setDisplayToast } = useContext(NotificationsContext);
  const [invalidLink, setInvalidLink] = useState(false);

  useEffect(() => {
    if (oobCode) {
      const verifyEmail = () => {
        const auth = firebase.auth();
        auth
          .applyActionCode(oobCode)
          .then(verified => {
            console.log('verified', verified);
            setDisplayToast({ type: 'success', message: 'Email Verified' });
            navigate('/');
          })
          .catch(error => {
            setDisplayToast({ type: 'error', message: error.message });
          });
      };
      verifyEmail();
    } else {
      setInvalidLink(true);
    }
  }, [oobCode]);

  return (
    <>
      {invalidLink && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>Invalid Link!</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
